import React, { useContext, useEffect, useState } from "react";
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";
import Logo from "../../Assets/Images/pakanimals-logo.png";
import Logo1 from "../../Assets/Images/PakAnimals1.png";
import Logo2 from "../../Assets/Images/PakAnimals2.jpg";
import Logo5 from "../../Assets/Images/header-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faX } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../Contexts/AuthContext";
import avatar from "../../Assets/Images/kitty1.jpg";
import "smartmenus/dist/css/sm-core-css.css";
import "smartmenus/dist/css/sm-clean/sm-clean.css";

const Header = () => {
  const { UserToken, setUserToken } = useAuth();
  const navigate = useNavigate();

  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  const handleLogout = () => {
    navigate("/");
    setUserToken("", "", "", "", "", "", "", "");
    window.location.reload();
  };

  // const closeOffcanvas = () => {
  //   setIsOffcanvasOpen(false);
  // };

  // Function to handle link click and close the offcanvas menu
  const handleLinkClick = () => {
    // if (isOffcanvasOpen) {
    // closeOffcanvas();
    setIsOffcanvasOpen(!isOffcanvasOpen)
    // }
  };

  return (
    <div className="header">
      <div className="container">
        <div className="row">
          <div className="col-6 col-lg-3">
            <div className="logo">
              <Link to="/home">
                <img src={Logo5} className="mt-2 mb-2" />
              </Link>
            </div>
          </div>
          <div className="col-6 col-lg-5">
            <nav className="navbar">
              <ul id="main-menu" className="sm sm-menu">
                <li>
                  <Link to="/" onClick={handleLinkClick}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/pet-search" onClick={handleLinkClick}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Pet & Animals
                  </Link>
                </li>
                <li>
                  <Link to="/store" onClick={handleLinkClick}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Store
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us" onClick={handleLinkClick}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link to="/about-us" onClick={handleLinkClick}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    About Us
                  </Link>
                </li>
              </ul>
            </nav>
            <div className="nav-mob ">
              <button
                className="btn btn-primary"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvas"
                aria-controls="offcanvas"
                onClick={handleLinkClick}
                data-bs-dismiss="offcanvas"
                aria-label="Close"

              >
                <span>
                  <i>
                    <FontAwesomeIcon icon={faBars} />
                  </i>
                </span>
              </button>
              <div
                className={`offcanvas offcanvas-end ${isOffcanvasOpen ? "show" : ""}`}
                tabIndex={-1}
                id="offcanvas"
                aria-labelledby="offcanvasExampleLabel"
                data-bs-backdrop="false"
              >
                <div className="offcanvas-header">
                  <h2>MENU</h2>
                  <button
                    type="button"
                    className=""
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={handleLinkClick}

                  >
                    <span>
                      <i>
                        <FontAwesomeIcon icon={faX} />
                      </i>
                    </span>
                  </button>
                </div>
                <div className="offcanvas-body">
                  <ul id="mob-menu" className="sm sm-menu">
                    <li>
                      <Link to="/" onClick={handleLinkClick}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/pet-search" onClick={handleLinkClick}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        Buy/Sell
                      </Link>
                    </li>
                    <li>
                      <Link to="/store" onClick={handleLinkClick}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        Store
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us" onClick={handleLinkClick}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/about-us" onClick={handleLinkClick}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      >
                        About Us
                      </Link>
                    </li>
                    {/* {!UserToken.email ? (
                      <Link className="btn-blue" to={"/login"}         onClick={handleLinkClick}
          data-bs-dismiss="offcanvas"
                  aria-label="Close"
>
                        Post Ad
                      </Link>
                    ) : ( */}
                    <Link className="btn-blue" to={"/category-post-ads"} onClick={handleLinkClick}
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      Post Ad
                    </Link>
                    {/* )} */}
                    {!UserToken.email ? (
                      <>
                        <li>
                          <Link to={"/login"} className="mx-1" onClick={handleLinkClick}
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          >
                            Login
                          </Link>
                        </li>
                        <li>
                          <Link to={"/register"} className="mx-1" onClick={handleLinkClick}
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          >
                            Register
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <Link to={""} className="with-sub" onClick={handleLinkClick}
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          >
                            Account
                            <span className="sub-arrow"></span>
                          </Link>
                          <ul className="sub-menu">
                            <li>
                              <Link to="/dashboard" onClick={handleLinkClick}
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                Dashboard
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={"/"}
                                onClick={() => {
                                  handleLogout();
                                  handleLinkClick();
                                }}
                                className="mx-1"
                              >
                                Logout
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-lg-4 ">
            <div className="header-right">
              <Link className="btn-blue" to={"/category-post-ads"} onClick={handleLinkClick}
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                Post Ad
              </Link>
              {!UserToken.email ? (
                <>
                  <span>
                    <Link to={"/login"} className="mx-1" onClick={handleLinkClick}
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      Login
                    </Link>
                  </span>
                  <span>/</span>
                  <span>
                    <Link to={"/register"} className="mx-1" onClick={handleLinkClick}
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      Register
                    </Link>
                  </span>
                </>
              ) : (
                <>
                  <span>
                    <Link className="mx-1" to="/dashboard" onClick={handleLinkClick}
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      Dashboard
                    </Link>
                  </span>
                  <span>/</span>
                  <span>
                    <Link
                      to={"/"}
                      onClick={() => {
                        handleLogout();
                        handleLinkClick();
                      }}
                      className="mx-1"
                    >
                      Logout
                    </Link>
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
