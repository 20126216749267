import React, { useEffect, useState } from 'react'
import { GetBreedsByPetId, GetParentPetTypes, GetPetTypes } from '../../../Service/PetServices';
import { useAuth } from '../../../Contexts/AuthContext';
import { getuid } from 'process';
import { randomInt } from 'crypto';

type PetTypeAndBreedTypes = {
    petType: string,
    petTypeChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
    petBreedId: number,
    petBreedChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
    className: string
    petTypeClassName: string
    petBreedClassName: string
}

const PetBreedAndBreedSelects = (props: PetTypeAndBreedTypes) => {

    const { UserToken } = useAuth()

    const [petTypesOptions, setPetTypesOptions] = useState([{ key: 'Select Type', value: 'select' }])

    const [petBreedsOptions, setPetBreedsOptions] = useState([{ key: 'Select Breed', value: 'select' }])



    const GetPetBreedsById = async (PetId: any) => {

        setPetBreedsOptions([{ key: 'Select Breed', value: 'select' }]);

        if (PetId !== 'select') {

            await GetBreedsByPetId(PetId, UserToken.token)
                .then(res => {
                    res.data.map(breed => {
                        // if(petBreedsOptions.find(a => a.key == breed.name) == undefined)
                        setPetBreedsOptions(oldValues => [...oldValues.filter(a => a.key !== breed.name), { key: breed.name, value: breed.id.toString() }])
                    })
                })
        }
    }

    // useEffect(() => {
    //     // GetPetBreedsById(props.petType);

    // }, [props.petType])


    const [parentPetTypes, setParentPetTypes] = useState([])

    useEffect(() => {

        (async () => {

            await GetParentPetTypes(UserToken.token)
                .then(res => {
                    setParentPetTypes(res.data)
                    // res.data.map((petType : any) => {
                    //     // setPetTypesOptions(oldValues => [...oldValues.filter(a => a.key !== petType.name), { key: petType.name, value: petType.id.toString() }])
                    // })
                })
                .catch(err => {
                    console.log('err from GetPet Types ', err);
                })


        })()

    }, [])




    return (
        <>
            <div className={props.petTypeClassName}>
                <select name="type" id="ptype" className="form-select"
                    value={props.petType}
                    onChange={props.petTypeChange}

                >
                    <option
                        value={`select`}
                    >
                        Select Pet Type
                    </option>

                    {

                        parentPetTypes.map(
                            (system: {
                                id: number, name: string, subTypes: [string]
                                // , createdOn: string, isActive : boolean
                            }, index) => (
                                <>
                                    <option
                                        key={system.id}
                                        value={`${system.name}-Parent`}
                                        style={{ color: '#103a3a' }}
                                        className='fw-bold fs-5'
                                    >
                                        {system.name}
                                    </option>


                                    {system.subTypes.map((type, index1) => {
                                        return (
                                            <option key={index1}
                                                value={`${type}-Child`}
                                                className=''
                                            >
                                                {type}
                                            </option>
                                        )
                                    })}
                                </>))

                    }

                </select>
            </div>

            {/* <div className={props.petBreedClassName}>

                <select name="category" id="pcategory" className="form-select"
                    value={props.petBreedId}
                    onChange={props.petBreedChange}
                >
                    {petBreedsOptions.map((type) =>
                        <option
                            value={type.value}
                        >
                            {type.key}
                        </option>
                    )}
                </select>


            </div> */}
        </>
    )
}

export default PetBreedAndBreedSelects