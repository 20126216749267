import React, { useState, useEffect } from 'react'
import new_parrot from "../Assets/Images/new-parrot.jpg";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Form as BSForm, Spinner, Button, Card, FormLabel, FormSelect } from 'react-bootstrap-v5'
import FormikControl from '../Components/ReusableFormikComponents/FormikControl'
import axios from 'axios'
import { changePassword, login, register, requestPassword } from '../Service/AuthServices';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext';

const ForgotPassword = () => {


    useEffect(() => {

        window.scrollTo(0, 0);

    }, [])

    //    console.log('url : ',url);
    const initialValues = {
        email: ''
    }


    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Required').email()
    })




    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState('')


    const [checkEmailMsg, setCheckEmailMsg] = useState(false)


    onsubmit = (values) => {


        setHasErrors('');

        window.scrollTo(0, 0);
        // values.CreatedBy = UserToken.LoggedInUserId;




        setLoading(true)

        console.log('values***--- ', values);

        requestPassword(values.email).then(
            (response) => {


                setLoading(false)

                setCheckEmailMsg(true)




                // toast.success('User created successfully', { position: toast.POSITION.TOP_RIGHT });

                // history.push('/users/AllUsers');

            })
            .catch((error) => {
                setLoading(false)

                let Obj = error.toJSON();
                console.log('1111111');
                console.log('Obj', Obj);
                if (Obj.message === 'Network Error') {

                    setHasErrors('API Server is down....');
                }
                else {
                    let obj2 = JSON.parse(Obj.message);

                    setHasErrors(obj2.errorMessage);
                    // toast.error(obj2.errorMessage, { position: toast.POSITION.TOP_RIGHT });

                }

            })


    }








    return (
        <div className="container">
            <div className="breadcrums mt-2">
                <ul>
                    <li><Link to={'/home'} className='mx-2'>Home </Link></li>
                    <li><Link to={''} className='mx-2'> Forgot Password </Link></li>

                </ul>
            </div>
            <div className="reg">
                <div className="row">
                    <div className="col col-12 col-lg-6">
                        <img src={
                            new_parrot
                        } />
                    </div>
                    <div className="col col-12 col-lg-6 ">

                        {checkEmailMsg ? (
                            <div className='mb-lg-15 p-5  mt-5 m-2 alert alert-primary'>
                                <div className='alert-text font-weight-bold'>
                                    Check Your Email for Confirmation Link
                                </div>
                            </div>
                        ) :
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={onsubmit}
                            >
                                {
                                    formik => {
                                        return (
                                            <Form className='reg-form form-large'>


                                                <h2>Forgot Password ?</h2>
                                                <h4 className='text-muted text-bold text-center mb-5'>Enter your email to reset your password.</h4>

                                                {hasErrors ? (
                                                    <div className='mb-lg-15 alert alert-danger'>
                                                        <div className='alert-text font-weight-bold'>{hasErrors}</div>
                                                    </div>
                                                ) : ''}



                                                <FormikControl
                                                    control='input'
                                                    type='text'
                                                    placeholder='Enter Your Email'
                                                    name='email'
                                                    className='form-item  large'
                                                />




                                                <button type="submit">
                                                    <div className='d-flex justify-content-center'>

                                                        SUBMIT
                                                        {loading && (
                                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                                <span className='spinner-border spinner-border-sm align-middle m-1 mb-2'></span>
                                                            </span>
                                                        )}
                                                    </div>
                                                </button>







                                            </Form>
                                        )
                                    }}

                            </Formik>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword