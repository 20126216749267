import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PetCard from '../Components/widgets/PetCard';
import { useQuery } from 'react-query';
import { FadeLoader } from 'react-spinners';
import { GetAllPets, GetNewPets } from '../Service/PetServices';
import { useAuth } from '../Contexts/AuthContext';
import { ThemeColor } from '../types/DefaultThemeColor';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 10,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

interface PetSectionProps {
  petType: string;
  title?: string;
  showViewAllButton?: boolean;
}

const PetSection: React.FC<PetSectionProps> = ({ petType, title, showViewAllButton = true }) => {

  const { UserToken } = useAuth();
  
  const {
    isLoading: AllPetsLoading,
    data: SectionPets,
    isError: isAllPetsError,
    isFetching: isAllPetsFetching,
    error: AllPetsError,
    refetch: refetchAllPets,
    isRefetching: isRefetchingAllPets,
  } = useQuery(
    "SectionPets"+petType,
    () => {

      let childPetType = null;
      let parentPetType = null;


        petType!.split('-')[1] == 'Child' ? childPetType = petType!.split('-')[0]
          : parentPetType = petType!.split('-')[0]
    
      return GetAllPets(
        "",
        "",
        "",
        parentPetType!,
        childPetType!,
        0,
        0,
        0,
        0,
        1,
        4,
        UserToken.userId,
        UserToken.token
      );
    },
    {
      // enabled: !!UserToken.UserDetailsId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 30000,
      // refetchInterval :
      // retry: false,
      onSuccess: (res) => {

        console.log("Success of AllPets in Pets Listing  ");

      },
      onError: (err) => {
        // let Obj /* : ErrorResponseModel */ = err;
      },
    }
  );



  return (
    <div className="container">
      <div className="view-all-card">
        <div className="row">
          <div className="col-6">
            <h4 className="Home-Category" style={{ fontWeight: 'bold' }}>{title}</h4>  
          </div>
          <div className="col-6 text-end">
            {showViewAllButton && 
            <Link to={`/pet-search?petType=${petType}`} className="view-all-button">View All</Link>
          }
          </div>
        </div> 

        
        <div className="row">
          <div className="pet d-flex justify-content-center">{AllPetsLoading && <FadeLoader color={ThemeColor} />}</div>
          
          {!!SectionPets && SectionPets.data && (
        <Carousel responsive={responsive} removeArrowOnDeviceType={["mobile"]} > 
          {SectionPets?.data!.petListViewModel.map((petItem, index) => {
          
            return(
              <div className=" cc col-12 col-md-4 col-lg-3" key={index}>
                <PetCard PetObj={petItem} isFromHomePage={true} />
              </div>
            )
          })}
        </Carousel> 
        )}
        </div>
      </div>
    </div>
  );
};

export default PetSection;
