// src/ShopOwnerCategories.js
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Pet from '../Assets/Images/img__3.jpg';
import PetAccessories from '../Assets/Images/PetAccessories.jpg';
import PetFood from '../Assets/Images/PetFood.jpg';
import DashboardHeader from "../Components/widgets/DashboardHeader";

const categories = [
  { id: 1, label: "Post Animal", image: Pet },
  { id: 3, label: "Pet Food", image: PetFood },
  { id: 2, label: "Pet Accessories", image: PetAccessories },
];

const ShopOwnerCategories = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const navigate = useNavigate();

  const [materialType, setMaterialType] = useState("")

  const handleCategoryClick = (category) => {
    setErrorMsg("")
    if (category.label.includes("Accessories")) setMaterialType("Accessories")
    if (category.label.includes("Food")) setMaterialType("Food")


    setSelectedCategory(category.id);

  };

  const handleNextClick = () => {
    if (selectedCategory !== null) {
      console.log(`Selected Category ID: ${selectedCategory}`);
      if (selectedCategory === 1) {
        navigate("/add-product")
      }
      else if (selectedCategory === 2) {
        navigate(`/add-material?Type=${materialType}`)
      }
      else if (selectedCategory === 3) {
        navigate(`/add-material?Type=${materialType}`)
      }
    } else {
      setErrorMsg("Please select category first to move forward")
    }
  };

  const [errorMsg, setErrorMsg] = useState("")

  return (
    <div className="container">
      <DashboardHeader />
      <div className="post-your-ad mt-3">
        <h5 className="post-text py-4">Post Your Ad</h5>
      </div>
      
      <div className="shop-owner-categories mt-3">
        <h5 className="post-category">Select Category</h5>

        {errorMsg ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{errorMsg}</div>
          </div>
        ) : ''}

        <div className="ads-categories">
          {categories.map((category) => (
            <div
              key={category.id}
              className={`ads-category ${selectedCategory === category.id ? "selected" : ""}`}
              onClick={() => handleCategoryClick(category)}
            >
              <img src={category.image} alt={category.label} className="rounded-circle mx-2" />
              <span>{category.label}</span>
            </div>
          ))}
        </div>
        <div className="nav-ads d-flex justify-content-between mb-3">
          <Button variant="secondary"  className="btn-lg"> <span className="mx-2">Back</span></Button>
          <Button variant="primary" className="btn-lg" onClick={handleNextClick}>
            <span className="mx-2">Next </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ShopOwnerCategories;
