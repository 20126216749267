import React from 'react';
import logo from "../Assets/Images/sqlogo.jpeg";


const AboutUsPage = () => {
  return (
    <div className="container">
      <h1>About Us</h1>
      <div className="row mt-3 mb-4">
        <div className="col-md-8">
          <p>
            PakAnimals.com is Pakistan’s premier online marketplace for buying, selling, and caring for animals, pets, and birds. Our user-friendly platform offers a seamless experience, connecting animal lovers, pet enthusiasts, and farmers nationwide. With a diverse range of listings, including household pets, exotic birds, and essential livestock, we cater to all your needs in one convenient place. In addition to facilitating animal transactions, we provide pet food, accessories, and services to ensure your pets are well taken care of.
          </p>
          <p>
            At PakAnimals.com, we prioritize safety and ease of use. Farmers and sellers can advertise their animals with just one click, reaching a broad audience across Pakistan. Our platform ensures secure transactions and verified listings, giving you peace of mind whether you're buying or selling. Join our vibrant community today and experience the best in online animal trading, where convenience meets trust and reliability.
          </p>
          <p>
            PakAnimals.com is Pakistan’s premier online marketplace for buying, selling, and caring for animals, pets, and birds.  We understand the challenges faced by farmers and animal enthusiasts when it comes to traditional methods of buying and selling. That’s Why we are offering a user-friendly way to make the process as seamless as possible.
          </p>
          <p>
            With just a few clicks, you can browse through a diverse range of listings, from household pets to essential livestock, all in one convenient place. No more travelling long distances or dealing with middlemen. We bring the market to your fingertips, saving you time and effort. Moreover, we offer pet food, accessories, and services, ensuring that your pets are well taken care of. Our skilled team of veterinary doctors assists, giving you peace of mind about the health of your animals.
          </p>
        </div>
        <div className="col-md-4">
          <img src={logo} alt="Logo" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
