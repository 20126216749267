import React, { ReactNode } from 'react'
import { Modal, Button } from 'react-bootstrap'

type ModalProps = {
  show: boolean
  handleClose: () => void
  children : ReactNode

}

const ModalComponent2 = (props: ModalProps) => {

  return (
    <Modal show={props.show} onHide={props.handleClose} size='lg' centered>
      <Modal.Body className='p-5'>
        {props.children}
      </Modal.Body>
    </Modal>
  )
}

export default ModalComponent2