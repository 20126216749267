import React, { useEffect, useState } from 'react'
import NewPet from '../Components/widgets/PetCard'
import { useQuery } from 'react-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong, faArrowRightLong, faArrowsRotate, faFilter, faSearch, faSliders } from '@fortawesome/free-solid-svg-icons'
import MyPagination from '../Components/Pagination/Pagination'
import { Link, useLocation } from 'react-router-dom'
import LoaderAnimation from '../Components/widgets/LoaderAnimation'
import GridListing from '../Components/widgets/GridListing'
import { useAuth } from '../Contexts/AuthContext'
import PetBreedAndBreedSelects from '../Components/widgets/TypeAndBreedSelectComponent/PetTypeAndBreedSelects'
import CitySelectComponent from '../Components/widgets/CitySelectComponent'
import { GetFilteredMaterials } from '../Service/MaterialServices'
import { GetPetTypes } from '../Service/PetServices'
import MaterialCard from '../Components/widgets/MaterialCard'
import { MaterialType } from '../types/MaterialType'
import { useMediaQuery } from 'react-responsive'

const StoreListing = () => {


  {
    /*  from tay */
  }

  const isMobile = useMediaQuery({ query: "(max-width: 450px)" });

  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
  };
  {
    /* end from tay */
  }



  const [range, setRange] = useState(0)

  // const [petTypeId, setpetTypeId] = useState(0)
  const [petBreedId, setpetBreedId] = useState(0)
  const [search, Setsearch] = useState("")
  const [searchTerm, SetsearchTerm] = useState("")

  const [minPriceRage, setMinPriceRage] = useState(0)
  const [maxPriceRange, setMaxPriceRange] = useState(0)

  const [city, setCity] = useState("")


  const [petTypeValue, setPetTypeValue] = useState("")




  const [selectedCityOption, setSelectedCityOption] = useState<{ label: string; value: string; } | null>()

  const handleChange = (selectedCityOption: any) => {


    setCity(selectedCityOption.value)
    setSelectedCityOption(selectedCityOption)

  }

  const [minPriceTerm, setMinPriceTerm] = useState(0)
  const [maxPriceTerm, setMaxPriceTerm] = useState(0)

  const [childPetType, setChildPetType] = useState("")

  const [parentPetType, setParentPetType] = useState("")

  const [sortBy, SetSortBy] = useState("")

  const [currPage, setCurrPage] = React.useState(1);

  const [pageSize, setPageSize] = useState(6)

  const [totalCount, setTotalCount] = useState(0)

  const { UserToken, setUserToken } = useAuth()

  const ResetFilteringAndSearching = () => {


    setPetTypeValue("")
    setChildPetType("");
    setParentPetType("");

    setCity("")
    // setpetTypeId(0)
    setpetBreedId(0)
    setRange(0)
    Setsearch("")
    setMaxPriceRange(0)
    setMinPriceRage(0)
    setMinPriceTerm(0)
    setMaxPriceTerm(0)
    setSelectedCityOption(null)
    SetsearchTerm("")
    SetSortBy("")
    setCurrPage(1)
  }





  const {
    isLoading: AllMaterialsLoading,
    data: AllMaterials,
    isFetching: isAllMaterialsFetching,
    isError: isAllMaterialsError,
    error: AllMaterialsError,
    refetch: refetchAllMaterials,
    isRefetching: isRefetchingAllMaterials
  }
    = useQuery('AllMaterials', () => {
      return GetFilteredMaterials(
        city,
        searchTerm,
        sortBy,
        parentPetType,
        childPetType,
        range,
        minPriceTerm,
        maxPriceTerm,
        currPage,
        pageSize,
        UserToken.userId,
        UserToken.token)
    },
      {
        // enabled: !!UserToken.UserDetailsId,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: 30000,
        // refetchInterval : 
        // retry: false,
        onSuccess: (res) => {
          setFilterLoading(false)

          // console.log('Success of AllMaterials :  ', res);

        },
        onError: (err) => {
          setFilterLoading(false)
          // let Obj /* : ErrorResponseModel */ = err;
        }

      }
    )

  const [filterLoading, setFilterLoading] = useState(false)

  useEffect(() => {


    if (!!city || !!searchTerm || !!sortBy || !!childPetType || !!parentPetType || !!petBreedId || !!range || !!minPriceRage || !!maxPriceRange || currPage !== 1)
      setFilterLoading(true)

    refetchAllMaterials()

  }, [city, searchTerm, sortBy, childPetType, parentPetType, range, minPriceTerm, maxPriceTerm, currPage, pageSize])


  const location = useLocation()

  const urlParams = new URLSearchParams(location.search)


  const onPetBreedChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value !== "select") {
      setCurrPage(1);

      setpetBreedId(parseInt(event.target.value));
    } else setpetBreedId(0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!!location.search) {
      let city = urlParams.get("city")
      // let petTypeId = urlParams.get("petTypeId")


      // console.log('city : ', city);
      // console.log('petTypeId : ', petTypeId);
      // console.log('petBreedId : ', petBreedId);


      if (!!city) {
        setCity(city)
        setSelectedCityOption({ label: city!, value: city! })
      }

      // if (!!petTypeId)
      //   setpetTypeId(parseInt(petTypeId!))




    }

  }, [location.search])








  useEffect(() => {

    if (AllMaterials?.data.totalCount !== undefined) {
      setTotalCount(AllMaterials?.data.totalCount)
    }


  }, [AllMaterials?.data.totalCount])


  const onPetTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {

    if (event.target.value !== "select") {
      setCurrPage(1);

      setPetTypeValue(event.target.value)

      var splittedValue = event.target.value!.split('-');


      if (splittedValue[1] == 'Child') {
        setParentPetType(""); setChildPetType(splittedValue[0]);
      }

      if (splittedValue[1] == 'Parent') { console.log('setting parent'); setChildPetType(""); setParentPetType(splittedValue[0]); }

      // GetPetBreedsById(parseInt(event.target.value));

    } else {
      setPetTypeValue("")
      setChildPetType("")
      setParentPetType("")

    }


  }


  const [petTypesOptions, setPetTypesOptions] = useState([{ key: 'Select Type', value: 'select' }])


  useEffect(() => {

    (async () => {

      await GetPetTypes(UserToken.token)
        .then(res => {
          res.data.map(petType => {
            setPetTypesOptions(oldValues => [...oldValues.filter(a => a.key !== petType.name), { key: petType.name, value: petType.id.toString() }])
          })
        })
        .catch(err => {
          console.log('err from GetPet Types ', err);
        })


    })()

  }, [])










  return (
    <div className="shop-listing">

      <div className="container">
        <div className="breadcrums mt-2">
          <ul>
            <li><Link to={'/home'} className='mx-2'>Home </Link></li>
            <li><Link to={''} className='mx-2'> Materials </Link></li>
          </ul>
        </div>

        {/*  from tay */}
        {isMobile && (
          <div className="d-block d-md-none d-flex justify-content-end">
            <button className="btn-filter mx-1 " type="button" onClick={toggleFilterVisibility}>
              <FontAwesomeIcon className="mx-1" color="ffffff" icon={faFilter} />
            </button>
          </div>
        )}
        {/* end from tay */}

        <div>
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="adv-search side-panel">
                <h4 className="section-heading">Advance Search</h4>

                <div className="search-form">

                  <div className="input-group mb-3">
                    <input
                      type="text"
                      placeholder="Search Pet Name"
                      className="form-control"
                      id="Query" aria-describedby=""
                      value={search} onChange={(event) => {

                        Setsearch(event.target.value)
                      }}
                      onBlur={(event) => {

                        setCurrPage(1)

                        SetsearchTerm(event.target.value)

                      }}
                    />
                    {/* <div className="input-group-append">
                      <button type='button' className="input-group-text mx-0" id="basic-addon2">
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div> */}
                  </div>


                  {/* ------------------------------------------- */}


                  <PetBreedAndBreedSelects
                    petType={petTypeValue}
                    petTypeChange={onPetTypeChange}
                    petBreedId={petBreedId}
                    petBreedChange={onPetBreedChange}
                    className=''
                    petTypeClassName='mb-3'
                    petBreedClassName='mb-3'

                  />

                  {/* <div className={'col-12 col-md-6 col-lg-3 w-100 mb-3'}>

                    <select name="category" id="pcategory" className="form-select "
                      value={petTypeId}

                      onChange={onPetTypeChange}
                    >
                      {petTypesOptions.map((type) =>
                        <option
                          value={type.value}
                        >
                          {type.key}
                        </option>
                      )}
                    </select>
                  </div>   */}







                  <div className="mb-3">
                    <CitySelectComponent
                      showLabel={false}
                      placeholder='Search City...'
                      showCityErrorMsg={false}
                      selectedOption={selectedCityOption!}
                      handleChange={handleChange}
                      selectControlClassName=''
                    />

                  </div>




                  <div className="mb-3 d-flex">

                    <div className="form-group w-100 m-1">
                      <label htmlFor="minPriceRange">Min Range</label>

                      <input
                        id='minPriceRange'
                        type="text"
                        className="form-control m-1"
                        value={minPriceRage}
                        onChange={(event) => {

                          if (Number.isInteger(parseInt(event.target.value))) {
                            setMinPriceRage(parseInt(event.target.value))
                          } else {
                            setMinPriceRage(0)
                          }

                        }}

                        onBlur={(event) => {
                          // setCurrPage(1)
                          if (maxPriceTerm !== 0)
                            setMinPriceTerm(parseInt(event.target.value))
                        }}
                      />
                    </div>

                    <div className="form-group w-100 m-1">
                      <label htmlFor="maxPriceRange">Max Range</label>

                      <input
                        type="number"
                        id='maxPriceRange'
                        className="form-control m-1"
                        aria-describedby=""
                        value={maxPriceRange}
                        onChange={(event) => {

                          if (Number.isInteger(parseInt(event.target.value))) {
                            setMaxPriceRange(parseInt(event.target.value))
                          } else {
                            setMaxPriceRange(0)
                          }
                        }}

                        onBlur={(event) => {
                          // setCurrPage(1)
                          setMinPriceTerm(minPriceRage)

                          setMaxPriceTerm(parseInt(event.target.value))
                        }}
                      />
                    </div>

                  </div>


                  {/* <div className="mb-3 range-slider">
                    <div className="tags">
                      <span>Price ({range})</span>
                      <span className="price">RS 0 - RS 100000</span>
                    </div>
                    <input id="" className="slider" type="range" value={range} max="100000" min="0"
                      step="1000" onChange={(event) => setRange(parseInt(event.target.value))
                      } />
                  </div> */}


                  <div className="d-flex justify-content-center">
                    <button className='mx-1' type='button' onClick={ResetFilteringAndSearching}>
                      <FontAwesomeIcon icon={faArrowsRotate} />
                      Reset
                    </button>
                    <button className='mx-1' type='button'>SEARCH</button>
                  </div>



                </div>

              </div>

            </div>

            <div className="col-12 col-lg-9 results">
              <div className="row ">
                <div className="col-12 col-lg-6">
                  <h5> <span>Showing </span>
                    {((pageSize * currPage) + 1) - pageSize < totalCount
                      ?
                      ((pageSize * currPage) + 1) - pageSize
                      : totalCount}
                    <span> - </span>
                    {(pageSize * currPage) < totalCount ? (pageSize * currPage) : totalCount}
                    <span> of </span>
                    {totalCount}
                    <span> results </span>
                  </h5>
                </div>
                <div className="col-12 col-lg-6 sort">
                  <div className="sort">
                    <span>Sort By:</span>
                    <span>
                      <select name="sort" id="sort" className="form-select"
                        value={sortBy}
                        onChange={(event) => SetSortBy(event.target.value)}
                      >
                        <option value="">Default</option>
                        <option value="Price">By price</option>
                        <option value="Pet">By Pet Name</option>
                      </select>
                    </span>
                  </div>

                  {/* <ul className="page-header icons">
                    <li><a href="#"><i className="fa-solid fa-list"></i></a></li>
                    <li><a href="#"><i className="fa-solid fa-border-all"></i></a></li>
                  </ul> */}

                </div>
              </div>

              {(AllMaterialsLoading || filterLoading || isAllMaterialsFetching) &&
                <LoaderAnimation />
              }



              {(!!totalCount) ?
                <MyPagination

                  totPages={Math.ceil(totalCount / pageSize)}
                  currentPage={currPage}
                  pageClicked={(page_number: number) => {
                    // afterPageClicked(ele);
                    setCurrPage(page_number);


                  }}>

                  <div className="product-sec"
                  >
                    <div className="row">


                      {!isAllMaterialsFetching && AllMaterials?.data.materialListViewModel!.map((Item, index) => (
                        <div className="col-12 col-lg-4" key={index}>

                          <MaterialCard
                            MaterialObj={Item}
                          />
                        </div>

                      )
                      )}



                    </div>
                  </div>


                </MyPagination>
                : ""}


            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default StoreListing