import React, { useState } from 'react'
import PropTypes from 'prop-types'
import new_parrot from "../../Assets/Images/new-parrot.jpg";
import { Link } from 'react-router-dom';
import { API_URL } from '../../Service/PetServices';
import { useAuth } from '../../Contexts/AuthContext';
import { FavoriteAndUsersPetType } from '../../types/FavoriteAndUsersPetType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette, faPaw, faPencil, faSyringe, faTrashCan } from '@fortawesome/free-solid-svg-icons';

type PropsType = {
    PetObj: FavoriteAndUsersPetType
    onEditClick: (id: number) => void | null
    showEdit: boolean
    onDeleteClick : () => void

    // onClick(PetObj: PetObjTYpe, userId: string, ) : void
}
const PetGridCard = (props: PropsType) => {

    const { UserToken, setUserToken } = useAuth()


    var PetObj = props.PetObj;


    // console.log('on click in PetGridCard : ', props.onClick);






    return (

        // <img className="card-img-top" src={`${API_URL}/${PetObj.files[0]}`} alt="Card image cap" /> </Link>
        <div className="sec-content">
            <div className="row">
                <div className="col-5 col-lg-2">
                    <Link to={`/pet-details/${PetObj.nickName}`}><img className="card-img-top" src={`${API_URL}/${PetObj.image}`} alt="Card image cap" /> </Link>
                </div>
                <div className="col-7 col-lg-10">
                    <div className="content-header">
                        <div>
                            <h3>{PetObj.nickName}</h3>
                            <p><span className='text-muted'>Last Upated</span> {new Date(PetObj.updatedOn).toDateString()}</p>
                        </div>
                        <div className="icons">
                            <span>
                                <button className='mx-1' onClick={() => props.onDeleteClick()}>
                                    <FontAwesomeIcon icon={faTrashCan} />
                                </button>
                                {props.showEdit &&
                                    <button className='mx-1' onClick={() => props.onEditClick(PetObj.id)}>
                                        <FontAwesomeIcon icon={faPencil} />
                                    </button>
                                }
                            </span>
                        </div>
                    </div>
                    <div className="content-footer">
                        <ul>
                            <li>
                                <FontAwesomeIcon className='mx-1' icon={faPalette} />{PetObj.color}
                            </li>
                            <li>
                                <FontAwesomeIcon className='mx-1' icon={faPalette} />{PetObj.age}</li>
                            <li>
                                <FontAwesomeIcon className='mx-1' icon={faSyringe} />{PetObj.isVaccinated ? "Yes" : "No"}
                            </li>
                            <li>
                                <FontAwesomeIcon className='mx-1' icon={faPaw} />{PetObj.group}
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>


    )
}



export default PetGridCard