import { AddMaterialToFavorite, RemoveMaterialFromFavorite } from '../Service/MaterialServices';
import {  AddPetToFavorite, RemovePetFromFavorite } from '../Service/PetServices';
import { MaterialType } from './MaterialType';
import { PetObjTYpe } from './PetObjType';


export const onPetHeartClick = (PetObj : PetObjTYpe, userId : string, refetch : () => void, userToken : string ) => {

    console.log('On HeartClick call ** ');
    
    if(PetObj?.isFavorite){
        
        RemovePetFromFavorite(PetObj?.id!, userId,userToken)
        .then(res => {
            refetch()

        })
        .catch(err => {

        })

    }else{
        AddPetToFavorite(PetObj?.id!, userId,userToken)
        .then(res => {
            refetch()
        })
        .catch(err => {
            
        })

    }
}

export const onMaterialHeartClick = (MaterialObj : MaterialType, userId : string, refetch : () => void, userToken : string ) => {


    if(MaterialObj?.isFavorite){
        
        RemoveMaterialFromFavorite(MaterialObj?.id!, userId,userToken)
        .then(res => {
            refetch()

        })
        .catch(err => {

        })

    }else{
        AddMaterialToFavorite(MaterialObj?.id!, userId,userToken)
        .then(res => {
            refetch()
        })
        .catch(err => {
            
        })

    }
}