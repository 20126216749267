import React, { useEffect, useState } from "react";
import NewPet from "../Components/widgets/PetCard";
import { useQuery } from "react-query";
import { GetAllPets, GetBreedsByPetId, GetNewPets, GetPetTypes } from "../Service/PetServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faArrowRightLong,
  faArrowsRotate,
  faFilter,
  faSearch,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import MyPagination from "../Components/Pagination/Pagination";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import LoaderAnimation from "../Components/widgets/LoaderAnimation";
import GridListing from "../Components/widgets/GridListing";
import { useAuth } from "../Contexts/AuthContext";
import PetBreedAndBreedSelects from "../Components/widgets/TypeAndBreedSelectComponent/PetTypeAndBreedSelects";
import CitySelectComponent from "../Components/widgets/CitySelectComponent";
import { PetObjTYpe } from "../types/PetObjType";
import { useMediaQuery } from "react-responsive";

const PetsListing = () => {
  {
    /*  from tay */
  }

  const isMobile = useMediaQuery({ query: "(max-width: 450px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 912px)" });

  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
  };
  {
    /* end from tay */
  }

  const [range, setRange] = useState(0);

  const [childPetType, setChildPetType] = useState("")

  const [petTypeValue, setPetTypeValue] = useState("")

  const [parentPetType, setParentPetType] = useState("")

  const [petBreedId, setpetBreedId] = useState(0)
  const [search, Setsearch] = useState("")
  const [searchTerm, SetsearchTerm] = useState("")

  const [minPriceRage, setMinPriceRage] = useState(0);
  const [maxPriceRange, setMaxPriceRange] = useState(0);

  const [city, setCity] = useState("")




  const [selectedCityOption, setSelectedCityOption] = useState<{ label: string; value: string; } | null>(null)

  const handleChange = (selectedCityOption: any) => {
    setCity(selectedCityOption.value);
    setSelectedCityOption(selectedCityOption);
  };

  const [minPriceTerm, setMinPriceTerm] = useState(0);
  const [maxPriceTerm, setMaxPriceTerm] = useState(0);

  const [sortBy, SetSortBy] = useState("");

  const [currPage, setCurrPage] = React.useState(1);

  const [pageSize, setPageSize] = useState(9);

  const [totalCount, setTotalCount] = useState(0);

  const { UserToken, setUserToken } = useAuth();

  const ResetFilteringAndSearching = () => {

    setPetTypeValue("")
    setChildPetType("");
    setParentPetType("");
    setRange(0);

    setpetBreedId(0);
    setMinPriceRage(0);
    setMaxPriceRange(0);

    setMinPriceTerm(0);
    setMaxPriceTerm(0);

    setCity("");
    setSelectedCityOption(null);
    Setsearch("");
    SetsearchTerm("");
    SetSortBy("");

    setCurrPage(1);
  };

  const [maxPrice, setMaxPrice] = useState(0);

const [searchParams, setSearchParams] = useSearchParams();

  const {
    isLoading: AllPetsLoading,
    data: AllPets,
    isError: isAllPetsError,
    isFetching: isAllPetsFetching,
    error: AllPetsError,
    refetch: refetchAllPets,
    isRefetching: isRefetchingAllPets,
  } = useQuery(
    "AllPets",
    () => {
      let petTypeParam = urlParams.get("petType")

      let childPetTypeParam = null;
      let parentPetTypeParam = null;

      if (!!petTypeParam) {


        petTypeParam!.split('-')[1] == 'Child' ? childPetTypeParam = petTypeParam!.split('-')[0]
          : parentPetTypeParam = petTypeParam!.split('-')[0]

      }

      setSearchParams("?")

      return GetAllPets(
        urlParams.get("city") ?? city,
        urlParams.get("searchTerm") ?? searchTerm,
        sortBy,
        !!urlParams.get("petType") ? parentPetTypeParam! : parentPetType,
        !!urlParams.get("petType") ? childPetTypeParam! : childPetType,
        petBreedId,
        range,
        minPriceTerm,
        maxPriceTerm,
        currPage,
        pageSize,
        UserToken.userId,
        UserToken.token
      );
    },
    {
      // enabled: !!UserToken.UserDetailsId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 30000,
      // refetchInterval :
      // retry: false,
      onSuccess: (res) => {
        setFilterLoading(false);
        console.log("Success of AllPets in Pets Listing  ");

        setMaxPrice(res.data.maxPrice);
      },
      onError: (err) => {
        setFilterLoading(false);
        // let Obj /* : ErrorResponseModel */ = err;
      },
    }
  );

  const [filterLoading, setFilterLoading] = useState(false);



  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!!location.search) {

      let city = urlParams.get("city");
      let petType = urlParams.get("petType")

      console.log('petType : ', petType);


      if (!!petType) {


        setPetTypeValue(petType);
        var splittedValue = petType!.split('-');



        if (splittedValue[1] == 'Child') { setParentPetType(""); setChildPetType(splittedValue[0]); }

        if (splittedValue[1] == 'Parent') { setChildPetType(""); setParentPetType(splittedValue[0]); }


      }



      let petBreedId = urlParams.get("petBreedId")


      if (!!searchTerm) {
        Setsearch(searchTerm!);
        SetsearchTerm(searchTerm!);
      }

      if (!!city) {
        setCity(city);
        setSelectedCityOption({ label: city!, value: city! });
      }


      if (petBreedId) setpetBreedId(parseInt(petBreedId!));
    }
  }, [location.search]);


  useEffect(() => {


    console.log('refetch All Pets [*1*] ');

    if (!!city || !!searchTerm || !!sortBy || !!childPetType || !!parentPetType || !!petBreedId || !!range || !!minPriceRage || !!maxPriceRange || currPage !== 1)
      setFilterLoading(true)

    console.log('refetch All Pets [*2*] ');
    


    refetchAllPets()



  }, [city, searchTerm, sortBy, childPetType, parentPetType, petBreedId, range, minPriceTerm, maxPriceTerm, currPage, pageSize])

  useEffect(() => {
    if (AllPets?.data.totalCount !== undefined) {
      setTotalCount(AllPets?.data.totalCount);
    }
  }, [AllPets?.data.totalCount]);

  const onPetTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {

    if (event.target.value !== "select") {
      setCurrPage(1);

      setPetTypeValue(event.target.value)

      var splittedValue = event.target.value!.split('-');


      if (splittedValue[1] == 'Child') {
        setParentPetType(""); setChildPetType(splittedValue[0]);
      }

      if (splittedValue[1] == 'Parent') { console.log('setting parent'); setChildPetType(""); setParentPetType(splittedValue[0]); }

      // GetPetBreedsById(parseInt(event.target.value));

    } else {
      setPetTypeValue("")
      setChildPetType("")
      setParentPetType("")

    }


  }

  const onPetBreedChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value !== "select") {
      setCurrPage(1);

      setpetBreedId(parseInt(event.target.value));
    } else setpetBreedId(0);
  };

  useEffect(() => {
    refetchNewPets();
  }, []);

  const {
    isLoading: NewPetsLoading,
    data: NewPets,
    isFetching: isNewPetsFetching,
    isError: isNewPetsError,
    error: NewPetsError,
    refetch: refetchNewPets,
    isRefetching: isRefetchingNewPets,
  } = useQuery(
    "NewPets",
    () => {
      return GetNewPets(UserToken.userId, UserToken.token);
    },
    {
      // enabled: !!UserToken.UserDetailsId,
      refetchOnMount: false,
      refetchOnWindowFocus: true,
      staleTime: 30000,
      // refetchInterval :
      // retry: false,
      onSuccess: (res) => {
        console.log("Success of GetNewPets in PetsListing  ");
      },
      onError: (err) => {
        // let Obj /* : ErrorResponseModel */ = err;
      },
    }
  );

  return (
    <div className="container">
      <div className="breadcrums mt-2">
        <ul>
          <li>
            <Link to={"/home"} className="mx-2">
              Home{" "}
            </Link>
          </li>
          <li>
            <Link to={""} className="mx-2">
              {" "}
              Pets{" "}
            </Link>
          </li>
        </ul>
      </div>

      {/*  from tay */}

      {(isMobile || isTablet) && (
        <div className="d-flex justify-content-end">
          <button className="btn-filter mx-1 " type="button" onClick={toggleFilterVisibility}>
            <FontAwesomeIcon className="mx-1" color="ffffff" icon={faFilter} />
          </button>
        </div>
      )}
      {/* end from tay */}

      <div>
        <div className="row">
          <div className="col-12 col-lg-3">
            {/*  from tay */}
            {(!isMobile && !isTablet || isFilterVisible) && (
              <div className="adv-search side-panel">
                <h4 className="section-heading">Advance Search</h4>

                <div className="search-form">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      placeholder="Search Pet Name"
                      className="form-control"
                      id="Query"
                      aria-describedby=""
                      value={search}
                      onChange={(event) => {
                        Setsearch(event.target.value);
                      }}
                      onBlur={(event) => {
                        setCurrPage(1);

                        SetsearchTerm(event.target.value);
                      }}
                    />
                    {/* <div className="input-group-append">
                    <button type='button' className="input-group-text btn-sm mx-0" id="basic-addon2">
                      <FontAwesomeIcon icon={faSearch} />
                    </button>
                  </div> */}
                  </div>

                  <PetBreedAndBreedSelects
                    petType={petTypeValue}
                    petTypeChange={onPetTypeChange}
                    petBreedId={petBreedId}
                    petBreedChange={onPetBreedChange}
                    className=''
                    petTypeClassName='mb-3'
                    petBreedClassName='mb-3'

                  />






                  <div className="mb-3">
                    <CitySelectComponent
                      showLabel={false}
                      placeholder="Search City..."
                      showCityErrorMsg={false}
                      selectedOption={selectedCityOption!}
                      handleChange={handleChange}
                      selectControlClassName=""
                    />
                  </div>

                  <div className="mb-3 d-flex">
                    <div className="form-group w-100 m-1">
                      <label htmlFor="minPriceRange">Min Range</label>

                      <input
                        id="minPriceRange"
                        type="text"
                        className="form-control m-1"
                        value={minPriceRage}
                        onChange={(event) => {
                          if (Number.isInteger(parseInt(event.target.value))) {
                            setMinPriceRage(parseInt(event.target.value));
                          } else {
                            setMinPriceRage(0);
                          }
                        }}
                        onBlur={(event) => {
                          // setCurrPage(1)
                          if (maxPriceTerm !== 0) setMinPriceTerm(parseInt(event.target.value));
                        }}
                      />
                    </div>

                    <div className="form-group w-100 m-1">
                      <label htmlFor="maxPriceRange">Max Range</label>

                      <input
                        type="number"
                        id="maxPriceRange"
                        className="form-control m-1"
                        aria-describedby=""
                        value={maxPriceRange}
                        onChange={(event) => {
                          if (Number.isInteger(parseInt(event.target.value))) {
                            setMaxPriceRange(parseInt(event.target.value));
                          } else {
                            setMaxPriceRange(0);
                          }
                        }}
                        onBlur={(event) => {
                          // setCurrPage(1)
                          setMinPriceTerm(minPriceRage);

                          setMaxPriceTerm(parseInt(event.target.value));
                        }}
                      />
                    </div>
                  </div>

                  {/* <div className="mb-3 range-slider">
                  <div className="tags">
                    <span>Price ({range.toLocaleString()})</span>
                    <span className="price">RS 0 - RS {maxPrice.toLocaleString()}</span>
                  </div>
                  <input id="" className="slider" type="range" value={range} max={maxPrice} min="0"
                    step="1000" onChange={(event) => setRange(parseInt(event.target.value))
                    } />
                </div> */}

                  <div className="d-flex justify-content-center">
                    <button className="mx-1" type="button" onClick={ResetFilteringAndSearching}>
                      <FontAwesomeIcon icon={faArrowsRotate} />
                      Reset
                    </button>
                    <button className="mx-1" type="button">
                      SEARCH
                    </button>
                  </div>
                </div>
              </div>
            )}
            {/* end from tay */}

            {/* this div only display on desktop & tablet (not mobile) coze of using d-none */}
            <div className="listing-sec side-panel d-none d-lg-block">
              <h4 className="section-heading">Latest Listings</h4>

              {!isNewPetsFetching && <GridListing items={NewPets?.data!} />}
            </div>
          </div>

          <div className="col-12 col-lg-9 results">
            <div className="row ">
              <div className="col-12 col-lg-6">
                <h5>
                  {" "}
                  <span>Showing </span>
                  {pageSize * currPage + 1 - pageSize < totalCount ? pageSize * currPage + 1 - pageSize : totalCount}
                  <span> - </span>
                  {pageSize * currPage < totalCount ? pageSize * currPage : totalCount}
                  <span> of </span>
                  {totalCount}
                  <span> results </span>
                </h5>
              </div>
              <div className="col-12 col-lg-6 sort">
                <div className="sort">
                  <span>Sort By:</span>
                  <span>
                    <select
                      name="sort"
                      id="sort"
                      className="form-select"
                      value={sortBy}
                      onChange={(event) => SetSortBy(event.target.value)}
                    >
                      <option value="">Default</option>
                      <option value="Price">By price</option>
                      <option value="Pet">By Pet Name</option>
                      <option value="Breed">By Breed Name</option>
                    </select>
                  </span>
                </div>

                {/* <ul className="page-header icons">
                    <li><a href="#"><i className="fa-solid fa-list"></i></a></li>
                    <li><a href="#"><i className="fa-solid fa-border-all"></i></a></li>
                  </ul> */}
              </div>
            </div>

            {(AllPetsLoading || filterLoading || isAllPetsFetching) && <LoaderAnimation />}

            {!!totalCount ? (
              <MyPagination
                totPages={Math.ceil(totalCount / pageSize)}
                currentPage={currPage}
                pageClicked={(page_number: number) => {
                  // afterPageClicked(ele);
                  setCurrPage(page_number);
                }}
              >
                <div className="product-sec">
                  <div className="row">
                    {!isAllPetsFetching &&
                      AllPets?.data.petListViewModel.map((petItem, index) => {
                        return (
                          <div className="col-12 col-lg-4" key={index}>
                            <NewPet PetObj={petItem} />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </MyPagination>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PetsListing;
