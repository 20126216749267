import axios from 'axios'
import { API_URL } from './PetServices';

export const Newsletter_URL = `${API_URL}/api/Newsletter`

export const Subscribe_Newsletter_URL = `${API_URL}/api/Newsletter/Subscribe`

export function newsletter(model: any) {
    console.warn("----------")
    var jsondata={
      "email":model
    }
    console.warn(JSON.stringify(jsondata))
    console.warn("----------")
    return axios.post(Subscribe_Newsletter_URL, jsondata)
  }