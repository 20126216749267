import React, { useState } from 'react'
import PropTypes from 'prop-types'
import new_parrot from "../../Assets/Images/new-parrot.jpg";
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faHeartCircleBolt, faLocation, faLocationDot, faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { PetObjTYpe } from '../../types/PetObjType';
import { Button, Dropdown } from 'react-bootstrap';
import { API_URL } from '../../Service/PetServices';
import SocailShare from './socailShare';
import { useAuth } from '../../Contexts/AuthContext';
import { onMaterialHeartClick } from '../../types/HeartIconClickFuncs';
import { MaterialType } from '../../types/MaterialType';
import { GetMaterialByName } from '../../Service/MaterialServices';

type PropsType = {
    MaterialObj: MaterialType
    // onClick(MaterialObj: PetObjTYpe, userId: string, ) : void
}
const MaterialCard = (props: PropsType) => {

    const { UserToken } = useAuth()


    var MaterialObj = props.MaterialObj;

    var encodedPetName = encodeURI(MaterialObj.name);

    // console.log('on click in MaterialCard : ', props.onClick);



    const navigate = useNavigate()


    const [isFavorite, setIsFavorite] = useState(MaterialObj?.isFavorite)




    const [materialObjState, setMaterialObjState] = useState(MaterialObj)


    const RefetchItemData = () => {
        GetMaterialByName(materialObjState.name, UserToken.userId, UserToken.token).then(res => {
            setMaterialObjState(res.data)
        }
        )

    }


    return (
        <div className="card">
            <div className="card-header">
                <Link to={`/material-details/${materialObjState.name}`}>
                    <img className="card-img-top" src={`${API_URL}/${materialObjState.files[0]}`} alt="Card image cap" />
                </Link>


                <div className="icons">


                    {!!UserToken.email &&

                        <button className='heart-btn'
                        disabled={isFavorite !== materialObjState.isFavorite}

                        onClick={() => {
                            setIsFavorite(!isFavorite)
                            onMaterialHeartClick(materialObjState, UserToken.userId, RefetchItemData, UserToken.token)
                            
                        }}>
                            <FontAwesomeIcon icon={faHeart}

                                className={`${isFavorite ?  'icon-active' : 'text-muted'}`}
                            />
                        </button>
                    }
                    <SocailShare uri={`${API_URL}/material-details/${encodedPetName}`} />


                </div>
            </div>
            <div className="card-body">
                <h2>{materialObjState.name}</h2>
                <h3>Rs. {materialObjState.price}</h3>
            </div>
            <div className="card-footer">
                <button onClick={() => navigate(`/material-details/${materialObjState.name}`)}>View</button>
            </div>
        </div>

    )
}



export default MaterialCard