import React, { Fragment, useEffect, useState, FormEvent, MouseEventHandler, Suspense, lazy, useRef } from "react";
import FormikControl from "../Components/ReusableFormikComponents/FormikControl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import new_parrot from "../Assets/Images/new-parrot.jpg";
import img1 from "../Assets/Images/img__1.jpg";
import img2 from "../Assets/Images/img__2.jpg";

import stat1 from "../Assets/Images/stat1.png";
import stat2 from "../Assets/Images/stat2.png";
import stat3 from "../Assets/Images/stat3.png";
import cat from "../Assets/Images/cat.png";
import dog from "../Assets/Images/dog.png";
import hen from "../Assets/Images/hen.png";
import parrot from "../Assets/Images/parrot.png";
import livestock from "../Assets/Images/livestock.png";
import horse from "../Assets/Images/horse.png";
import birds from "../Assets/Images/birds.png";
import ducks from "../Assets/Images/ducks.png";
import fish from "../Assets/Images/fish.png";
import sheeps from "../Assets/Images/sheeps.png";
import PetFood from "../Assets/Images/PetFood.jpg";
import Accessories from "../Assets/Images/PetAccessories.jpg";
import PetServices from "../Assets/Images/PetServices.jpg";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "./arrows";
import { DragDealer } from "./dragDealer";
import { Form as BSForm, Button, Modal, Spinner } from "react-bootstrap";
import NewPet from "../Components/widgets/PetCard";
import FeaturedPetsCarousol from "../Components/widgets/FeaturedPetsCarousol";
import { PetObjTYpe } from "../types/PetObjType";
import { API_URL, GetFeaturedPets, GetNewPets, GetTopCategories } from "../Service/PetServices";
// import EnquiryForm from "../Components/EnquiryForm";
import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import PetCard from "../Components/widgets/PetCard";
import FeaturedPetsComponent from "../Components/widgets/FeaturedPetsComponent";
import { useAuth } from "../Contexts/AuthContext";
import PetBreedAndBreedSelects from "../Components/widgets/TypeAndBreedSelectComponent/PetTypeAndBreedSelects";
import CitySelectComponent from "../Components/widgets/CitySelectComponent";

import { FadeLoader } from "react-spinners";
import { ThemeColor } from "../types/DefaultThemeColor";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import LeftArrowMy from "./LeftArrowMy";
import PetSection from "./PetSection";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;
const elemPrefix = "test";
const getId = (index: number) => `${elemPrefix}${index}`;

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 10,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 8,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3
  }
}


const getItems = () =>
  Array(20)
    .fill(0)
    .map((_, ind) => ({ id: getId(ind) }));

const LandingPage = () => {

  const [showAdvanced, setShowAdvanced] = useState(true);

  const handleAdvancedClick = () => {
    setShowAdvanced((prev) => !prev)
  }

  const [items] = React.useState(getItems);

  // NOTE: for drag by mouse
  const dragState = React.useRef(new DragDealer());

  const handleDrag =
    ({ scrollContainer }: scrollVisibilityApiType) =>
      (ev: React.MouseEvent) =>
        dragState.current.dragMove(ev, (posDiff: number) => {
          if (scrollContainer.current) {
            scrollContainer.current.scrollLeft += posDiff;
          }
        });

  const { UserToken } = useAuth();

  const navigate = useNavigate();

  // const div1Ref = useRef<HTMLDivElement>(null)

  // useEffect(() => {
  //     const observer = new IntersectionObserver((entries, observer) => {
  //         const entry = entries[0];
  //         // console.log('entry', entry);
  //         console.log('entry.isIntersecting', entry.isIntersecting);

  //         if (!!entry.isIntersecting) {
  //             console.log('Fetch data ** ');
  //             setLoadNewPets(true)

  //         }
  //     });

  //     observer.observe(div1Ref.current!);

  // }, []);

  const [petType, setPetType] = useState("");

  const [petBreedId, setpetBreedId] = useState(0);

  const [city, SetCity] = useState("");

  const [search, Setsearch] = useState("")

  const [selectedOption, setselectedOption] = useState(null);

  const handleChange = (selectedOption: any) => {
    setselectedOption(selectedOption);
    SetCity(selectedOption.value);
  };

  const onPetTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {


    // console.log("event.target : ", event.target.selectedOptions[0].text);



    if (event.target.value !== "select") {


      setPetType(event.target.value)
    } else {
      setPetType("")

    };
  };

  const onPetBreedChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value !== "select") {
      setpetBreedId(parseInt(event.target.value));
    } else setpetBreedId(0);
  };

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    refetchNewPets();
  }, [location]);

  const {
    isLoading: NewPetsLoading,
    data: NewPets,
    isError: isNewPetsError,
    error: NewPetsError,
    refetch: refetchNewPets,
    isRefetching: isRefetchingNewPets,
  } = useQuery(
    "NewPets",
    () => {
      return GetNewPets(UserToken.userId, UserToken.token);
    },
    {
      // enabled: !!UserToken.UserDetailsId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 30000,
      // refetchInterval :
      // retry: false,
      onSuccess: (res) => {
        // console.log('Success of AllPets :  ', res);
      },
      onError: (err) => {
        // let Obj /* : ErrorResponseModel */ = err;
      },
    }
  );

  const homeTitleStyle = {
    fontSize: '5vw',
  };


  const {
    isLoading: TopCategoriesLoading,
    data: TopCategories,
    isError: isTopCategoriesError,
    isFetching: isTopCategoriesFetching,
    error: TopCategoriesError,
    refetch: refetchTopCategories,
    isRefetching: isRefetchingTopCategories,
  } = useQuery(
    "TopCategories",
    () => {


      return GetTopCategories(UserToken.token);
    },
    {
      // enabled: !!UserToken.UserDetailsId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 30000,
      // refetchInterval :
      // retry: false,
      onSuccess: (res) => {

        console.log("Success of GetTop Categories : in Landing  res : ", res.data);

      },
      onError: (err) => {
        // let Obj /* : ErrorResponseModel */ = err;
      },
    }
  );

  return (
    <>
      {/* <!-- Banner Sec --> */}

      <div className="banner">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8 ">{ <h1 className="home-title">Pakistan's Trusted Online Animal Marketplace</h1>}</div>
          </div>
        </div>
      </div>

      <div className="container" style={{ marginBottom: "-76px" }}>
        <div className="search-bar">
          <div className="row d-flex flex-wrap">

            <div className="col-12 col-md-4 col-lg-4 mb-2">
              <BSForm.Control type="text" value={search} onChange={(event) => { Setsearch(event.target.value) }} placeholder="Search here anything..." />

            </div>
            {/* {showAdvanced && ( */}

            <div className="col-12 col-md-3 col-lg-3 mb-2 mb-md-0 ">
              <CitySelectComponent
                showLabel={false}
                placeholder="Search City"
                showCityErrorMsg={false}
                selectedOption={selectedOption!}
                handleChange={handleChange}
                selectControlClassName={`${showAdvanced && 'd-none'} d-md-inline d-xl-inline d-lg-inline`}
              />
            </div>

            <PetBreedAndBreedSelects
              petType={petType}
              petTypeChange={onPetTypeChange}
              petBreedId={petBreedId}
              petBreedChange={onPetBreedChange}
              className=""
              petTypeClassName={`col-12 col-md-3 col-lg-3 ${showAdvanced && 'd-none'} d-md-inline d-xl-inline d-lg-inline`}
              petBreedClassName="col-12 col-md-3 col-lg-3"
            />
            {/* // )} */}

            <div className="col-12 col-md-2 col-lg-2 text-md-start text-sm-end text-end">
              <span className="mx-2">
                <a className="advance d-lg-none d-md-none" href="#"
                  onClick={handleAdvancedClick}
                >
                  Advance
                </a>
              </span>
              <button
                type="button"
                className="rounded"
                onClick={() => navigate(`/pet-search?city=${city}&petType=${petType}&petBreedId=${petBreedId}`)}
              >
                SEARCH
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Banner Sec --> */}

      {/* <!-- Categories Sec --> */}

      {/* <ScrollMenu LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            onWheel={onWheel}
            onMouseDown={() => dragState.current.dragStart}
            onMouseUp={() => dragState.current.dragStop}
            onMouseMove={handleDrag}>
        <div className="bg-danger m-5" style={{height : '400px', width : '300px'}}>Cat</div>
        <div className="bg-danger m-5" style={{height : '400px', width : '300px'}}>Cat</div>
        <div className="bg-danger m-5" style={{height : '400px', width : '300px'}}>Cat</div>
        <div className="bg-danger m-5" style={{height : '400px', width : '300px'}}>Cat</div>
        <div className="bg-danger m-5" style={{height : '400px', width : '300px'}}>Cat</div>
      </ScrollMenu> */}

      <div className="categories">
        <div className="container">
          <div className="top-categories mb-5">
            <div className="line"></div>
            <p className="category-text">Browse our Top Categories</p>
            <div className="line"></div>
          </div>

          {TopCategoriesLoading &&
            <div className="d-flex justify-content-center"><FadeLoader color={ThemeColor} /></div>}

          <Carousel responsive={responsive} removeArrowOnDeviceType={["mobile"]} >


            {TopCategories?.data!.length! > 1 &&
              TopCategories?.data.map((category) => {

                if (!category.imageURL.includes(API_URL!)) {
                  category.imageURL = category.imageURL.replace('.', API_URL!)
                }


                return (
                  <div className="text-center" key={category.petTypeId}>
                    <Link to={`/pet-search?petType=${category.petType}-Child`} className="cat-text">
                      <img src={category.imageURL} />
                      <h3>{category.petType}</h3>
                    </Link>
                  </div>
                )
              })
            }





          </Carousel>

        </div>
      </div>

      {/* <!-- End Categories Sec --> */}

      {/* <!-- New pet sec --> */}

      <div
        className="container"
      // ref={div1Ref}
      >
        <div className="newpet-sec">
          <h1 className="newly-added-pet"> NEWLY ADDED PET</h1>
          <p>With ove 10,0000 active listing, We have great largest inventory</p>

          <div className="card-sec">
            <div className="row">
              <div className="d-flex justify-content-center">{NewPetsLoading && <FadeLoader color={ThemeColor} />}</div>

              {NewPets?.data!.map((petItem, index) => (
                <div className="col-12 col-md-4 col-lg-3" key={index}>
                  <PetCard PetObj={petItem} isFromHomePage={true} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* <!-- End new pet section --> */}


      {/* <div className="container">
        <div className="card-sec">
          <div className="row mb-3">
            <div className="col-6">
              <h4 className="Home-Category" style={{fontWeight: 'bold'}}>Livestock</h4>
            </div>
            <div className="col-6 text-end">
              <Link to="" className="view-all-button ">
                View All
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="d-flex justify-content-center">{NewPetsLoading && <FadeLoader color={ThemeColor} />}</div>

            {NewPets?.data!
              .slice(0, 4)
              .map((petItem, index) => (
                <div className="col-12 col-md-4 col-lg-3" key={index}>
                  <PetCard PetObj={petItem} isFromHomePage={true} />
                </div>
              ))}
          </div>
        </div>
      </div> */}


      {/* <div className="container">
        <div className="card-sec">
          <div className="row mb-3">
            <div className="col-6">
              <h4 className="Home-Category" style={{fontWeight: 'bold'}}>Dogs</h4>
            </div>
            <div className="col-6 text-end">
              <Link to="" className="view-all-button ">
                View All
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="d-flex justify-content-center">{NewPetsLoading && <FadeLoader color={ThemeColor} />}</div>

            {NewPets?.data!.filter((petItem) => petItem.petTypeName.toLowerCase() === "dog")
              .slice(0, 4)
              .map((petItem, index) => (
                <div className="col-12 col-md-4 col-lg-3" key={index}>
                  <PetCard PetObj={petItem} isFromHomePage={true} />
                </div>
              ))}
          </div>
        </div>
      </div> */}


      {/* <div className="container">
        <div className="card-sec">
          <div className="row mb-3">
            <div className="col-6">
              <h4 className="Home-Category" style={{fontWeight: 'bold'}}>Hen</h4>
            </div>
            <div className="col-6 text-end">
              <Link to="" className="view-all-button ">
                View All
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="d-flex justify-content-center">{NewPetsLoading && <FadeLoader color={ThemeColor} />}</div>

            {NewPets?.data!.filter((petItem) => petItem.petTypeName.toLowerCase() === "hen")
              .slice(0, 4)
              .map((petItem, index) => (
                <div className="col-12 col-md-4 col-lg-3" key={index}>
                  <PetCard PetObj={petItem} isFromHomePage={true} />
                </div>
              ))}
          </div>
        </div>
      </div>   */}


      {/* <PetSection petType={["dog", "hen"]}  title="Livestock" /> */}

      <PetSection petType="LiveStocks-Parent" title="LiveStocks" />
      <PetSection petType="Dog-Child" title="Dogs" />
      <PetSection petType="Hen-Child" title="Hen" />
      {/* <PetSection petType="Birds-Parent" title="Birds" /> */}


      {/* <!-- Stats Sec --> */}
      {/* <div className="stats">
        <div className="container">
          <div className="row">
            <div className="col-6 col-lg-3">
              <img src={stat1} />
              <h3>385K</h3>
              <p>Post Submitted</p>
            </div>
            <div className="col-6 col-lg-3">
              <img src={stat2} />
              <h3>12</h3>
              <p>Professional Agents</p>
            </div>
            <div className="col-6 col-lg-3">
              <img src={stat1} />
              <h3>235K</h3>
              <p>Sucess Stories</p>
            </div>
            <div className="col-6 col-lg-3">
              <img src={stat3} />
              <h3>496K</h3>
              <p>Happy Customers</p>
            </div>
          </div>
        </div>
      </div> */}

      {/* <!-- End Stat Section --> */}

      {/* <!-- Collage section --> */}
      <div className="collage-sec">
        <h1>BUY PET FOOD AND ACCESORIES</h1>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="img-full">
                <img src={PetFood} />
                <h3>PET FOOD</h3>
              </div>
            </div>
            {/* <div className="col-12 col-md-4 multi-img">
              <div className="img-half">
                <img src={new_parrot} />
                <h3>PET ACCESORIES</h3>
              </div>
              <div className="img-half">
                <img src={new_parrot} />
                <h3>PET CLINIC</h3>
              </div>
            </div> */}
            <div className="col-12 col-md-4">
              <div className="img-full">
                <img src={Accessories} />
                <h3>PET ACCESORIES</h3>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="img-full">
                <img src={PetServices} />
                <h3>PETS SERVICES</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Colage Section --> */}

      {/* <!-- Featured Section --> */}
      {/* <FeaturedPetsComponent /> */}
    </>
  );
};

export default LandingPage;

