import React from 'react'
import { BeatLoader, MoonLoader, ScaleLoader } from 'react-spinners'
import { ThemeColor } from '../../types/DefaultThemeColor'

const LoaderAnimation2 = () => {
    return (
        <div
            className='fp-container'
            style={{ zIndex: "10" }}
        >

            <ScaleLoader
                className='fp-loaderCenter'
                color={ThemeColor} />
        </div>
    )
}

export default LoaderAnimation2