import React, { useState, useEffect } from 'react'
import new_parrot from "../Assets/Images/new-parrot.jpg";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Form as BSForm, Spinner, Button, Card, FormLabel, FormSelect } from 'react-bootstrap-v5'
import FormikControl from '../Components/ReusableFormikComponents/FormikControl'
import axios from 'axios'
import { changePassword, login, register, resetPassword } from '../Service/AuthServices';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext';

const ResetPassword = () => {

    const { UserToken } = useAuth()

    const navigate = useNavigate()



    useEffect(() => {

        window.scrollTo(0, 0);

    }, [])

    //    console.log('url : ',url);
    const initialValues = {
        email: '',
        password: '',
        confirmPassword: ''
    }


    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Required').email(),
        password: Yup.string().min(6, 'Minimum 6 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('password is required'),
        confirmPassword: Yup.string()
            .required('password confirmation is required')
            .when('password', {
                is: (val) => (val && val.length > 0 ? true : false),
                then: () => Yup.string().oneOf([Yup.ref('password')], "password and Confirm password didn't match"),
            })



    })




    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState('')

    let query = new URLSearchParams(useLocation().search);


    const Token = query.get("token")



    onsubmit = (values) => {


        setHasErrors('');

        window.scrollTo(0, 0);
        // values.CreatedBy = UserToken.LoggedInUserId;




        setLoading(true)

        console.log('values***--- ', values);

        resetPassword(encodeURIComponent(Token), values).then(
            (response) => {


                setLoading(false)


                navigate('/login')




                // toast.success('User created successfully', { position: toast.POSITION.TOP_RIGHT });

                // history.push('/users/AllUsers');

            })
            .catch((error) => {
                setLoading(false)

                let Obj = error.toJSON();
                console.log('1111111');
                console.log('Obj', Obj);
                if (Obj.message === 'Network Error') {

                    setHasErrors('API Server is down....');
                }
                else {
                    let obj2 = JSON.parse(Obj.message);

                    setHasErrors(obj2.errorMessage);
                    // toast.error(obj2.errorMessage, { position: toast.POSITION.TOP_RIGHT });

                }

            })


    }








    return (
        <div className="container">
            <div className="breadcrums mt-2">
                <ul>
                    <li><Link to={'/home'} className='mx-2'>Home </Link></li>
                    <li><Link to={''} className='mx-2'> Reset Password </Link></li>

                </ul>
            </div>
            <div className="reg">
                <div className="row">
                    <div className="col col-12 col-lg-6">
                        <img src={
                            new_parrot
                        } />
                    </div>
                    <div className="col col-12 col-lg-6 ">

                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onsubmit}
                        >
                            {
                                formik => {
                                    return (
                                        <Form className='reg-form form-large'>


                                            <h2>Reset Your Password</h2>

                                            {hasErrors ? (
                                                <div className='mb-lg-15 alert alert-danger'>
                                                    <div className='alert-text font-weight-bold'>{hasErrors}</div>
                                                </div>
                                            ) : ''}


                                            <FormikControl
                                                control='input'
                                                type='text'
                                                placeholder='Enter Email'
                                                name='email'
                                                className='form-item  large'
                                            />



                                            <FormikControl
                                                control='input'
                                                type='password'
                                                placeholder='Enter New Password'
                                                name='password'
                                                className='form-item  large'
                                            />



                                            <FormikControl
                                                control='input'
                                                type='password'
                                                placeholder='Confirm New Password'
                                                name='confirmPassword'
                                                className='form-item  large'
                                            />



                                            <button type="submit">
                                                <div className='d-flex justify-content-center'>

                                                    SUBMIT
                                                    {loading && (
                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                                            <span className='spinner-border spinner-border-sm align-middle m-1 mb-2'></span>
                                                        </span>
                                                    )}
                                                </div>
                                            </button>







                                        </Form>
                                    )
                                }}

                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword