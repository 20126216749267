
import axios from 'axios'
import { API_URL } from './PetServices';
import { StoreType } from '../types/StoreType';



export const Store_URL = `${API_URL}/api/Store`






export function GetUserStores(userId : string, accessToken: string) {
    return axios.get<StoreType[]>(`${Store_URL}/GetUserStores?UserId=${userId}`,{
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });
  }
  
  export function GetStoreById(id: number, accessToken: string) {
  
    return axios.get<StoreType>(`${Store_URL}/${id}`,{
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });
  }
  
  export function PostStore(model: StoreType, accessToken: string) {
    console.log('model values in PostLinen -- ', model);
    return axios.post(`${Store_URL}`,
      model,{
        headers: {
          'Authorization': 'Bearer ' + accessToken
        }
      }
    );
  }
  
  export function UpdateStore(id: number, model : StoreType, accessToken: string) {
    model.id = id;
    return axios.put(`${Store_URL}/${id}`, model ,{
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });
  }
  
  export function DeleteStore(id: number, accessToken: string) {
    return axios.delete(`${Store_URL}/${id}`,{
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });
  }
  