import React, { useState, useEffect } from "react";
import new_parrot from "../Assets/Images/new-parrot.jpg";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Form as BSForm, Spinner, Button, Card, FormLabel, FormSelect } from "react-bootstrap-v5";
import FormikControl from "../Components/ReusableFormikComponents/FormikControl";
import axios from "axios";
import { GetUserById, UpdateUser, register } from "../Service/AuthServices";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CitySelectComponent from "../Components/widgets/CitySelectComponent";
import { useAuth } from "../Contexts/AuthContext";
import LoaderAnimation from "../Components/widgets/LoaderAnimation";
import LoaderAnimation3 from "../Components/widgets/LoaderAnimation3";

const Register = () => {
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const [returnUri, setReturnUri] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!!location.search) setReturnUri(location.search);
  }, [location.search]);

  const initialValues = {
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
  };

  //------------------------------------------------
  const { id } = useParams();

  const isRegisterMode = !id;

  console.log("id form Params", id);

  const [loading2, setLoading2] = useState(false);

  const [userObj, setUser] = useState(null);
  //------------------------------------------------
  //------------------------------------------------

  const [showCityErrorMsg, setshowCityErrorMsg] = useState(false);

  const [selectedOption, setselectedOption] = useState(null);

  const handleChange = (selectedOption) => {
    if (showCityErrorMsg) {
      setshowCityErrorMsg(false);
    }

    setselectedOption(selectedOption);
    // SetCity(selectedOption.value)
  };

  //------------------------------------------------
  //------------------------------------------------
  const { UserToken, setUserToken } = useAuth();

  useEffect(() => {
    (async () => {
      if (id) {
        setLoading2(true);

        await GetUserById(id, UserToken.token).then((user) => {
          user.data.password = "";

          setselectedOption({ label: user.data.city, value: user.data.city });

          setUser(user.data);
          setLoading2(false);
        });
      } else {
        setUser(initialValues);
      }
    })();
  }, []);

  let validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Required"),
    email: Yup.string().required("Required").email(),
    // phoneNumber: Yup.string().required('Required').matches('^[923]{3}[0-9+]{9}$', "phoneNumber should start with 92 and should be of length 12"),
    phoneNumber: Yup.string()
      .required("Required")
      .matches("^03[0-9]{9}$", "phoneNumber should start with 03 and should be of length 11"),
  });

  if (isRegisterMode) {
    validationSchema = Yup.object().shape({
      password: Yup.string().min(6, "Minimum 6 symbols").max(50, "Maximum 50 symbols").required("password is required"),
      confirmPassword: Yup.string()
        .required("password confirmation is required")
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: () => Yup.string().oneOf([Yup.ref("password")], "password and Confirm password didn't match"),
        }),
      fullName: Yup.string().required("Required"),
      email: Yup.string().required("Required").email(),
      // phoneNumber: Yup.string().required('Required').matches('^[923]{3}[0-9+]{9}$', "phoneNumber should start with 92 and should be of length 12"),
      phoneNumber: Yup.string()
        .required("Required")
        .matches("^03[0-9]{9}$", "Phone number should start with 03 and be followed by 9 digits"),
    });
  }

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState("");

  onsubmit = (values) => {
    if (selectedOption == null) {
      setshowCityErrorMsg(true);
      window.scrollTo(0, 0);
    } else {
      values.City = selectedOption.value;
      setHasErrors("");
      setLoading(true);

      if (isRegisterMode) {
        setHasErrors("");
        setLoading(true);

        register(values)
          .then(() => {
            setLoading(false);

            if (!!returnUri) {
              console.log("returnUri : ", returnUri);

              navigate(`/login${returnUri}`);
            } else {
              navigate("/login");
            }

            // toast.success('User created successfully', { position: toast.POSITION.TOP_RIGHT });

            // history.push('/users/AllUsers');
          })
          .catch((error) => {
            setLoading(false);

            let Obj = error.toJSON();
            console.log("1111111");
            console.log("Obj", Obj);
            if (Obj.message === "Network Error") {
              setHasErrors("API Server is down....");
            } else {
              console.log("Obj.message : ", Obj.message);
              console.log("type of Obj.message , ", typeof Obj.message);
              let obj2 = JSON.parse(Obj.message);

              setHasErrors(obj2.errorMessage);

              // toast.error(obj2.errorMessage, { position: toast.POSITION.TOP_RIGHT });
            }
          });
      } else {
        console.log("vaules : ", values);

        values.roles = [];

        UpdateUser(id, values, UserToken.token)
          .then(() => {
            setLoading(false);

            // toast.success('User updated successfully', { position: toast.POSITION.TOP_RIGHT });

            navigate("/dashboard");

            setUserToken(
              UserToken.token,
              UserToken.expiry,
              values.fullName,
              values.email,
              values.phoneNumber,
              UserToken.userId,
              selectedOption.value,
              UserToken.createdOn
            );
          })
          .catch((error) => {
            setLoading(false);

            let Obj = error.toJSON();
            console.log("1111111");
            console.log("Obj", Obj);
            if (Obj.message === "Network Error") {
              setHasErrors("API Server is down....");
            } else {
              let obj2 = JSON.parse(Obj.message);

              setHasErrors(obj2.errorMessage);

              // toast.error(obj2.errorMessage, { position: toast.POSITION.TOP_RIGHT });
            }
          });
      }
    }
  };

  return (
    <div className="container">
      <div className="breadcrums mt-2">
        <ul>
          <li>
            <Link to={"/home"} className="mx-2">
              Home{" "}
            </Link>
          </li>
          <li>
            <Link to={""} className="mx-2">
              {" "}
              {isRegisterMode ? "Register" : "Update Profile"}{" "}
            </Link>
          </li>
        </ul>
      </div>
      <div className="reg d-flex justify-content-center">
        {/* <div className="row">
                    <div className="col col-12 col-lg-6">
                        <img src={
                            new_parrot

                        } />
                    </div> */}
        <div className="col col-12 col-lg-5 ">
          {(loading || loading2) && <LoaderAnimation3 />}

          {userObj !== null && (
            <Formik initialValues={userObj} validationSchema={validationSchema} onSubmit={onsubmit}>
              {(formik) => {
                return (
                  <Form className="reg-form form-large">
                    <h2>{isRegisterMode ? "Register" : "Update Profile"}</h2>

                    {hasErrors ? (
                      <div className="mb-lg-15 alert alert-danger">
                        <div className="alert-text font-weight-bold">{hasErrors}</div>
                      </div>
                    ) : (
                      ""
                    )}

                    <FormikControl
                      control="input"
                      type="text"
                      placeholder="Name"
                      name="fullName"
                      className="form-item"
                    />

                    <FormikControl
                      control="input"
                      type="text"
                      placeholder="Email"
                      name="email"
                      className="form-item  large"
                    />

                    <FormikControl
                      control="input"
                      type="text"
                      placeholder="PhoneNumber 03xxxxxxxxx"
                      name="phoneNumber"
                      className="form-item  large"
                    />

                    {isRegisterMode && (
                      <>
                        <FormikControl
                          control="input"
                          type="password"
                          placeholder="Password"
                          name="password"
                          className="form-item  large"
                        />

                        <FormikControl
                          control="input"
                          type="password"
                          placeholder="Confirm password"
                          name="confirmPassword"
                          className="form-item large"
                        />
                      </>
                    )}

                    <CitySelectComponent
                      showLabel={false}
                      placeholder="Select Your City..."
                      showCityErrorMsg={false}
                      selectedOption={selectedOption}
                      handleChange={handleChange}
                      selectControlClassName="mb-3"
                    />

                    {/* {isRegisterMode &&

                                                    <div className="form-check">
                                                        <input type="checkbox" id="updates" name="uadates" value="updates" />
                                                        <label htmlFor="t1">Send me updates and relevent news</label>
                                                    </div>
                                                } */}

                    <button type="submit">
                      <div className="d-flex justify-content-center">
                        {isRegisterMode ? "CREATE ACCOUNT" : "UPDATE PROFILE"}

                        {loading && (
                          <span className="indicator-progress" style={{ display: "block" }}>
                            <span className="spinner-border spinner-border-sm align-middle m-1 mb-2"></span>
                          </span>
                        )}
                      </div>
                    </button>
                    {isRegisterMode && (
                      <>
                        <p>By clicking the button above, you are agreeing to our Terms of Use</p>
                        <p>
                          Already have an account?{" "}
                          <span>
                            <Link to={"/login"} className="">
                              Login
                            </Link>
                          </span>
                        </p>
                      </>
                    )}
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};

export default Register;
