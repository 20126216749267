import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faDog, faHeart, faLocationDot, faPaw } from '@fortawesome/free-solid-svg-icons';
import { Badge, Button } from 'react-bootstrap';
import { API_URL, GetPetDetailsByName } from '../../Service/PetServices';
import SocailShare from './socailShare';
import { useAuth } from '../../Contexts/AuthContext';
import { onPetHeartClick } from '../../types/HeartIconClickFuncs';
import { formatDistanceToNow } from 'date-fns';
import { PetObjTYpe } from '../../types/PetObjType';

type PropsType = {
    PetObj: PetObjTYpe;
    isFromHomePage?: boolean;
};

const PetCard = (props: PropsType) => {
    const { UserToken } = useAuth();
    const navigate = useNavigate();

    // console.log('props : ', props.PetObj.petTypeId);
    // console.log(JSON.stringify(props.PetObj, null, 2));
    

    const [isFavorite, setIsFavorite] = useState(props.PetObj?.isFavorite);
    const [petObjState, setPetObjState] = useState(props.PetObj);

    const RefetchItemData = () => {
        GetPetDetailsByName(petObjState.nickName, UserToken.userId, UserToken.token).then(res => {
            setPetObjState(res.data);
        });
    };

    const timeago = formatDistanceToNow(new Date(petObjState.updatedOn), { addSuffix: true });

    const handleHeartClick = () => {
        if (UserToken.email) {
            setIsFavorite(!isFavorite);
            onPetHeartClick(petObjState, UserToken.userId, RefetchItemData, UserToken.token);
        } else {
            navigate('/register');
        }
    };

    return (
        <div className="card">
            <div className="card-header border-0 d-flex justify-content-between">
                {petObjState.isPriceOnCall ? (
                    <Badge className="bg-secondary px-3 py-2 mt-1 mb-1">Price On Call</Badge>
                ) : (
                    <h3>Rs. {petObjState.price.toLocaleString()}</h3>
                )}
                <div className="icons">
                    <div className="ShareDropDown">
                        <Button className="btn-success" onClick={handleHeartClick}>
                            <FontAwesomeIcon
                                icon={faHeart}
                                className={`${isFavorite ? 'icon-active' : 'text-muted'} pointer-cursor`}
                            />
                        </Button>
                    </div>
                    <SocailShare uri={`${API_URL}/pet-details/${encodeURIComponent(petObjState.nickName)}`} />
                </div>
            </div>
            <Link className='linkkk' to={`/pet-details/${encodeURIComponent(petObjState.nickName)}`}>
                <img
                    className={`card-img-top rounded-0 ${props.isFromHomePage ? '' : 'listing-page-card'}`}
                    src={`${API_URL}/${petObjState.files[0]}`}
                    alt="Card image cap"
                />
           
            <div className="card-body">
                <h2>{petObjState.nickName}</h2>
                <FontAwesomeIcon className="mx-1" color="#00C194" icon={faLocationDot} />
                <span>{petObjState.city}</span>
            </div>
            <div className="card-footer">
                <li>
                    <FontAwesomeIcon icon={faDog} color="#808080" className="mr-1" />
                    <p>{petObjState.petTypeName}</p>
                </li>
                <li>
                    <FontAwesomeIcon icon={faClock} className="mr-2" color="#808080" />
                    <p>{timeago}</p>
                </li>
                {/* <li>
                    <h2>Breed:</h2>
                    <p>{petObjState.petBreedName}</p>
                </li>
                <li>
                    <h2>Gender:</h2>
                    <p>{petObjState.gender}</p>
                </li> */}
            </div>
            </Link>
        </div>
    );
};

export default PetCard;
