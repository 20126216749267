import { API_URL, GetBreedsByPetId, GetPetAges, GetPetById, GetPetColors, GetPetTypes, Pet_URL, PostPet, UpdatePet } from '../Service/PetServices'

import { useState, useEffect } from 'react'
import Dropzone, { useDropzone } from 'react-dropzone'
import 'react-dropzone/examples/theme.css'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Form as BSForm, Spinner, Button, Card, FormLabel, FormSelect } from 'react-bootstrap-v5'
import FormikControl from '../Components/ReusableFormikComponents/FormikControl'
import axios from 'axios'
// import { useHistory } from 'react-router'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../Contexts/AuthContext'

import Select from 'react-select'
import CitySelectComponent from '../Components/widgets/CitySelectComponent'
import DashboardHeader from '../Components/widgets/DashboardHeader'
import LoaderAnimation2 from '../Components/widgets/LoaderAnimation2'
import { ScrollToFieldError } from '../Components/ReusableFormikComponents/ScrollToErrorField'
import ImageGalleryDropZone from '../Components/widgets/ImageGalleryDropZone'
import { FormCheck } from 'react-bootstrap'


const AddPet = () => {


    const initialValues = {
        Id: 0,
        NickName: '',
        Price: 0,
        Gender: '',
        PetColorId: null,
        PetAgeId: '',

        Group: '',
        TrainingLevel: '',
        EnergyLevel: '',
        GroomingLevel: '',
        Details: '',
        Files: [],
        VideoURL: '',
        PetFor: '',
        PetTypeId: 0,
        PetBreedId: 0,
        TempramentFeatures: [],
        CompatibilityFeatures: [],
        Phone: ''


    }

    const [formValues, setFormValues] = useState(null)




    //------------------------------------------------
    //------------------------------------------------
    //   const [citiesOptions, setCitiesOptions] = useState([])
    const [selectedCityOption, setselectedCityOption] = useState(null)
    const [showCityErrorMsg, setshowCityErrorMsg] = useState(false)

    const handleChange = (selectedCityOption) => {

        if (showCityErrorMsg) {
            setshowCityErrorMsg(false);
        }

        setselectedCityOption(selectedCityOption);
    }


    const [selectedFileNames, setSelectedFileNames] = useState([])








    const [fileURLs, setFileURLs] = useState([])




    const TrainingLevelOptions = [
        { key: 'Select Training Level', value: '' },
        { key: 'Has Basic Training', value: 'BasicTraining' },
        { key: 'Well Trained', value: 'WellTrained' },
    ]

    const PetForOptions = [
        { key: 'Select PetFor', value: '' },
        { key: 'Engage', value: 'Engage' },
        { key: 'Sell', value: 'Sell' },
        { key: 'Both', value: 'Both' }
    ]

    const GroupOptions = [
        { key: 'Select Group', value: '' },
        { key: 'Baby', value: 'Baby' },
        { key: 'Young', value: 'Young' },
        { key: 'Adult', value: 'Adult' },
        { key: 'Senior', value: 'Senior' }
    ]

    const GenderOptions = [
        { key: 'Select Gender', value: '' },
        { key: 'Male', value: 'Male' },
        { key: 'Female', value: 'Female' },
        { key: 'Pair', value: 'Pair' }
    ]

    const EnergyLevelOptions = [
        { key: 'Select Energy Level', value: '' },
        { key: 'Low', value: 'Low' },
        { key: 'Moderate', value: 'Moderate' },
        { key: 'High', value: 'High' }
    ]


    const GroomingLevelOptions = [
        { key: 'Select Grooming Level', value: '' },
        { key: 'Low', value: 'Low' },
        { key: 'Moderate', value: 'Moderate' },
        { key: 'High', value: 'High' },
        { key: 'Not Required', value: 'Not Required' }

    ]


    // const ColorOptions = [
    //     { key: 'Select Color', value: '' },
    //     { key: 'Black', value: 'Black' },
    //     { key: 'Brown', value: 'Brown' },
    //     { key: 'Grey', value: 'Grey' },
    //     { key: 'White', value: 'White' },
    //     { key: 'Yellow', value: 'Yellow' },
    //     { key: 'Green', value: 'Green' },
    //     { key: 'Red', value: 'Red' },
    //     { key: 'Other', value: 'Other' }
    // ]

    const [ageOptions, setAgeOptions] = useState([{ key: 'Select Age', value: '' }])
    const [colorOptions, setColorOptions] = useState([{ key: 'Select Color', value: '' }])






    const { id } = useParams();

    console.log('Id : in AddPet ', id);

    const { UserToken } = useAuth()

    const isAddMode = !id;

    console.log('isAddMode in AddPet  ', isAddMode);

    // console.log('isAddMode :  ',isAddMode);
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [hasErrors, setHasErrors] = useState('')


    const [photos, setPhotos] = useState([])

    const setPhotosArray = (values) => {

        setPhotos(values)
    }



    useEffect(() => {
        (async () => {

            if (!UserToken.email)
                navigate(`/login?returnUri=/add-product`)


            window.scrollTo(0, 0);


            if (!isAddMode) {
                setLoading2(true);

                await GetPetById(id, UserToken.token).then((res => {
                    console.log('response data of Pet by id ----', res.data);


                    initialValues.Id = res.data.id;
                    initialValues.NickName = res.data.nickName;
                    initialValues.Price = res.data.price;
                    initialValues.Gender = res.data.gender;

                    if (!!res.data.petColorId)
                        initialValues.PetColorId = res.data.petColorId;
                    if (!!res.data.petAgeId)
                        initialValues.PetAgeId = res.data.petAgeId;
                    // initialValues.Group = res.data.group;
                    // initialValues.TrainingLevel = res.data.trainingLevel;
                    // initialValues.EnergyLevel = res.data.energyLevel;
                    // initialValues.GroomingLevel = res.data.groomingLevel;
                    initialValues.Details = res.data.details;
                    // initialValues.TempramentFeatures = res.data.tempramentFeatures;
                    // initialValues.CompatibilityFeatures = res.data.compatibilityFeatures;
                    initialValues.VideoURL = res.data.videoURL;
                    initialValues.Phone = res.data.phone;


                    initialValues.PetFor = res.data.petFor;




                    setselectedCityOption({ label: res.data.city, value: res.data.city })



                    res.data.files.map(file => {
                        setPhotos(prevState => [...prevState, { src: `${file}`, width: 4, height: 4 }])
                    })


                    setpetTypeId(res.data.petTypeId);
                    setpetBreedId(res.data.petBreedId)



                    setpetTypeName(res.data.petTypeName);

                    setPetBreedName(res.data.petBreedName);

                    GetPetBreedsById(res.data.petTypeId);





                    SetSpecialFeatures(res.data.specialFeatures);

                    setFormValues(initialValues);

                    setLoading2(false);

                }))
            } else {
                setFormValues(initialValues);
            }

        })()
    }, []
    )

    // const history = useHistory();

    const validationSchema = Yup.object().shape({

        NickName: Yup.string().required('Required'),
        Phone: Yup.string().required('Required').matches('^[923]{3}[0-9+]{9}$', "phoneNumber should start with 92 and should be of length 12"),
        Price: Yup.string().required('Required'),
        Gender: Yup.string().required('Required'),
        // PetColorId: Yup.string().required('Required'),
        PetAgeId: Yup.string().required('Required'),
        // Group: Yup.string().required('Required'),
        // TrainingLevel: Yup.string().required('Required'),
        // EnergyLevel: Yup.string().required('Required'),
        // GroomingLevel: Yup.string().required('Required'),
        Details: Yup.string().required('Required'),
        // PetTypeId: Yup.string().required('Required'),
        PetBreedId: Yup.string().required('Required'),
        // PetFor: Yup.string().required('Required'),
        // TempramentFeatures: Yup.array().min(1, 'Required'),
        // CompatibilityFeatures: Yup.array().min(1, 'Required'),
        // City: Yup.string().required('Required'),
        // Phone: Yup.string().required('Required')

    })


    const [petTypesOptions, setPetTypesOptions] = useState([{ key: 'Select Type', value: '' }])
    const [petBreedsOptions, setPetBreedsOptions] = useState([{ key: 'Select Breed', value: '' }])

    const [tempramentFeaturesOptions, setTempramentFeaturesOptions] = useState([])
    const [compatibilityFeaturesOptions, setCompatibilityFeaturesOptions] = useState([])

    const [specialFeatures, SetSpecialFeatures] = useState([])


    const featuresOptions = [
        { key: 'Yes', value: 'true' },
        { key: 'No', value: 'false' },
    ]



    useEffect(() => {

        (async () => {

            await GetPetTypes(UserToken.token)
                .then(res => {
                    res.data.map(petType => {
                        setPetTypesOptions(oldValues => [...oldValues.filter(a => a.key !== petType.name), { key: petType.name, value: petType.id }])
                    })
                })
                .catch(err => {
                    console.log('err from GetPet Types ', err);
                })

            await GetPetColors()
                .then(res => {
                    res.data.map(color => {
                        setColorOptions(oldValues =>
                            [...oldValues.filter(a => a.key !== color.name), { key: color.name, value: color.id }])
                    })
                })
                .catch(err => {
                    console.log('err from GetPet colors ', err);
                })

            await GetPetAges()
                .then(res => {
                    res.data.map(age => {
                        setAgeOptions(oldValues =>
                            [...oldValues, { key: age.name, value: age.id }])
                    })
                })
                .catch(err => {
                    console.log('err from GetPet Age ', err);
                })




            // await axios.get(`${API_URL}/api/Pet/CreatePet`)
            //     .then(res => {

            //         console.log('res.data == ', res.data);

            //         res.data.compatibilityFeatures.map((comptFeature, index) => {

            //             setCompatibilityFeaturesOptions(oldValues => [...oldValues, { key: comptFeature, value: comptFeature }]);
            //         })

            //         res.data.tempramentFeatures.map((temptFeature, index) => {
            //             setTempramentFeaturesOptions(oldValues => [...oldValues, { key: temptFeature, value: temptFeature }]);
            //         })

            //         if (isAddMode) {
            //             SetSpecialFeatures(res.data.specialFeatures);
            //         }

            //     }).catch(err => { })


        })()


    }, [])


    const handleFeatureCheck = (featureObj) => {

        var changedFeature = specialFeatures.find(a => a.item1 === featureObj.item1);
        changedFeature.item2 = !changedFeature.item2;

        SetSpecialFeatures([...specialFeatures]);



    }

    const [petTypeId, setpetTypeId] = useState(0)
    const [petBreedId, setpetBreedId] = useState(0)

    const [showpetTypeErrorMsg, setShowpetTypeErrorMsg] = useState(false)

    const [showpetBreedErrorMsg, setShowpetBreedErrorMsg] = useState(false)

    const [petTypeName, setpetTypeName] = useState('')

    const [petBreedName, setPetBreedName] = useState('')



    const handlePetChange = (event) => {


        var SelectedItemId = petTypesOptions.find(a => a.key == event.target.value).value;

        setpetTypeName(event.target.value);

        setpetTypeId(SelectedItemId);

        GetPetBreedsById(SelectedItemId);

        console.log('event.target.value== ', SelectedItemId);

        if (SelectedItemId !== '') {
            setShowpetTypeErrorMsg(false);
            // if (petBreedName !== '') {
            //     setShowpetBreedErrorMsg(false);
            // }

        } else {
            setpetTypeId(0);
            // // setpetBreedId(0);
            // setShowpetBreedErrorMsg(true);

        }

    }


    const handleBreedChange = (e) => {

        var SelectedItemId = petBreedsOptions.find(a => a.key === e.target.value).value;

        setPetBreedName(e.target.value);

        setpetBreedId(SelectedItemId);

        if (SelectedItemId !== '') {
            // setShowpetBreedErrorMsg(false);
        } else {
            setpetBreedId(0);
        }


    }



    const GetPetBreedsById = async (SelectedItemId) => {



        setPetBreedsOptions([{ key: 'Select Breed', value: '' }]);

        if (SelectedItemId !== '') {

            await GetBreedsByPetId(SelectedItemId, UserToken.token)
                .then(res => {
                    res.data.map(breed => {
                        // if(petBreedsOptions.find(a => a.key == breed.name) == undefined)
                        setPetBreedsOptions(oldValues => [...oldValues.filter(a => a.key !== breed.name), { key: breed.name, value: breed.id }])
                    })
                })
        }


    }


    const navigate = useNavigate()

    const [isPriceOnCall, setIsPriceOnCall] = useState(false)



    onsubmit = (values) => {


        // if (selectedUserOption == null) {
        //     setshowUsersErrorMsg(true);
        // }
        if (selectedCityOption == null) {
            setshowCityErrorMsg(true);
        }

        if (petTypeId == 0) {
            setShowpetTypeErrorMsg(true);
        }
        // if (petBreedId == 0) {
        //     setShowpetBreedErrorMsg(true);
        // }

        if (selectedCityOption !== null && petTypeId !== 0 /* && petBreedId !== 0 */) {


            values.City = selectedCityOption.value;


            values.OwnerId = UserToken.userId;
            values.IsPriceOnCall = isPriceOnCall;
            values.PetTypeId = petTypeId;
            values.PetBreedId = petBreedId;


            let fileURLs = photos.map(photo => photo.src);
            values.Files = fileURLs;

            values.SpecialFeatures = specialFeatures;

            console.log('values == ', values);


            if (isAddMode) {
                setLoading(true)

                setTimeout(() => {

                    console.log('values--- ', values);




                    PostPet(values, UserToken.token).then(
                        () => {
                            setLoading(false)
                            //toast.success('Item created successfully', { position: //toast.POSITION.TOP_RIGHT });

                            navigate('/dashboard')

                            // history.push('/Pets/AllPets');

                        })
                        .catch((error) => {
                            setLoading(false)

                            let Obj = error.toJSON();
                            console.log('1111111');
                            console.log('Obj', Obj);
                            if (Obj.message === 'Network Error') {

                                var msg = 'API Server is down....';
                                setHasErrors(msg);
                                //toast.error(msg, { position: //toast.POSITION.BOTTOM_RIGHT });

                            }
                            else {
                                let obj2 = JSON.parse(Obj.message);
                                console.log('obj2 : ', obj2);

                                setHasErrors(obj2.ErrorMessage);
                                //toast.error(obj2.ErrorMessage, { position: //toast.POSITION.BOTTOM_RIGHT });

                            }

                        })
                }, 1000)

            }
            else {
                setLoading(true)

                setTimeout(() => {


                    UpdatePet(id, values, UserToken.token).then(
                        () => {
                            // setHasErrors(false)
                            setLoading(false)

                            navigate('/dashboard')
                            //toast.success('Item updated successfully', { position: //toast.POSITION.TOP_RIGHT });

                            // history.push('/Pets/AllPets');

                        })
                        .catch((error) => {
                            setLoading(false)

                            let Obj = error.toJSON();
                            console.log('1111111');
                            console.log('Obj', Obj);
                            if (Obj.message === 'Network Error') {

                                setHasErrors('API Server is down....');
                            }
                            else {
                                let obj2 = JSON.parse(Obj.message);

                                setHasErrors(obj2.errorMessage);
                                //toast.error(obj2.errorMessage, { position: //toast.POSITION.BOTTOM_RIGHT });

                            }
                        })
                }, 1000)


            }

        } else {
            window.scrollTo(0, 0);
        }


    }


    return (
        <div className='container'>

            <div className="breadcrums mt-2">
                <ul>
                    <li><Link to={'/home'} className='mx-2'>Home </Link></li>
                    <li><Link to={'/dashboard'} className='mx-2'> Dashboard </Link></li>
                    <li><Link to={''} className='mx-2'> {isAddMode ? "Add" : "Edit"} Material </Link></li>
                </ul>
            </div>


            <DashboardHeader />
            {(loading || loading2) && <LoaderAnimation2 />}

            {formValues !== null ?
                <Formik
                    initialValues={formValues}
                    validationSchema={validationSchema}
                    onSubmit={onsubmit}
                >
                    {
                        formik => {
                            return (

                                <Form
                                    className="add-product-form"
                                >
                                    <ScrollToFieldError />
                                    <h3 className="section-heading">
                                        {!isAddMode ? "Update Information About Your pet"
                                            : "Add Information About Your pet"
                                        }
                                    </h3>



                                    {hasErrors ? (
                                        <div className='mb-lg-15 alert alert-danger'>
                                            <div className='alert-text font-weight-bold'>{hasErrors}</div>
                                        </div>
                                    ) : ''}


                                    <div className="product-info d-flex flex-wrap">

                                        <div className=' width-class'>
                                            <FormikControl
                                                control='input'
                                                type='text'
                                                label='NickName'
                                                name='NickName'
                                                className='form-item'

                                            />
                                        </div>
                                        {/* <FormikControl
                                            control='select'
                                            options={PetForOptions}
                                            type='text'
                                            label='PetFor'
                                            name='PetFor'
                                            className='form-item width-class'
                                        /> */}

                                        <div className='d-flex width-class'>

                                            <FormikControl
                                                control='input'
                                                type='number'
                                                label='Price'
                                                disabled={isPriceOnCall}
                                                name='Price'
                                                className='form-item'
                                            />

                                            <FormCheck
                                                onClick={() => {
                                                    formValues.Price = 0;
                                                    setFormValues(formValues)
                                                    setIsPriceOnCall(!isPriceOnCall);
                                                }}
                                                checked={isPriceOnCall}
                                                id='negotiable-checkbox'
                                                label="Is Price On Call?"
                                                style={{ font: 'message-box', fontWeight: 'bold', marginLeft: '40px', width: '300px' }}
                                                className='mt-4'
                                            />

                                        </div>

                                        <div className='width-class'>

                                            <h6 className={`${showpetTypeErrorMsg ? 'text-danger' : ''}`} htmlFor={'PetTypeId'}>
                                                {'Select Pet Type'}
                                            </h6>

                                            <BSForm.Select
                                                className='form-item'
                                                name='PetTypeId'
                                                onChange={handlePetChange}
                                                value={petTypeName}
                                            >
                                                {petTypesOptions.map((type) =>
                                                    <option
                                                        key={type.value}
                                                    >
                                                        {type.key}
                                                    </option>
                                                )}
                                            </BSForm.Select>
                                            {showpetTypeErrorMsg ? (
                                                <div className='p-1 fs-3 text-danger'>{'Required'}</div>
                                            ) : ''}

                                        </div>

                                        <div className='form-item width-class'>

                                            <h6 className={`${showpetBreedErrorMsg ? 'text-danger' : ''}`} htmlFor={'PetBreedId'}>{'Select Pet Breed'}</h6>

                                            <BSForm.Select
                                                className=''
                                                name='PetBreedId'
                                                onChange={handleBreedChange}
                                                value={petBreedName}
                                            >
                                                {petBreedsOptions.map((type) =>
                                                    <option
                                                        key={type.value}
                                                    >
                                                        {type.key}
                                                    </option>
                                                )}
                                            </BSForm.Select>
                                            {/* {showpetBreedErrorMsg ? (
                                                <div className='p-1 fs-3 text-danger'>{'Required'}</div>
                                            ) : ''} */}

                                        </div>


                                        <FormikControl
                                            control='select'
                                            options={GenderOptions}
                                            label='Select Gender'
                                            name='Gender'
                                            className='form-item width-class'

                                        />


                                        {/* <FormikControl
                                            control='select'
                                            options={colorOptions}
                                            type='text'
                                            label='Select Color'
                                            name='PetColorId'
                                            className='form-item width-class'

                                        /> */}

                                        <FormikControl
                                            control='select'
                                            options={ageOptions}
                                            type='text'
                                            label='Select Age'
                                            name='PetAgeId'
                                            className='form-item width-class'

                                        />


                                        {/* <FormikControl
                                            control='select'
                                            options={GroupOptions}
                                            type='text'
                                            label='Select Group'
                                            name='Group'
                                            className='form-item width-class'

                                        /> */}
                                        {/* <FormikControl
                                            control='select'
                                            options={TrainingLevelOptions}
                                            type='text'
                                            label='Select Training Level'
                                            name='TrainingLevel'
                                            className='form-item width-class'

                                        /> */}



                                        {/* <FormikControl
                                            control='select'
                                            options={EnergyLevelOptions}
                                            type='text'
                                            label='Select Energy Level'
                                            name='EnergyLevel'
                                            className='form-item width-class'

                                        /> */}
                                        {/* <FormikControl
                                            control='select'
                                            options={GroomingLevelOptions}
                                            type='text'
                                            label='Select Grooming Level'
                                            name='GroomingLevel'
                                            className='form-item width-class'

                                        /> */}


                                    </div>


                                    {/* <h3 className="section-heading"> Pet Features</h3> */}

                                    {/* <FormikControl
                                        control='checkbox'
                                        options={tempramentFeaturesOptions}
                                        label='Temprament'
                                        name='TempramentFeatures'
                                        formGroupClassName=' pet-features'
                                        className='form-check'
                                    /> */}

                                    {/* <FormikControl
                                        control='checkbox'
                                        options={compatibilityFeaturesOptions}
                                        label='Compatibility'
                                        name='CompatibilityFeatures'
                                        formGroupClassName='pet-features'
                                        className='form-check'
                                    /> */}






                                    {/* special features */}

                                    {/* <h3 className="section-heading">Special Pet Features</h3> */}

                                    {/* <div className=" row pet-features">
                                        {
                                            specialFeatures.map((featureObj, index) => {

                                                var CheckedOption = featuresOptions.find(a => a.value == (featureObj.item2).toString());


                                                return (
                                                    <div className='col-12 col-lg-2' key={index}>

                                                        <h4> {featureObj.item1}</h4>

                                                        <div className='d-flex'>
                                                            {featuresOptions.map((option, index) => (

                                                                <div className="form-check radio" key={index}
                                                                    style={{ marginRight: "-15px", marginBottom: "15px" }}
                                                                >
                                                                    <input
                                                                        className=""
                                                                        type="radio"
                                                                        name={featureObj.item1}
                                                                        onClick={() => handleFeatureCheck(featureObj)}
                                                                        id={featureObj.item1}
                                                                        defaultChecked={CheckedOption.value == 'true' && option.key == 'Yes' || CheckedOption.value == 'false' && option.key == 'No'} value={option.value}
                                                                    />

                                                                    <label className="form-check-label" htmlFor="inlineRadio1">{option.key}</label>
                                                                </div>
                                                            )
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div> */}

                                    {/* Details input */}
                                    {/* <div className='m-5'> */}
                                    <h4>More About Your Pet</h4>
                                    <FormikControl
                                        control='textarea'
                                        type='text'
                                        rows={4}
                                        // label='More About Your Pet'
                                        name='Details'
                                    // className='w-100'
                                    />

                                    {/* </div>

                                        <div className='m-5'> */}

                                    {/* <FormikControl
                                            control='input'
                                            type='text'
                                            label='Video URL'
                                            name='VideoURL'
                                            placeholder='Paste Video URL'
                                            inputClassName='text-muted'

                                        /> */}
                                    {/* </div> */}

                                    {/* File selector */}

                                    <ImageGalleryDropZone photosArray={photos} setPhotosArray={setPhotosArray} />





                                    {/* Owner Details */}

                                    <div className="owner-detail ">
                                        <h3>Pet Owner Details</h3>
                                        <div className="owner-info">
                                            <FormikControl
                                                control='input'
                                                type='text'
                                                label='Phone'
                                                name='Phone'
                                                className='form-item width-class input-1'
                                            />

                                            <div>
                                                <CitySelectComponent

                                                    showLabel={true}

                                                    showCityErrorMsg={showCityErrorMsg}
                                                    selectedOption={selectedCityOption}
                                                    handleChange={handleChange}
                                                    selectControlClassName='form-item width-class form-control city-select'
                                                />

                                            </div>
                                        </div>
                                    </div>


                                    {/* ------------ */}


                                    {/* Submit Btn */}
                                    <div className='d-flex '>

                                        <Button variant="success" className="submit-button" type='submit'>
                                            <div className='d-flex justify-content-center'>


                                                {/* {!isAddMode ? <span>Update </span> : <span> Create</span>} */}
                                                SUBMIT


                                                {loading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </div>
                                        </Button>
                                    </div>





                                </Form>

                            )
                        }
                    }
                </Formik>
                : ""}

        </div>
    )
}

export default AddPet