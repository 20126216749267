import React from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik"; // Import Formik components
import * as Yup from "yup"; // Import Yup for form validation
import { contactUs } from "../Service/AuthServices";

function ContactUs() {
  const initialValues = {
    name: "",
    email: "",
    subject: "",
    message: "",
    phoneNumber : "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
    phoneNumber: Yup.string().required("Phone number is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log(values);
    try{
      const response = await contactUs(values)
      console.log(response, "Email sent successfully");
    }
    catch (error){
      console.error("Error sending emial", error);
    }
    finally{
      setSubmitting(false);
    }
  };

  return (
    <div className="container">
      {/* <div className="breadcrumbs mt-2">
        <ul>
          <li>
            <Link to="/home" className="mx-2">
              Home
            </Link>
          </li>
          <li>
            <Link to="" className="mx-2">
              Contact us
            </Link>
          </li>
        </ul>
      </div> */}

      <div className="can d-flex justify-content-center">
      <div className="col col-12 col-lg-5 ">

        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form className="can-form form-large">
              <div className="text-center mb-4"> {/* Center aligning the Contact Us and paragraph */}
                <h2>Contact Us</h2>
                <p>Use the form below to get in touch. Our office hours are Monday through Friday, 9 am to 5 pm Eastern, and we attempt to respond to support requests within 1 business day.</p>
              </div>
              <div className="row">
                <div className="col-md-12 offset-md-0"> {/* Adjusting the form field width */}
                  <div className="form-group mb-1">
                    <label htmlFor="name" className="mb-1">Name</label>
                    <Field type="text" id="name" name="name" className="form-control" />
                    <ErrorMessage name="name" component="div" className="text-danger" />
                  </div>
                  <div className="form-group mb-1">
                    <label htmlFor="email" className="mb-1">Email</label>
                    <Field type="email" id="email" name="email" className="form-control" />
                    <ErrorMessage name="email" component="div" className="text-danger" />
                  </div>
                  <div className="form-group mb-1">
                    <label htmlFor="subject" className="mb-1">Subject</label>
                    <Field type="text" id="subject" name="subject" className="form-control" />
                    <ErrorMessage name="subject" component="div" className="text-danger" />
                  </div>
                  <div className="form-group mb-1">
                    <label htmlFor="phone" className="mb-1">Phone Number</label>
                    <Field type="tel" id="phoneNumber" name="phoneNumber" className="form-control" />
                    <ErrorMessage name="phoneNumber" component="div" className="text-danger" />
                  </div>
                  <div className="form-group mb-1">
                    <label htmlFor="message" className="mb-1">Message</label>
                    <Field as="textarea" id="message" name="message" className="form-control" />
                    <ErrorMessage name="message" component="div" className="text-danger" />
                  </div>
                  
                  <button type="submit"  disabled={isSubmitting}>
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      </div>
    </div>
  );
}

export default ContactUs;
