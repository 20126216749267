import React from 'react'

type PropsType = {
    messageError: boolean    
    message: string
    messageChangeEvent : (event: React.ChangeEvent<HTMLTextAreaElement>) => void
    loading: boolean
    onMessageSubmit: () => void



}

const SendMessageForm = (props: PropsType) => {

    const { message, messageError, messageChangeEvent, loading, onMessageSubmit } = props;

    return (
        <div className="contact-form">

            <form className="form-inline">

                <div className="mb-3 message">
                    <textarea
                        rows={5}
                        // placeholder="Message*"
                        className={`form-control ${messageError && "border-danger"}`}
                        // id="InputMessage"
                        // aria-describedby="message"
                        value={message}
                        onChange={(event) => {
                            messageChangeEvent(event)                            
                        }}
                    />

                    {messageError ? (
                        <div className='p-1  text-danger'>{'Required'}</div>
                    ) : ''}

                </div>

                <button className="btn-green " type="button" onClick={onMessageSubmit}>

                    <div className='d-flex justify-content-center'>
                        {/* SIGN IN */}
                        SEND MESSAGE
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                <span className='spinner-border spinner-border-sm align-middle mx-1 '></span>
                            </span>
                        )}
                    </div>


                </button>

            </form>

        </div>
    )
}

export default SendMessageForm