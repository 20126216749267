
import axios from 'axios'
import { API_URL } from './PetServices';
import { MaterialType } from '../types/MaterialType';
import { MaterialListingType } from '../types/MaterialsListingType';



export const Material_URL = `${API_URL}/api/Material`



export function GetFilteredMaterials(
  City: string,
  SearchTerm: string,
  SortBy: string,
  parentType: string,
  childType: string,
  PriceRange: number,
  MinPriceRange: number,
  MaxPriceRange: number,
  pageNumber: number,
  pageSize: number,
  UserId: string,
  accessToken: string
) {
  return axios.get<MaterialListingType>
    (`${Material_URL}/GetFilteredMaterials?City=${City}&SearchTerm=${SearchTerm}&SortBy=${SortBy}&PetType=${childType}&ParentPetType=${parentType}&PriceRange=${PriceRange}&MinPriceRange=${MinPriceRange}&MaxPriceRange=${MaxPriceRange}&pageNumber=${pageNumber}&pageSize=${pageSize}&UserId=${UserId}`
      ,
      {
        headers: {
          'Authorization': 'Bearer ' + accessToken
        }
      }
    );
}




export function GetAllMaterialsByUser(storeOwnerId: string,
  accessToken: string) {
  return axios.get<MaterialListingType>(`${Material_URL}/GetFilteredMaterials?storeOwnerId=${storeOwnerId}`,
    {
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });
}



export function GetMaterialsByStoreId(storeId: number,
  accessToken : string) {
  return axios.get<MaterialType[]>(`${Material_URL}/GetMaterialsByStore?StoreId=${storeId}`,
    {
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });
}


export function GetMaterialById(id: number,
  accessToken : string) {

  return axios.get<MaterialType>(`${Material_URL}/${id}`,
    {
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });
}

export function GetMaterialByName(name: string, UserId: string,
  accessToken : string) {

  return axios.get<MaterialType>(`${Material_URL}/MaterialByName/${name}?UserId=${UserId}`,
    {
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });
}




export function PostMaterial(model: MaterialType,
  accessToken : string) {
  console.log('model values in PostLinen -- ', model);
  return axios.post(`${Material_URL}`,
    model
    ,
    {
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }
  );
}

export function UpdateMaterial(id: number, model: MaterialType,
  accessToken : string) {
  model.id = id;
  return axios.put(`${Material_URL}/${id}`, model,
    {
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });
}

export function DeleteMaterial(id: number,
  accessToken : string) {
  return axios.delete(`${Material_URL}/${id}`,
    {
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });
}

export const MaterialBrand_URL = `${API_URL}/api/MaterialBrand`





export function GetMaterialBrands(accessToken : string) {
  return axios.get(`${MaterialBrand_URL}`,
    {
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });
}


export function GetUserFavoriteMaterials(userId: string,
  accessToken : string) {
  return axios.get<MaterialType[]>(`${Material_URL}/GetUserFavoriteMaterials?UserId=${userId}`
    ,
    {
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });
}

export function AddMaterialToFavorite(petId: number, loggedInUserId: string, accessToken: string) {

  return axios.post(`${Material_URL}/AddMaterialToFavorite?id=${petId}&UserId=${loggedInUserId}`,
    {
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }
  );
}


export function RemoveMaterialFromFavorite(petId: number, loggedInUserId: string, accessToken: string) {

  return axios.post(`${Material_URL}/RemoveMaterialFromFavorite?id=${petId}&UserId=${loggedInUserId}`,
    {
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }
  );
}
