import React, { useState } from 'react'
import { arrayMoveImmutable, arrayMoveMutable } from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Gallery from "react-photo-gallery";
import axios from 'axios'
import Dropzone, { useDropzone } from 'react-dropzone'
import 'react-dropzone/examples/theme.css'
import { API_URL, Pet_URL } from '../../Service/PetServices';


const SortablePhoto = SortableElement((props) =>
    <div className='m-2  border border-3  hover_img ehover1' >
        {/* <div>{JSON.stringify(props)}</div> */}


        <div>
            <img className='' height={200} width={200} src={`${API_URL}/${props.photo.src}`} />
        </div>

        <div className="overlay">

            <div className="info">
                {/* showing preview in 2 sec
        <KTSVG path='/media/icons/duotune/files/fil024.svg' className='svg-icon-1' /> */}

                <div className=' d-flex'>


                    {props.defaultImage ? <span className="bi bi-shield-lock-fill fa-2x text-light"></span> : <span></span>}

                    <button className="bi bi-x-circle fa-2x bg- border border-0"
                        style={{ 'backgroundColor': '#00000000', color: '#ffffff' }}
                        type='button'
                        onClick={() => props.onClick(props.photo.src)}
                    ></button>
                </div>

            </div>


        </div>

    </div>

);

const SortableGallery = SortableContainer((props_) => {

    const { items, onRemoveButtonClick } = props_



    return (

        <Gallery photos={items} renderImage={props =>
            <SortablePhoto
                {...props}
                onClick={onRemoveButtonClick}
                defaultImage={props_.items[0].src == props.photo.src}

            />} />

        // <Gallery photosArray={items} renderImage={props => console.log('props projection :::  ', JSON.stringify(props))} />
    )
});

const ImageGalleryDropZone = ({ photosArray, setPhotosArray }) => {


    const [loading, setLoading] = useState(false)

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setPhotosArray(arrayMoveImmutable(photosArray, oldIndex, newIndex));
    };



    const onDrop = (files) => {

        console.log('files  debuging== ', files);

        setLoading(true)

        if (files.length > 0) {


            const formData = new FormData()

            files
                .map(
                    file => {
                        if (!photosArray.includes({ src: `StaticFiles/Images/${file.name}`, width: 4, height: 4 })) {
                            formData.append(`filesArray`, file)

                        } else {
                            // toast.warning(`${file.name} already exist In Selected Files`, { position: toast.POSITION.BOTTOM_RIGHT });
                        }
                    })

            if (formData.get("filesArray") !== null) {


                axios.post(`${Pet_URL}/PostFiles`, formData, { headers: { 'content-type': 'multipart/form-data' } })
                    .then(res => {
                        res.data.map(url => {

                            setLoading(false)
                            setPhotosArray(prevState => [...prevState, { src: `${url}`, width: 4, height: 4 }])

                            // setFileURLs(prevState => [...prevState, url])
                        })
                    })
                    .catch(err => {
                        // toast.error(`Error from Posting Files`, { position: toast.POSITION.BOTTOM_RIGHT });

                        console.log('Error from PostFiles response', err)
                        setLoading(false)
                    })
            }

        }
    }

    const removeFile = (file) => {

        console.log('file : ', file);
        let filteredPhotos = photosArray.filter(f => f.src !== file);

        setPhotosArray(filteredPhotos)



    }



    return (
        <Dropzone
            onDrop={onDrop}
            accept={['.jpg', '.tif', '.tiff', '.jpeg', '.jfif', '.pjpeg', '.pjp', '.png', '.svg', '.webp', '.bmp']}
        >
            {({ getRootProps, getInputProps }) => (
                <section className="container">
                    <div {...getRootProps({ className: 'dropzone' })}>
                        {/* <KTSVG path='/media/icons/duotune/files/fil023.svg' className='svg-icon-1' /> */}

                        <input {...getInputProps()} />
                        <p className='h5 p-3 text-muted'>Drag & Drop files here or click to browser</p>

                        {loading &&
                            (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    <span className='spinner-border spinner-border-sm align-middle ms-2 p-4'>
                                    </span>
                                </span>
                            )
                        }

                    </div>
                    <aside>
                        {/* <h4 className='p-5'>{files.length >= 1 && <span> Selected Files</span>} </h4> */}

                        <div className='d-flex flex-wrap'>
                            {photosArray.length > 0 &&
                                <SortableGallery onRemoveButtonClick={removeFile} items={photosArray} onSortEnd={onSortEnd} axis={"xy"} />
                            }
                        </div>
                    </aside>
                </section>
            )}
        </Dropzone>

    )
}

export default ImageGalleryDropZone