import React from 'react'
import { useAuth } from '../../Contexts/AuthContext'
import avatar from "../../Assets/Images/avatar.png";
import user from "../../Assets/Images/user.png";
import { Link } from 'react-router-dom';

const DashboardHeader = () => {

    const { UserToken } = useAuth()
    return (
        <div className="member-sec">
            <div className="row">
                <div className="col-12 member-info">
                    <div>
                        <img src={user} />
                    </div>
                    <div>
                        <h2>{UserToken.name}</h2>
                        <p> <span className='text-muted'>Joined On </span> {new Date(UserToken.createdOn).toDateString()}</p>
                        <li> <Link to={`/edit-profile/${UserToken.userId}`}> Edit Profile</Link>
                            <span>|</span>
                            <Link to={`/change-password`}>Change Password</Link>
                        </li>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardHeader