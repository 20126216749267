import React from "react";
import { Pagination } from 'react-bootstrap-v5';
import { faAngleDoubleLeft, faAngleDoubleRight, faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function MyPagination(props) {
  const [pageArray, setPageArray] = React.useState([]);

  React.useEffect(() => {
    const totPages = parseInt(props.totPages);
    const currentPage = parseInt(props.currentPage);
    const pageArr = [];

    if (totPages > 1) {
      // If total pages are less than or equal to 5, show all page numbers
      if (totPages <= 5) {
        for (let i = 1; i <= totPages; i++) {
          pageArr.push(i);
        }
      } else {
        // If total pages are more than 5
        if (currentPage <= 3) {
          // If current page is less than or equal to 3, show first 5 pages
          for (let i = 1; i <= 5; i++) {
            pageArr.push(i);
          }
          pageArr.push(""); // Add ellipsis
          pageArr.push(totPages); // Add last page
        } else if (currentPage >= totPages - 2) {
          // If current page is greater than or equal to total pages - 2, show last 5 pages
          pageArr.push(1); // Add first page
          pageArr.push(""); // Add ellipsis
          for (let i = totPages - 4; i <= totPages; i++) {
            pageArr.push(i);
          }
        } else {
          // Otherwise, show current page and pages before and after current page
          pageArr.push(1); // Add first page
          pageArr.push(""); // Add ellipsis
          for (let i = currentPage - 2; i <= currentPage + 2; i++) {
            pageArr.push(i);
          }
          pageArr.push(""); // Add ellipsis
          pageArr.push(totPages); // Add last page
        }
      }
    }

    setPageArray(pageArr);
  }, [props.totPages, props.currentPage]);

  return (
    <React.Fragment>
      {props.children}
      {props.totPages !== 1 &&
        <div style={{ marginTop: "15px" }} className='' >
          <Pagination style={{ justifyContent: "center" }} >
            {pageArray.map((ele, ind) => {
              const toReturn = [];

              if (ind === 0) {
                toReturn.push(
                  <Pagination.First
                    key={"firstpage"}
                    onClick={() => props.pageClicked(1)}
                  >
                    <FontAwesomeIcon icon={faAngleDoubleLeft} />
                  </Pagination.First>
                );

                toReturn.push(
                  <Pagination.Prev
                    key={"prevpage"}
                    onClick={() => props.pageClicked(props.currentPage - 1)}
                  >
                    <FontAwesomeIcon icon={faArrowLeftLong} />
                  </Pagination.Prev>
                );
              }

              if (ele === "") {
                toReturn.push(<Pagination.Ellipsis key={ind} />);
              } else {
                toReturn.push(
                  <Pagination.Item
                    key={ind}
                    active={props.currentPage === ele}
                    onClick={() => props.pageClicked(ele)}
                  >
                    {ele}
                  </Pagination.Item>
                );
              }

              if (ind === pageArray.length - 1) {
                toReturn.push(
                  <Pagination.Next
                    key={"nextpage"}
                    onClick={() => props.pageClicked(props.currentPage + 1)}
                  >
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </Pagination.Next>
                );

                toReturn.push(
                  <Pagination.Last
                    key={"lastpage"}
                    onClick={() => props.pageClicked(ele)}
                  >
                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                  </Pagination.Last>
                );
              }

              return toReturn;
            })}
          </Pagination>
        </div>
      }
    </React.Fragment>
  );
}
