import React from 'react'
import { BeatLoader, BounceLoader, MoonLoader, ScaleLoader, SyncLoader } from 'react-spinners'
import { ThemeColor } from '../../types/DefaultThemeColor'

const LoaderAnimation3 = () => {
    return (
        <div
            className='fp-container'
            style={{ zIndex: "10" }}
        >

            <SyncLoader
                 speedMultiplier={1}
                className='fp-loaderCenter'
                color={ThemeColor} />
        </div>
    )
}

export default LoaderAnimation3