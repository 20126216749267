import axios from 'axios'
import { API_URL } from './PetServices'


export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/Account/GetUserByToken`

export const LOGIN_URL = `${API_URL}/api/Account/Login`
export const REGISTER_URL = `${API_URL}/api/Account/SignUp`

export const REGISTERATION_URL = `${API_URL}/api/Account/Register`

export const VERIFY_OTP_URL = `${API_URL}/api/Account/VerifyOTP`

export const Get_OTP_URL = `${API_URL}/api/Account/GetOTPOnPhoneNumber`

export const CONTACTUS_EMAIL_URL = `${API_URL}/api/Account/SendEmail`

export const CHANGE_PASSWORD_URL = `${API_URL}/api/Account/ChangePassword`

export const CONFIRM_EMAIL_URL = `${API_URL}/api/Account/ConfirmEmail`

export const REQUEST_PASSWORD_URL = `${API_URL}/api/Account/forgotPassword`



// Server should return AuthModel
export function login(model: any) {

  console.log('Login URL --- ', LOGIN_URL);

  return axios.post(LOGIN_URL, model)
}

// Server should return AuthModel
export function register(model: any) {

  return axios.post(REGISTER_URL, model)
}

export function registeration(model: any) {

  return axios.post(REGISTERATION_URL, model)
}

export function getOTP(model: any) {

  return axios.post(Get_OTP_URL, model)
}

export function contactUs(model: any) {

  return axios.post(CONTACTUS_EMAIL_URL, model)
}

export function verifyOTP(model: any){
  return axios.post(VERIFY_OTP_URL, model)
}

//-----------------------------------------
export function confirmEmail(model: any) {
  console.log(
    'in confirm Email Function---- at line 34--- AuthCURD---model---',
    model
  );

  return axios.post(CONFIRM_EMAIL_URL, model)
}

// Server should return object => { result: boolean } (Is Email in DB)

export function changePassword(model: any, accessToken : string) {
  return axios.post(CHANGE_PASSWORD_URL, model, {
    headers: {
      'Authorization': 'Bearer ' + accessToken
    }
  }
  )
}


export function requestPassword(email: string) {
  return axios.post(REQUEST_PASSWORD_URL, {Email :  email })
}

export function resetPassword(token: string, model: any) {
  return axios.post(`${API_URL}/api/Account/resetPassword?resetToken=${token}`, model)
}


export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get(GET_USER_BY_ACCESSTOKEN_URL)
}

export const AdministrationManager_URL = `${API_URL}/api/Administration`



export function GetUserById(id: string, accessToken: string) {

  return axios.get(`${AdministrationManager_URL}/EditUserGet/${id}`,
    {
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }
  )
}

export function UpdateUser(id: number, model: any, accessToken: string) {
  return axios.put(`${AdministrationManager_URL}/EditUserPut/${id}`, model,
    {
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }
  );
}

