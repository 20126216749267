import React, { ReactNode } from 'react'
import { Modal, Button } from 'react-bootstrap'

type ModalProps = {
  show: boolean
  handleClose: () => void
  handleOk : () => void
  OkButtonText: string
  showOKButtonSpinner: boolean
  modalBodyText : string
}

const ModalComponent = (props: ModalProps) => {

  return (
    <Modal show={props.show} onHide={props.handleClose} >
      <Modal.Header>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      Are You sure to want to {props.modalBodyText}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Cancel
        </Button>
        <Button variant="danger"  onClick={props.handleOk}>
          <div className='d-flex'>
            {props.OkButtonText}
            {props.showOKButtonSpinner && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </div>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalComponent