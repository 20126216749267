
import axios from 'axios'
import { PetObjTYpe } from '../types/PetObjType';
import { PetDetailsTYpe } from '../types/PetDetailsType';
import { PetAndBreedCategoryType } from '../types/PetAndBreedCategoryType';
import { PetsListingType } from '../types/PetsListingType';
import { FavoriteAndUsersPetType } from '../types/FavoriteAndUsersPetType';
import { TopCategoryType } from '../types/TopCategoryType';

export const API_URL = process.env.REACT_APP_API_URL || 'api'


export const Pet_URL = `${API_URL}/api/Pet`


export function GetNewPets(UserId: string,
  accessToken: string) {
  return axios.get<PetObjTYpe[]>(`${Pet_URL}/NewPets?UserId=${UserId}`, {
    headers: {
      'Authorization': 'Bearer ' + accessToken
    }
  }
  );
}


export function GetParentPetTypes(accessToken: string) {
  return axios.get(`${API_URL}/api/ParentPetType`,
    {
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    });
}

export function GetAllPets(
  City: string,
  SearchTerm: string,
  SortBy: string,
  ParentPetType: string,
  PetType: string,
  PetBreedId: number,
  PriceRange: number,
  MinPriceRange: number,
  MaxPriceRange: number,
  pageNumber: number,
  pageSize: number,
  UserId: string,
  accessToken: string
) {
  console.log('PetType : ', PetType);
  console.log('ParentPetType : ', ParentPetType);
  return axios.get<PetsListingType>
    (`${Pet_URL}?City=${City}&SearchTerm=${SearchTerm}&SortBy=${SortBy}&PetType=${PetType}&ParentPetType=${ParentPetType}&PetBreedId=${PetBreedId}&PriceRange=${PriceRange}&MinPriceRange=${MinPriceRange}&MaxPriceRange=${MaxPriceRange}&pageNumber=${pageNumber}&pageSize=${pageSize}&UserId=${UserId}`
      , {
        headers: {
          'Authorization': 'Bearer ' + accessToken
        }
      }


    );
}

// }?hotelId=${HotelId}&Month=${month}&Year=${year}&pageNumber=${pageNumber}&pageSize=${pageSize}

export function GetFeaturedPets(accessToken: string) {
  return axios.get<PetObjTYpe[]>(`${Pet_URL}/FeaturedPets`, {
    headers: {
      'Authorization': 'Bearer ' + accessToken
    }
  });
}


export function GetRelatedPets(petId: number, petTypeId: number, UserId: string,
  accessToken: string) {
  return axios.get<PetObjTYpe[]>(`${Pet_URL}/RelatedPets/${petTypeId}?UserId=${UserId}&PetId=${petId}`, {
    headers: {
      'Authorization': 'Bearer ' + accessToken
    }
  });
}

export function GetPetsByUser(userId: string,
  accessToken: string) {
  return axios.get<FavoriteAndUsersPetType[]>(`${Pet_URL}/PetsByUser?UserId=${userId}`, {
    headers: {
      'Authorization': 'Bearer ' + accessToken
    }
  });
}
export function GetUsersAds(userId: string, pageNumber: number, pageSize: number,
  accessToken: string) {
  return axios.get(`${Pet_URL}/GetUsersAds?UserId=${userId}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
    headers: {
      'Authorization': 'Bearer ' + accessToken
    }
  });
}


export function GetUserFavoritePets(userId: string,
  accessToken: string) {
  return axios.get<FavoriteAndUsersPetType[]>(`${Pet_URL}/GetUserFavoritePets?UserId=${userId}`, {
    headers: {
      'Authorization': 'Bearer ' + accessToken
    }
  });
}



export function GetPetById(id: number,
  accessToken: string) {

  return axios.get<PetObjTYpe>(`${Pet_URL}/${id}`, {
    headers: {
      'Authorization': 'Bearer ' + accessToken
    }
  });
}

export function GetPetDetailsByName(name: string, UserId: string,
  accessToken: string) {

  return axios.get<PetDetailsTYpe>(`${Pet_URL}/PetDetails/${name}?UserId=${UserId}`, {
    headers: {
      'Authorization': 'Bearer ' + accessToken
    }
  });
}


export function PostPet(model: any, accessToken: string) {
  console.log('model values in PostLinen -- ', model);
  return axios.post(`${Pet_URL}`,
    model, {
    headers: {
      'Authorization': 'Bearer ' + accessToken
    }
  }
  );
}



export function UpdatePet(id: number, model: any, accessToken: string) {
  model.id = id;
  return axios.put(`${Pet_URL}/${id}`, model, {
    headers: {
      'Authorization': 'Bearer ' + accessToken
    }
  });
}

export function DeletePet(id: number, accessToken: string) {
  return axios.delete(`${Pet_URL}/${id}`, {
    headers: {
      'Authorization': 'Bearer ' + accessToken
    }
  });
}




const PetType_URL = `${API_URL}/api/PetType`

export function GetPetTypes(accessToken: string) {
  return axios.get<PetAndBreedCategoryType[]>(`${PetType_URL}`, {
    headers: {
      'Authorization': 'Bearer ' + accessToken
    }
  });
}


const PetBreed_URL = `${API_URL}/api/PetBreed`

export function GetBreedsByPetId(id: any, accessToken: string) {
  return axios.get<PetAndBreedCategoryType[]>(`${PetBreed_URL}/GetBreedsByPetId/${id}`, {
    headers: {
      'Authorization': 'Bearer ' + accessToken
    }
  });
}

export const PetAge_URL = `${API_URL}/api/PetAge`


export function GetPetAges() {
  return axios.get(`${PetAge_URL}`);
}


export const PetColor_URL = `${API_URL}/api/PetColor`

export function GetPetColors() {
  return axios.get(`${PetColor_URL}`);
}

export function GetTopCategories(accessToken : string) {

  return axios.get<TopCategoryType[]>(`${API_URL}/api/TopCategories`,
  {
    headers: {
      'Authorization': 'Bearer ' + accessToken
    }
  }

  );
}







export function AddPetToFavorite(petId: number, loggedInUserId: string, accessToken: string) {

  return axios.post(`${Pet_URL}/AddPetToFavorite?id=${petId}&UserId=${loggedInUserId}`,
    {
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }
  );
}


export function RemovePetFromFavorite(petId: number, loggedInUserId: string, accessToken: string) {

  return axios.post(`${Pet_URL}/RemovePetFromFavorite?id=${petId}&UserId=${loggedInUserId}`,
    {
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }
  );
}
