import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../Contexts/AuthContext';
import { useQuery } from 'react-query';
import { DeletePet, GetFeaturedPets, GetPetsByUser, GetUserFavoritePets, RemovePetFromFavorite } from '../Service/PetServices';
import PetGridCard from '../Components/widgets/PetGridCard';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faEnvelope, faEye, faEyeDropper, faEyeLowVision, faFileLines, faHeart, faPaw, faPencil, faPlus, faReply, faShieldHeart, faStore, faStoreAlt, faStoreSlash, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';
import { GetReceivedMessages, GetSentMessages, PostMessage } from '../Service/MessagesServices';
import { DeleteStore, GetUserStores } from '../Service/StoreServices';
import { DeleteMaterial, GetAllMaterialsByUser, GetMaterialsByStoreId, GetUserFavoriteMaterials, RemoveMaterialFromFavorite } from '../Service/MaterialServices';
import MaterialGridCard from '../Components/widgets/MaterialGridCard';
import { MaterialType } from '../types/MaterialType';
import DashboardHeader from '../Components/widgets/DashboardHeader';
import LoaderAnimation from '../Components/widgets/LoaderAnimation';
import LoaderAnimation2 from '../Components/widgets/LoaderAnimation2';
import ModalComponent from '../Components/widgets/ModalComponent';
import SendMessageForm from '../Components/widgets/SendMessageForm';
import ModalComponent2 from '../Components/widgets/ModalComponent2';
import { ThemeColor } from '../types/DefaultThemeColor';
import { MessageObjType } from '../types/MessagesTypes';

const Dashboard = () => {

    const { UserToken, setUserToken } = useAuth()

    const location = useLocation()




    useEffect(() => {

        window.scrollTo(0, 0);


        refetchUserPets()
        refetchFavoritePets()
        refetchReceivedMessages()
        refetchSentMessages()
        // refetchStores()
        refetchFavoriteMaterials()
        // refetchStoreMaterials()



    }, [location])


    const navigate = useNavigate()

    // console.log('UserToken : ', UserToken);


    const {
        isLoading: FavoritePetsLoading,
        data: FavoritePets,
        isError: isFavoritePetsError,
        error: FavoritePetsError,
        refetch: refetchFavoritePets,
        isRefetching: isRefetchingFavoritePets
    }
        = useQuery('FavoritePets', () => {
            return GetUserFavoritePets(UserToken.userId, UserToken.token)
        },
            {
                // enabled: !!UserToken.UserDetailsId,
                refetchOnMount: false,
                refetchOnWindowFocus: false,
                staleTime: 30000,
                // refetchInterval : 
                // retry: false,
                onSuccess: (res) => {
                    setLoading(false)
                    // console.log('Success of AllPets :  ', res);

                },
                onError: (err) => {
                    setLoading(false)
                    // let Obj /* : ErrorResponseModel */ = err;
                }

            }
        )


    const {
        isLoading: FavoriteMaterialsLoading,
        data: FavoriteMaterials,
        isError: isFavoriteMaterialsError,
        error: FavoriteMaterialsError,
        refetch: refetchFavoriteMaterials,
        isRefetching: isRefetchingFavoriteMaterials
    }
        = useQuery('FavoriteMaterials', () => {
            return GetUserFavoriteMaterials(UserToken.userId, UserToken.token)
        },
            {
                // enabled: !!UserToken.UserDetailsId,
                refetchOnMount: false,
                refetchOnWindowFocus: false,
                staleTime: 30000,
                // refetchInterval : 
                // retry: false,
                onSuccess: (res) => {
                    setLoading(false)
                    // console.log('Success of AllPets :  ', res);

                },
                onError: (err) => {
                    setLoading(false)
                    // let Obj /* : ErrorResponseModel */ = err;
                }

            }
        )


    const {
        isLoading: UserPetsLoading,
        data: UserPets,
        isError: isUserPetsError,
        error: UserPetsError,
        refetch: refetchUserPets,
        isRefetching: isRefetchingUserPets
    }
        = useQuery('PetsByUser', () => {
            return GetPetsByUser(UserToken.userId, UserToken.token)
        },
            {
                // enabled: !!UserToken.UserDetailsId,
                refetchOnMount: false,
                refetchOnWindowFocus: false,
                staleTime: 30000,
                // refetchInterval : 
                // retry: false,
                onSuccess: (res) => {
                    // console.log('Success of AllPets :  ', res);
                    setLoading(false)
                },
                onError: (err) => {
                    setLoading(false)
                    // let Obj /* : ErrorResponseModel */ = err;
                }

            }
        )

    const {
        isLoading: ReceivedMessagesLoading,
        data: ReceivedMessages,
        isError: isReceivedMessagesError,
        error: ReceivedMessagesError,
        refetch: refetchReceivedMessages,
        isRefetching: isRefetchingReceivedMessages
    }
        = useQuery('ReceivedMessages', () => {
            return GetReceivedMessages(UserToken.userId, UserToken.token)
        },
            {
                // enabled: !!UserToken.UserDetailsId,
                refetchOnMount: false,
                refetchOnWindowFocus: false,
                staleTime: 30000,
                // refetchInterval : 
                // retry: false,
                onSuccess: (res) => {
                    // setLoading(false)
                    // console.log('Success of AllPets :  ', res);

                },
                onError: (err) => {
                    // setLoading(false)
                    // let Obj /* : ErrorResponseModel */ = err;
                }

            }
        )


    const {
        isLoading: SentMessagesLoading,
        data: SentMessages,
        isError: isSentMessagesError,
        error: SentMessagesError,
        refetch: refetchSentMessages,
        isRefetching: isRefetchingSentMessages
    }
        = useQuery('SentMessages', () => {
            return GetSentMessages(UserToken.userId, UserToken.token)
        },
            {
                // enabled: !!UserToken.UserDetailsId,
                refetchOnMount: false,
                refetchOnWindowFocus: false,
                staleTime: 30000,
                // refetchInterval : 
                // retry: false,
                onSuccess: (res) => {
                    setLoading(false)
                    // console.log('Success of AllPets :  ', res);

                },
                onError: (err) => {
                    setLoading(false)
                    // let Obj /* : ErrorResponseModel */ = err;
                }

            }
        )


    // const {
    //     isLoading: StoresLoading,
    //     data: Stores,
    //     isError: isStoresError,
    //     error: StoresError,
    //     refetch: refetchStores,
    //     isRefetching: isRefetchingStores
    // }
    //     = useQuery('Stores', () => {
    //         return GetUserStores(UserToken.userId, UserToken.token)
    //     },
    //         {
    //             // enabled: !!UserToken.UserDetailsId,
    //             refetchOnMount: false,
    //             refetchOnWindowFocus: false,
    //             staleTime: 30000,
    //             // refetchInterval : 
    //             // retry: false,
    //             onSuccess: (res) => {
    //                 console.log('Stores Data :  ', res.data);
    //                 // setStoreId(res.data[0].id)

    //                 res.data.map(store => {
    //                     setStoresOptions(oldValues => [...oldValues.filter(a => a.key !== store.name), { key: store.name, value: store.id.toString() }])
    //                 })


    //             },
    //             onError: (err) => {
    //                 // let Obj /* : ErrorResponseModel */ = err;
    //             }

    //         }
    //     )

    // const [storeId, setStoreId] = useState(0)

    // const [storesOptions, setStoresOptions] = useState([{ key: 'Select Store', value: 'select' }])


    // const onStoreChange = (event: React.ChangeEvent<HTMLSelectElement>) => {

    //     console.log('event.target : ', event.target.selectedOptions[0].text);


    //     if (event.target.value !== 'select') {

    //         setStoreId(parseInt(event.target.value))



    //     } else
    //         setStoreId(0)


    // }





    const [StoreMaterialsList, SetStoreMaterials] = useState<MaterialType[] | null>([])


    const GetAllStoreMaterialsByStoreOwnerId = () => {
        setLoading(true);
        GetAllMaterialsByUser(UserToken.userId, UserToken.token)
            .then(res => {
                console.log('res from GEt Material----', res);
                SetStoreMaterials(res.data.materialListViewModel)
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);

                console.log('err from GEt Material', err);

                // let Obj: ErrorResponseModel = err.toJSON();

                // console.log(Obj.message);


                // if (Obj.message === 'Network Error') {
                //     setHasErrors('API Server is down....')
                // }
                // else {
                //     let obj2: ErrorModel = JSON.parse(Obj.message);
                //     setHasErrors(obj2.errorMessage);
                // }

            })
    }


    const GetAllStoreMaterials = (storeId_: number) => {
        setLoading(true);
        GetMaterialsByStoreId(storeId_, UserToken.token)
            .then(res => {
                console.log('res from GEt GetAllStoreMaterials----', res);
                SetStoreMaterials(res.data)
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);

                console.log('err from GEt Material', err);

                // let Obj: ErrorResponseModel = err.toJSON();

                // console.log(Obj.message);


                // if (Obj.message === 'Network Error') {
                //     setHasErrors('API Server is down....')
                // }
                // else {
                //     let obj2: ErrorModel = JSON.parse(Obj.message);
                //     setHasErrors(obj2.errorMessage);
                // }

            })
    }


    useEffect(() => {



        // if (storeId == 0) {

        GetAllStoreMaterialsByStoreOwnerId()
        // } else {

        //     GetAllStoreMaterials(storeId)
        // }

    }, [ /*storeId */])






    const onEditClick = (id: number) => {
        navigate(`/edit-pet/${id}`)
    }


    const onMaterialEditClick = (id: number) => {
        navigate(`/edit-material/${id}`)
    }



    const [loading, setLoading] = useState(true)

    const [hasErrors, setHasErrors] = useState('')

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);





    const idForDeleteModal = useRef(0)


    const handleShow = (
        id: number,
        isMyAdsListing: boolean,
        isFavroritePetsListing: boolean,
        isFavroriteMaterialsListing: boolean,
        isStoresListing: boolean,
        isStoreMaterialsListing: boolean
    ) => {
        console.log('id in handleShow---', id);

        if (isMyAdsListing) {
            setDeleteMethodRef(() => handlePetDelete)
        } else if (isFavroritePetsListing) {
            setDeleteMethodRef(() => handleFavoritePetDelete)
        } else if (isFavroriteMaterialsListing) {
            setDeleteMethodRef(() => handleFavoriteMaterialDelete)
        } else if (isStoresListing) {
            setDeleteMethodRef(() => handleStoreDelete)
        } else if (isStoreMaterialsListing) {
            setDeleteMethodRef(() => handleStoreMaterialDelete)
        }

        console.log('id in handleShow 2---', id);
        setShow(true);

        idForDeleteModal.current = id;





    };

    const [showDeleteSpinner, SetshowDeleteSpinner] = useState(false);


    const [deleteMethodRef, setDeleteMethodRef] = useState<() => void>(() => { })



    function handlePetDelete() {
        SetshowDeleteSpinner(true);
        setLoading(true)

        console.log('id in handle handlePetDelete ---', idForDeleteModal.current);


        DeletePet(idForDeleteModal.current, UserToken.token).then(() => {
            setLoading(false)
            refetchUserPets()
            handleClose();
            SetshowDeleteSpinner(false);

        })
            .catch(err => {
                setLoading(false)
                SetshowDeleteSpinner(false);
                // toast.error('Error in deleting Item', { position: toast.POSITION.TOP_RIGHT });
                console.log('err from DeleteStore', err);
            })


    };


    function handleStoreMaterialDelete() {
        SetshowDeleteSpinner(true);
        console.log('id in handle handleMaterialDelete ---', idForDeleteModal.current);
        setLoading(true)

        DeleteMaterial(idForDeleteModal.current, UserToken.token).then(() => {
            setLoading(false)

            handleClose();
            // if (storeId == 0) {

            GetAllStoreMaterialsByStoreOwnerId()
            // } else {

            //     GetAllStoreMaterials(storeId)
            // }
            SetshowDeleteSpinner(false);

        })
            .catch(err => {
                SetshowDeleteSpinner(false);
                setLoading(false)
                // toast.error('Error in deleting Item', { position: toast.POSITION.TOP_RIGHT });
                console.log('err from DeleteStore', err);
            })


    };

    function handleFavoritePetDelete() {
        SetshowDeleteSpinner(true);
        console.log('id in handle handleFavoritePetDelete ---', idForDeleteModal.current);
        setLoading(true)



        RemovePetFromFavorite(idForDeleteModal.current, UserToken.userId, UserToken.token)
            .then(() => {
                setLoading(false)
                handleClose()
                refetchFavoritePets()
                SetshowDeleteSpinner(false);

            })
            .catch(err => {
                setLoading(false)
                SetshowDeleteSpinner(false);
                // toast.error('Error in deleting Item', { position: toast.POSITION.TOP_RIGHT });
                console.log('err from DeleteStore', err);
            })





    };

    function handleFavoriteMaterialDelete() {
        SetshowDeleteSpinner(true);
        setLoading(true)

        console.log('id in handle handleFavoriteMaterialDelete --', idForDeleteModal.current);

        RemoveMaterialFromFavorite(idForDeleteModal.current, UserToken.userId, UserToken.token)
            .then(() => {
                setLoading(false)
                handleClose()
                refetchFavoriteMaterials()
                SetshowDeleteSpinner(false);


            })
            .catch(err => {
                setLoading(false)
                SetshowDeleteSpinner(false);
                // toast.error('Error in deleting Item', { position: toast.POSITION.TOP_RIGHT });
                console.log('err from DeleteStore', err);
            })





    };

    function handleStoreDelete() {
        SetshowDeleteSpinner(true);
        console.log('id in handle handlePetDelete ---', idForDeleteModal.current);
        setLoading(true)

        DeleteStore(idForDeleteModal.current, UserToken.token).then(() => {
            setLoading(false)
            // refetchStores()
            handleClose();
            SetshowDeleteSpinner(false);

        })
            .catch(err => {
                SetshowDeleteSpinner(false);
                setLoading(false)
                // toast.error('Error in deleting Item', { position: toast.POSITION.TOP_RIGHT });
                console.log('err from DeleteStore', err);
            })


    };


    const [fromUserId, setFromUserId] = useState("")
    const [fromUserName, setFromUserName] = useState("")
    const [petId, setPetId] = useState<number | null>(null)
    const [materialId, setMaterialId] = useState<number | null>(null)

    const [showSendMessageModal, setShowSendMessageModal] = useState(false);

    const handleSendMessageModalClose = () => setShowSendMessageModal(false);

    const handleShowSendMessageModal = (Obj: MessageObjType) => {

        console.log('Obj : ', Obj);

        setFromUserId(Obj.fromUserId)
        setFromUserName(Obj.fromUserName)

        setPetId(Obj.petId)
        setMaterialId(Obj.materialId)



        setShowSendMessageModal(true)

    }


    const [message, setMessage] = useState("")

    const onMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {

        setMessageError(false)
        setMessage(event.target.value);
    }

    const [messageError, setMessageError] = useState(false)

    const onMessageSubmit = () => {



        if (!message)
            setMessageError(true)
        else {


            setMessageError(false)
            setLoading(true)



            var model = {
                fromUserId: UserToken.userId,
                toUserId: fromUserId,
                messageText: message!,
                petId: petId!,
                materialId: materialId!
            }


            console.log('model : ', model);


            PostMessage(model!, UserToken.token).then(
                () => {
                    setMessage("")
                    handleSendMessageModalClose()
                    refetchSentMessages()
                    refetchReceivedMessages()

                })
                .catch((error) => {
                    setLoading(false)

                    handleSendMessageModalClose()
                    let Obj = error.toJSON();
                    console.log('1111111');
                    console.log('Obj', Obj);
                    if (Obj.message === 'Network Error') {

                        var msg = 'API Server is down....';
                        setHasErrors(msg);
                        //toast.error(msg, { position: //toast.POSITION.BOTTOM_RIGHT });

                    }
                    else {
                        let obj2 = JSON.parse(Obj.message);
                        console.log('obj2 : ', obj2);

                        setHasErrors(obj2.ErrorMessage);
                        //toast.error(obj2.ErrorMessage, { position: //toast.POSITION.BOTTOM_RIGHT });

                    }

                })


        }

    }






    return (

        <div className="account-page">
            <div className="container">

                <div className="breadcrums mt-2">
                    <ul>
                        <li><Link to={'/home'} className='mx-2'>Home </Link></li>
                        <li><Link to={''} className='mx-2'> Dashboard </Link></li>
                    </ul>
                </div>


                <DashboardHeader />

                <div className="dashboard">
                    <div className="row">
                        <div className="col col-12 col-lg-3">
                            <div className="dashboard-panel">
                                <ul className="nav" role="tablist" id="myTab">
                                    <li className="nav-item" role="presentation"><a className="nav-link active" href="#"
                                        id="tab1" data-bs-toggle="tab" data-bs-target="#tabdetail1"
                                        role="tab"><span><FontAwesomeIcon className='mx-1' icon={faPaw} /></span>My Ads</a>
                                    </li>
                                    <li className="nav-item" role="presentation"><a className="nav-link" href="#" id="tab2"
                                        data-bs-toggle="tab" data-bs-target="#tabdetail2"
                                        role="tab"><span><FontAwesomeIcon className='mx-1' icon={faHeart} /></span>Favorite Pets</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" href="#" id="tab3" data-bs-toggle="tab" data-bs-target="#tabdetail3"
                                            role="tab"><span><FontAwesomeIcon className='mx-1' icon={faShieldHeart} /></span>
                                            Favorite Products
                                        </a>
                                    </li>
                                    {/* <li className="nav-item" role="presentation"> 
                                        <a className="nav-link" href="#" id="tab4"
                                            data-bs-toggle="tab" data-bs-target="#tabdetail4"
                                            role="tab"><span><FontAwesomeIcon className='mx-1' icon={faStore} /></span>
                                            Manage Stores
                                        </a>
                                    </li>*/}
                                    <li className="nav-item" role="presentation"><a className="nav-link" href="#" id="tab5"
                                        data-bs-toggle="tab" data-bs-target="#tabdetail5"
                                        role="tab"><span><FontAwesomeIcon className='mx-1' icon={faStoreAlt} />
                                        </span>Stores Items</a>
                                    </li>

                                    <li className="nav-item" role="presentation"><a className="nav-link" href="#" id="tab6"
                                        data-bs-toggle="tab" data-bs-target="#tabdetail6"
                                        role="tab"><span><FontAwesomeIcon className='mx-1' icon={faEnvelope} />
                                        </span>Messages</a>
                                    </li>
                                    {/* <li className="nav-item" role="presentation"><a className="nav-link" href="#" id="tab7"
                                            data-bs-toggle="tab" data-bs-target="#tabdetail7"
                                            role="tab"><span><FontAwesomeIcon className='mx-1' icon={faFileLines} /></span>Orders</a>
                                        </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col col-12 col-lg-9">

                            {
                                (UserPetsLoading || loading) &&
                                <LoaderAnimation2 />
                            }


                            <div id="myTabContent" className=" tab-content">
                                <div className="tab-pane fade show active" id="tabdetail1" role="tabpanel"
                                    aria-labelledby="pet-ads">
                                    <h2>My Ads</h2>

                                    {UserPets?.data!.map((petItem, index) =>
                                    (
                                        <PetGridCard PetObj={petItem} key={index}
                                            onEditClick={() => onEditClick(petItem.id)}
                                            showEdit={true}
                                            onDeleteClick={() => {
                                                handleShow(petItem.id, true, false, false, false, false)
                                            }}
                                        />
                                    )
                                    )}


                                </div>

                                <div className="tab-pane fade" id="tabdetail2" role="tabpanel" aria-labelledby="pet-ads">
                                    <h2>Pets</h2>

                                    {FavoritePets?.data!.map((petItem, index) => (
                                        <PetGridCard PetObj={petItem} key={index}
                                            onEditClick={() => null}
                                            showEdit={false}
                                            onDeleteClick={() => {
                                                handleShow(petItem.id, false, true, false, false, false)
                                            }
                                            }
                                        />
                                    ))}


                                </div>

                                <div className="tab-pane fade" id="tabdetail3" role="tabpanel" aria-labelledby="pet-ads">
                                    <h2>Products</h2>

                                    {FavoriteMaterials?.data!.map((Item, index) => (
                                        <MaterialGridCard MaterialObj={Item} key={index}
                                            onEditClick={() => null}
                                            showEdit={false}
                                            onDeleteClick={() => {
                                                handleShow(Item.id, false, false, true, false, false)

                                            }
                                            }

                                        />
                                    ))}


                                </div>

                                <div className="tab-pane fade" id="tabdetail4" role="tabpanel" aria-labelledby="pet-ads">


                                    <div className={'mb-5 mb-xl-4 col-lg-11 col-md-11 col-sm-11'}>


                                        {/* begin::Header */}
                                        <div className='card-header border-0 pt-5 d-flex justify-content-between'>
                                            <h2 className='card-title align-items-start flex-column '>
                                                <span className='card-label fw-bolder fs-3 mb-1'>
                                                    All Stores
                                                </span>
                                                {/* <span className='text-muted mt-1 fw-bold fs-7'>All Offices in our PlatForm</span> */}
                                            </h2>
                                            <div className='card-toolbar'>
                                                {/* <a className='btn btn-sm btn-primary'
                                                    // onClick={() => history.push('/Stores/create')}
                                                    >
                                                        <FontAwesomeIcon icon={faPlus} className='mx-1' />
                                                        <span className='mx-1'>New</span>
                                                    </a> */}
                                                <button className='mx-1 dashboard-theme-button py-2'
                                                    onClick={() => navigate('/add-store')}
                                                >
                                                    <FontAwesomeIcon icon={faPlus} className='mx-1' />
                                                    <span className='mx-1'>New</span>
                                                </button>


                                            </div>
                                        </div>
                                        <div className='card-body py-3'>
                                            {/* begin::Table container */}
                                            <div className='table-responsive'>
                                                {hasErrors ? (
                                                    <div className='mb-lg-15 alert alert-danger'>
                                                        <div className='alert-text font-weight-bold'>{hasErrors}</div>
                                                    </div>
                                                ) : ''}

                                                {/* {loading &&
                                                                // <FullPageLoader />
                                                            } */}
                                                {/* begin::Table */}

                                                {/* <table className='table table-bordered align-middle gs-0 gy-4'>
                                                    <thead>
                                                        <tr className=' fw-bolder w-250px text-muted bg-light'>
                                                    
                                                            <th className='ps-4 w-50px'>#</th>

                                                            <th className='ps-4 w-150px'>Store Name</th>

                                                            <th className='ps-4 w-150px'>Registration #</th>

                                                            <th className='w-100px'>Actions</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            Stores?.data!.map(
                                                                (system, index) => (
                                                                    <tr key={system.id}>

                                                                        <td className='ps-4 w-50px'>{index + 1}</td>

                                                                        <td className='ps-4 w-150px text-dark  text-hover-primary'>{system.name}</td>

                                                                        <td className='ps-4 w-150px text-dark  text-hover-primary'>
                                                                            {system.registrationNumber}
                                                                        </td>

                                                            
                                                                        <td className='ps-4 w-100px'>

                                                                            <button className='mx-1'
                                                                                onClick={() => navigate(`/edit-store/${system.id}`)}

                                                                            >


                                                                                <FontAwesomeIcon icon={faPencil} />
                                                                            </button>

                                                                            <button className='mx-1' onClick={() => {
                                                                                handleShow(system.id, false, false, false, true, false)
                                                                            }}>
                                                                                <FontAwesomeIcon icon={faTrash} />
                                                                            </button>




                                                                        </td>

                                                                    </tr>

                                                                ))
                                                        }

                                                    </tbody>
                                                </table> */}


                                            </div>
                                        </div>
                                    </div>



                                </div>

                                <div className="tab-pane fade" id="tabdetail5" role="tabpanel" aria-labelledby="pet-ads">
                                    <div className='d-flex justify-content-between'>
                                        <h2>Items</h2>


                                        {/* <select name="store" id="store" className="form-select w-50 mx-5 bg-light"
                                            value={storeId}
                                            onChange={onStoreChange}

                                        >
                                            {storesOptions.map((type) =>
                                                <option
                                                    value={type.value}
                                                >
                                                    {type.key}
                                                </option>)}

                                        </select> */}

                                        {/* <button className='mx-3 dashboard-theme-button' onClick={() => navigate('/add-material')} >
                                            <FontAwesomeIcon icon={faPlus} className='mx-1' />
                                            <span className='mx-1'>New</span>
                                        </button> */}


                                    </div>

                                    {StoreMaterialsList?.map((Item, index) =>
                                    (
                                        <MaterialGridCard MaterialObj={Item} key={index}
                                            onEditClick={() => onMaterialEditClick(Item.id)}
                                            showEdit={true}
                                            onDeleteClick={() => {
                                                handleShow(Item.id, false, false, false, false, true)

                                            }}

                                        />
                                    )
                                    )}



                                </div>


                                <div className="tab-pane fade" id="tabdetail6" role="tabpanel" aria-labelledby="pet-ads">
                                    <h3 className='my-5'> Received Messages</h3>

                                    <table className="table table-bordered" style={{ width: '95%' }}>
                                        <thead>
                                            <tr>
                                                <th >Sender Name</th>
                                                <th >Message</th>
                                                <th>Commented Item</th>
                                                <th >Date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ReceivedMessages?.data!.map((msgObj, index) => (
                                                <tr>
                                                    <td >{msgObj.fromUserName}</td>
                                                    <td>{msgObj.messageText}</td>
                                                    <td>{msgObj.commentedItem}</td>
                                                    <td>{new Date(msgObj.dateTime).toLocaleString()}</td>
                                                    <td className='d-flex'>
                                                        <button className='mx-1' onClick={() => handleShowSendMessageModal(msgObj)}>
                                                            <FontAwesomeIcon icon={faReply} />
                                                        </button>
                                                        <button className='mx-1' onClick={
                                                            () => navigate(`${!!msgObj.petId ? `/pet-details` : !!msgObj.materialId && `/material-details`}/${msgObj.commentedItem}`)
                                                        }>
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </button>

                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>

                                    <h3 className='my-5'> Sent Messages</h3>

                                    <table className="table table-bordered" style={{ width: '95%' }}>
                                        <thead>
                                            <tr>
                                                <th >Receiver Name</th>
                                                <th >Message</th>
                                                <th>Commented Item</th>
                                                <th >Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {SentMessages?.data!.map((msg, index) => (
                                                <tr>
                                                    <td>{msg.toUserName}</td>
                                                    <td>{msg.messageText}</td>
                                                    <td>{msg.commentedItem}</td>
                                                    <td>{new Date(msg.dateTime).toLocaleString()}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>



                                </div>

                                <div className="tab-pane fade" id="tabdetail7" role="tabpanel" aria-labelledby="pet-ads">
                                    <h2>Orders</h2>


                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalComponent
                show={show}
                handleClose={handleClose}
                OkButtonText='Delete'
                showOKButtonSpinner={showDeleteSpinner}
                modalBodyText='Delete this item'
                handleOk={deleteMethodRef}
            />

            <ModalComponent2
                children={
                    <div>
                        <h1 style={{ color: ThemeColor }} className='mb-2 d-flex justify-content-start'>To : {fromUserName.toUpperCase()}</h1>
                        <SendMessageForm
                            message={message}
                            loading={loading}
                            messageChangeEvent={onMessageChange}
                            messageError={messageError}
                            onMessageSubmit={onMessageSubmit}
                        />
                    </div>
                }
                show={showSendMessageModal}
                handleClose={handleSendMessageModalClose}

            />

        </div>



    )
}

export default Dashboard