import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import React, { Suspense, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
// import { Route, Switch,Redirect } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import Layout from './Components/Layout/Layout';
import { useAuth } from './Contexts/AuthContext';
import LandingPage from './Pages/Landing Page';
import PetDetails from './Pages/PetDetails';
import PetsListing from './Pages/PetsListing';
import AddPet from './Pages/AddPet';
import Register from './Pages/Register';
import Login from './Pages/Login';
import AuthVerify from './common/auth-verify';
import Dashboard from './Pages/Dashboard';
import { AddStore } from './Pages/AddStore';
import { AddMaterial } from './Pages/AddMaterial';
import StoreListing from './Pages/StoreListing';
import MaterialDetails from './Pages/MaterialDetails';
import ChangePassword from './Pages/ChangePassword';
import ResetPassword from './Pages/ResetPassword';
import ForgotPassword from './Pages/ForgotPassword';
import Register2 from './Pages/Registeration';
import UserAds from './Pages/UserAds';
import { useParams } from 'react-router-dom';
import ContactUs from './Pages/ContactUs';
import Registeration from './Pages/Registeration';
import AboutUsPage from './Pages/AboutUs';
import AdsSelectOption from './Pages/AdsSelectOption';
import ShopOwnerCategories from './Pages/ShopOwnerCategories';

function App() {

  const { UserToken, setUserToken } = useAuth()
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to login if trying to access category-post-ads while not logged in
    if (!UserToken.email && window.location.pathname === '/category-post-ads') {
      navigate('/login');
    }
  }, [UserToken, navigate]);

  return (
    <Suspense>
      <Layout>
        <Routes>
          <Route path="/" element={<Navigate replace to="/home" />} />
          <Route path="/home" element={<LandingPage />} />



          <Route path="/register" element={<Register />} />


          <Route path="/login" element={<Login />} />

          <Route path="/forgot-password" element={<ForgotPassword />} />

          <Route path="/reset-password" element={<ResetPassword />} />

          {!!UserToken.userId &&
            <>
              <Route path="/dashboard" element={<Dashboard />} />


              <Route path='/edit-pet/:id' element={<AddPet />} />

              <Route path='/add-store' element={<AddStore />} />

              <Route path='/edit-store/:id' element={<AddStore />} />


              <Route path='/edit-material/:id' element={<AddMaterial />} />

              <Route path="/edit-profile/:id" element={<Register />} />

              <Route path="/change-password" element={<ChangePassword />} />

            </>
          }
          <Route path="/add-product" element={<AddPet />} />

          <Route path='/add-material' element={<AddMaterial />} />

          <Route path='/store' element={<StoreListing />} />

          <Route path='/pet-search' element={<PetsListing />} />

          <Route path='/pet-details/:name' element={<PetDetails />} />

          <Route path='/material-details/:name' element={<MaterialDetails />} />

          <Route path="/user-ads/:ownerId" element={<UserAds />} />

          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          {/* <Route path="/post-ads" element={<AdsSelectOption />} /> */}
          <Route path="/category-post-ads" element={<ShopOwnerCategories />} />
        </Routes>

      </Layout>
      <AuthVerify />

    </Suspense>
  );
}

export default App;
