import React, { useEffect, useState } from "react";
import { useAuth } from "../Contexts/AuthContext";
import { FadeLoader } from "react-spinners";
import PetCard from "../Components/widgets/PetCard";
import { GetUsersAds } from "../Service/PetServices";
import { useLocation, useParams } from "react-router-dom";
import { ThemeColor } from "../types/DefaultThemeColor";
import LoaderAnimation from "../Components/widgets/LoaderAnimation";
import MyPagination from "../Components/Pagination/Pagination";
// import ResponsivePagination from "react-responsive-pagination";
// import "react-responsive-pagination/themes/classic.css";


const UserAds = () => {
  const { UserToken } = useAuth();
  const [userAds, setUserAds] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const [currPage, setCurrPage] = useState(1);

  const [pageSize, setPageSize] = useState(8);
  const [totalCount, setTotalCount] = useState(0);

  // const param = useParams<{ ownerId: any }>();

  const { ownerId } = useParams<{ ownerId: string }>();

  useEffect(() => {

    console.log('!!ownerId : ', !!ownerId);

    // if (!!ownerId)
    fetchUserAds(currPage, pageSize);

  }, [currPage, pageSize]);

  const fetchUserAds = (curr_Page: number, page_Size: number) => {

    setLoading(true);
    GetUsersAds(ownerId!, curr_Page, page_Size, UserToken.token)
      .then(response => {

        setLoading(false);

        console.log('response : ', response.data);

        setUserAds(response.data.petListViewModel);
        setTotalCount(response.data.totalCount);
      })
      .catch(err => {
        setLoading(false);

      })


  };




  useEffect(() => {

    if (Array.isArray(userAds) && userAds.length > 0 && userAds[0].data && userAds[0].data.totalCount !== undefined) {
      setTotalCount(userAds[0].data.totalCount);
    }



  }, [userAds])

  return (
    <div className="container mt-4 mb-2 mb-3">
      {loading ? ( // Show loader if loading state is true
        <div className="pet d-flex justify-content-center mb-3 ">
          <FadeLoader color={ThemeColor} />
        </div>
      ) : (
        <MyPagination
          totPages={Math.ceil(totalCount / pageSize)}
          currentPage={currPage}
          pageClicked={(page_number: number) => {
            // afterPageClicked(ele);
            setCurrPage(page_number);
          }}
        >

          <div className="row">
            {userAds?.map((ad, index) => (
              <div className="col-12 col-md-4 col-lg-3" key={index}>
                <PetCard PetObj={ad} isFromHomePage={false} />
              </div>
            ))}
          </div>
        </MyPagination>
      )}
    </div>
  );
};

export default UserAds;
