import React, { useState } from 'react'
import PropTypes from 'prop-types'
import new_parrot from "../../Assets/Images/new-parrot.jpg";
import { Link } from 'react-router-dom';
import { API_URL } from '../../Service/PetServices';
import { useAuth } from '../../Contexts/AuthContext';
import { FavoriteAndUsersPetType } from '../../types/FavoriteAndUsersPetType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faCode, faDog, faPalette, faPaw, faPencil, faSyringe, faT, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faAngellist } from '@fortawesome/free-brands-svg-icons';
import { MaterialType } from '../../types/MaterialType';

type PropsType = {
    MaterialObj: MaterialType
    onEditClick: (id: number) => void | null
    showEdit: boolean
    onDeleteClick : () => void

}
const MaterialGridCard = (props: PropsType) => {

    const { UserToken, setUserToken } = useAuth()


    var MaterialObj = props.MaterialObj;


    // console.log('on click in MaterialGridCard : ', props.onClick);






    return (

        // <img className="card-img-top" src={`${API_URL}/${MaterialObj.files[0]}`} alt="Card image cap" /> </Link>
        <div className="sec-content">
            <div className="row">
                <div className="col-5 col-lg-2">


                    <Link to={`/material-details/${MaterialObj.name}`}><img className="card-img-top" src={`${API_URL}/${MaterialObj.files[0]}`} alt="Card image cap" /> </Link>
                </div>
                <div className="col-7 col-lg-10">
                    <div className="content-header">
                        <div>
                            <h3>{MaterialObj.name}</h3>
                            <p><span className='text-muted'>Last Upated</span> {new Date(MaterialObj.updatedOn).toDateString()}</p>
                        </div>
                        <div className="icons">
                            <span>

                                <button className='mx-1' onClick={() => props.onDeleteClick()}>
                                    <FontAwesomeIcon icon={faTrashCan} />
                                </button>
                                {props.showEdit &&
                                    <button className='mx-1'
                                        onClick={() => props.onEditClick(MaterialObj.id)}
                                    >
                                        <FontAwesomeIcon icon={faPencil} />
                                    </button>
                                }
                            </span>
                        </div>
                    </div>
                    <div className="content-footer">
                        <ul>
                            <li>
                                <FontAwesomeIcon className='mx-1' icon={faT} />{MaterialObj.materialType}
                            </li>
                            <li>
                                <FontAwesomeIcon className='mx-1' icon={faBuilding} />{MaterialObj.materialBrandName}</li>
                            <li>
                                <FontAwesomeIcon className='mx-1' icon={faCode} />{MaterialObj.productCode}
                            </li>
                            <li>
                                <FontAwesomeIcon className='mx-1' icon={faDog} />{MaterialObj.petTypeName}
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>


    )
}



export default MaterialGridCard