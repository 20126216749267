import React, { useEffect, useState } from "react";
import kitty1 from "../Assets/Images/kitty1.jpg";
import kitty2 from "../Assets/Images/kitty2.jpg";
import kitty3 from "../Assets/Images/kitty3.jpg";
import p1 from "../Assets/Images/p1.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faDroplet,
  faCircleCheck,
  faGreaterThan,
  faLessThan,
  faLocationDot,
  faPhone,
  faShieldDog,
  faTag,
  faVenusMars,
  faShareNodes,
  faPrint,
  faArrowRightArrowLeft,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { PetDetailsTYpe } from "../types/PetDetailsType";
import { string } from "yup";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import {
  API_URL,
  AddPetToFavorite,
  GetPetDetailsByName,
  GetRelatedPets,
  RemovePetFromFavorite,
} from "../Service/PetServices";
import GridListing from "../Components/widgets/GridListing";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import FeaturedPetsComponent from "../Components/widgets/FeaturedPetsComponent";
import SocailShare from "../Components/widgets/socailShare";
import { useAuth } from "../Contexts/AuthContext";
import { PostMessage } from "../Service/MessagesServices";
import { onPetHeartClick } from "../types/HeartIconClickFuncs";
// import { onPetHeartClick } from './Landing Page';
import avatar from "../Assets/Images/avatar.png";
import user from "../Assets/Images/user.png";
import person from "../Assets/Images/person.png";
import profile from "../Assets/Images/profile.png";
import LoaderAnimation3 from "../Components/widgets/LoaderAnimation3";
import SendMessageForm from "../Components/widgets/SendMessageForm";
import { Badge } from "react-bootstrap";
import PetSection from "./PetSection";

const PetDetails = () => {
  //const [showPhoneNumber, setShowPhoneNumber] = useState(false);

  const displayPhoneNumber = (action: string) => {
    const phoneNumber = PetObj?.data!.phone;
    if (action === "call") {
      window.location.href = `tel:+${phoneNumber}`;
    } else if (action === "whatsapp") {
      window.open(`https://wa.me/${phoneNumber}`, "_blank");
    }
  };
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const { UserToken } = useAuth();

  console.log("UserToken : in details ", UserToken);

  const { name } = useParams<{ name: string }>();

  const navigate = useNavigate();

  const {
    isLoading,
    data: PetObj,
    isError,
    error,
    refetch,
  } = useQuery(["pet-object", name], () => GetPetDetailsByName(name!, UserToken.userId, UserToken.token), {
    //   initialData: () => {
    //     // var featureItem =
    //     //   queryClient
    //     //     .getQueriesData('orders-list')
    //     //     ?.data?.find((obj) => obj.id === parseInt(route.params?.orderId))

    //     // if (featureItem) {
    //     //   return {
    //     //     data: featureItem
    //     //   }
    //     // }
    //     // else {
    //     //   return undefined
    //     // }

    //   },
    // refetchOnMount: true,
    // refetchOnWindowFocus: true,

    // refetchOnReconnect : false,
    enabled: !!name,
    refetchOnWindowFocus: false,
    refetchOnMount: true,

    onSuccess: (res) => {
      console.log("res.data in Details : ", res.data);

      window.scrollTo(0, 0);

      //  FruitObj?.data.fileNames.push(FruitObj?.data.imageURL)

      //
    },
  });

  const {
    isLoading: isLoadingRelatedPet,
    data: RelatedPetObj,
    isError: isRelatedPetError,
    error: RelatedPetError,
  } = useQuery(
    ["RelatedPets", name],
    () => GetRelatedPets(PetObj?.data!.id!, PetObj?.data!.petTypeId!, UserToken.userId, UserToken.token),
    {
      enabled: !!PetObj?.data!.id,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      onSuccess: (res) => {
        console.log("res.data in RelatedPets : ", res.data);
      },
    }
  );

  const [loading, setLoading] = useState(false);
  const [ownerId, setOwnerId] = useState("");
  const [hasErrors, setHasErrors] = useState("");

  const [message, setMessage] = useState("");

  const onMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageError(false);
    setMessage(event.target.value);
  };

  const [messageError, setMessageError] = useState(false);

  const onMessageSubmit = () => {
    if (!message) setMessageError(true);
    else {
      if (!UserToken.email) navigate(`/login?returnUri=/pet-details/${PetObj?.data.nickName!}&&MsgTxt=${message}`);
      else {
        setMessageError(false);
        setLoading(true);

        var model = {
          fromUserId: UserToken.userId,
          toUserId: PetObj?.data.ownerId!,
          messageText: message!,
          petId: PetObj?.data.id!,
        };
        setOwnerId(PetObj?.data.ownerId!);
        console.log("model : ", model);

        PostMessage(model!, UserToken.token)
          .then(() => {
            setLoading(false);
            setMessage("");
          })
          .catch((error) => {
            setLoading(false);

            let Obj = error.toJSON();
            console.log("1111111");
            console.log("Obj", Obj);
            if (Obj.message === "Network Error") {
              var msg = "API Server is down....";
              setHasErrors(msg);
              //toast.error(msg, { position: //toast.POSITION.BOTTOM_RIGHT });
            } else {
              let obj2 = JSON.parse(Obj.message);
              console.log("obj2 : ", obj2);

              setHasErrors(obj2.ErrorMessage);
              //toast.error(obj2.ErrorMessage, { position: //toast.POSITION.BOTTOM_RIGHT });
            }
          });
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!!location.search) {
      let Msg = urlParams.get("MsgTxt");
      console.log("Msg : ',' ", Msg);
      console.log("Msg :  ", !!Msg);

      if (!!Msg) {
        setMessage(Msg);
      }
    }
  }, [location.search]);

  return (
    <>
      <div className="container">
        <div className="breadcrums mt-2">
          <ul>
            <li>
              <Link to={"/home"} className="mx-2">
                Home{" "}
              </Link>
            </li>
            <li>
              <Link to={"/pet-search"} className="mx-2">
                {" "}
                Pets{" "}
              </Link>
            </li>
            <li>
              <Link to={""} className="mx-1">
                {" "}
                {PetObj?.data!.nickName}
              </Link>
            </li>
          </ul>
        </div>

        {isLoading && <LoaderAnimation3 />}
        {/* <!-- Product Detail --> */}
        <div className="product-detail">
          <div className="heading-sec">
            <div className="row">
              <div className="col-12 col-lg-6">
                <h1>{PetObj?.data!.nickName}</h1>
                <div className="loc">
                  <span>
                    {/* <i className="fa-solid fa-location-dot"></i> */}
                    <FontAwesomeIcon icon={faLocationDot} color="#00C194" className=" mx-1" />
                    {PetObj?.data!.city}
                  </span>
                  <span>/</span>
                  <span>
                    {/* <i className="fa-regular fa-clock"></i> */}
                    <FontAwesomeIcon icon={faClock} className="text-secondary-light mx-1" />
                    Last Upated {new Date(PetObj?.data!.updatedOn!).toDateString()}
                  </span>
                </div>
              </div>
              <div className="col-12 col-lg-6 price-sec">
                <div className="price-social-container">
                  {PetObj?.data!.isPriceOnCall ? (
                    // <h2 className="badge badge-dark"></h2>
                    <Badge className="bg-secondary  px-3 py-2 mb-2">Price On Call</Badge>
                  ) : (
                    <h3>Rs. {PetObj?.data!.price.toLocaleString()}</h3>
                  )}

                  <ul className="page-header icons">
                    <li className="social-share">
                      <SocailShare uri={`${API_URL}/pet-details/${encodeURI(PetObj?.data.nickName!)}`} />
                    </li>
                    {/* {!!UserToken.email ? ( */}
                    <li>
                      <FontAwesomeIcon
                        className={`${PetObj?.data!.isFavorite ? "svg-heart" : "svg"}`}
                        icon={faHeart}
                        onClick={() => onPetHeartClick(PetObj?.data!, UserToken.userId, refetch, UserToken.token)}
                      />
                    </li>
                    {/* ): (
                      <Link to="/register">
                        <FontAwesomeIcon className="svg-heart" icon={faHeart} />
                      </Link>
                    )} */}
                    <li>
                      <FontAwesomeIcon className="svg" icon={faPrint} />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-8">
              <Carousel
                showIndicators={false}
                showStatus={false}
                infiniteLoop={true}
                autoPlay={true}
                autoFocus={true}
                preventMovementUntilSwipeScrollTolerance={true}
                swipeScrollTolerance={30}
                renderArrowPrev={
                  (onClickHandler, hasPrev, label) => (
                    // hasPrev && (
                    <button
                      type="button"
                      onClick={onClickHandler}
                      title={label}
                      style={{ left: 15 }}
                      className="control-arrow"
                    >
                      <FontAwesomeIcon icon={faLessThan} size="2xs" />
                    </button>
                  )
                  // )
                }
                renderArrowNext={
                  (onClickHandler, hasNext, label) => (
                    // hasNext && (
                    <button
                      type="button"
                      onClick={onClickHandler}
                      title={label}
                      style={{ right: 15 }}
                      className="control-arrow"
                    >
                      <FontAwesomeIcon icon={faGreaterThan} size="2xs" />
                    </button>
                  )
                  // )
                }
              >
                {PetObj?.data!.files.map((file) => (
                  <div className="prod-slider-item">
                    <img src={`${API_URL}/${file}`} alt="" />
                  </div>
                ))}
                {/* <div className="prod-slider-item">
                                    <img src={kitty2} alt="" />
                                </div>
                                <div className="prod-slider-item">
                                    <img src={kitty3} alt="" />
                                </div>
                                <div className="prod-slider-item">
                                    <img src={kitty1} alt="" />
                                </div>
                                <div className="prod-slider-item">
                                    <img src={kitty2} alt="" />
                                </div> */}
              </Carousel>

              {/* <!-- OverView Sec --> */}
              <div className="overview-sec">
                <div className="content">
                  <h3>Overview</h3>
                  <div className="prop">
                    <ul>
                      <li>
                        <div className="prop-icon">
                          <i>
                            <FontAwesomeIcon icon={faTag} />
                          </i>
                        </div>
                        <div className="prop-content">
                          <p>ID No</p>
                          <p>{PetObj?.data!.productNumber}</p>
                        </div>
                      </li>
                      <li>
                        <div className="prop-icon">
                          <i>
                            <FontAwesomeIcon icon={faShieldDog} />
                          </i>
                        </div>
                        <div className="prop-content">
                          <p>Breed</p>
                          <p>{PetObj?.data!.petBreedName}</p>
                        </div>
                      </li>
                      <li>
                        <div className="prop-icon">
                          <i>
                            <FontAwesomeIcon icon={faVenusMars} />
                          </i>
                        </div>
                        <div className="prop-content">
                          <p>Gender</p>
                          <p>{PetObj?.data!.gender}</p>
                        </div>
                      </li>
                      <li>
                        <div className="prop-icon">
                          <i>
                            <FontAwesomeIcon icon={faDroplet} />
                          </i>
                        </div>
                        <div className="prop-content">
                          <p>Color</p>
                          <p>{PetObj?.data!.color}</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="content">
                  <h3>About This Listing</h3>
                  <p className="Listing-detail">{PetObj?.data!.details}</p>
                </div>
                {/* <div className="content">
                  <h3>Features & Behavior</h3>
                  <div className="row">
                    <div className="col-12 col-lg-4">
                      <h5 className="h6">Temperament</h5>
                      <ul className="feature-list">
                        {PetObj?.data!.tempramentFeatures.map((temFeature, index) => (
                          <li key={index}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                            {temFeature}
                          </li>
                        ))}
                      </ul>

                      <h5 className="h6">Compatibility</h5>
                      <ul className="feature-list">
                        {PetObj?.data!.compatibilityFeatures.map((feature, index) => (
                          <li key={index}>
                            <FontAwesomeIcon icon={faCircleCheck} />
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="col-12 col-lg-4">
                      <ul className="feature-list">
                        <h5 className="h6">General Features</h5>

                        {PetObj?.data!.specialFeatures.slice(0, 7).map((specialFeature, index) => (
                          <li key={index}>
                            <span className="">
                              {specialFeature.item2 + "" === "true" ? (
                                <FontAwesomeIcon icon={faCircleCheck} />
                              ) : (
                                <FontAwesomeIcon icon={faCircleXmark} />
                              )}
                            </span>
                            <span className="">{specialFeature.item1}</span>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="col-12 col-lg-4">
                      <ul className="feature-list">
                        {PetObj?.data!.specialFeatures.slice(7, 9).map((specialFeature, index) => (
                          <li key={index}>
                            <span className="">
                              {specialFeature.item2 + "" === "true" ? (
                                <FontAwesomeIcon icon={faCircleCheck} />
                              ) : (
                                <FontAwesomeIcon icon={faCircleXmark} />
                              )}
                            </span>
                            <span className="">{specialFeature.item1}</span>
                          </li>
                        ))}
                        <h5 className="h6">Training</h5>

                        <li>
                          <FontAwesomeIcon icon={faCircleCheck} />
                          {PetObj?.data!.trainingLevel}
                        </li>
                        <h5 className="h6">Energy</h5>

                        <li>
                          <FontAwesomeIcon icon={faCircleCheck} />
                          {PetObj?.data!.energyLevel}
                        </li>
                        <h5 className="h6"> Grooming</h5>

                        <li>
                          <FontAwesomeIcon icon={faCircleCheck} />
                          {PetObj?.data!.groomingLevel}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>  */}
              </div>
              {/* <!-- End Overview Section --> */}
            </div>
            <div className="col-lg-4">
              <div className="contact-sec right-panel">
                <h3>Contact Listing Owner</h3>
                <div className="row">
                  <div className="col-3 d-flex justify-content-center contact-img">
                    <img src={user} />
                  </div>
                  <div className="col-8 d-flex flex-column align-items-start contact-detail">
                    <p className="owner-name mb-0">{PetObj?.data!.ownerName}</p>
                    <div className="custom-link">
                      <Link to={`/user-ads/${PetObj?.data.ownerId}`}>View all Ads</Link>
                    </div>
                  </div>
                </div>

                {/* <p>
                      <FontAwesomeIcon icon={faPhone} className="mx-1" />+{PetObj?.data!.phone}
                    </p>
                    <p>
                      <FontAwesomeIcon icon={faWhatsapp} className="mx-1" />+{PetObj?.data!.phone}
                    </p> */}

                <div className="row mt-5">
                  <div className="button-container ">
                    <button className="callNow-btn mx-1 mx-1" onClick={() => displayPhoneNumber("call")}>
                      <FontAwesomeIcon icon={faPhone} className="me-1" /> Call
                    </button>
                    <button className="whatsapp-btn mx-1" onClick={() => displayPhoneNumber("whatsapp")}>
                      <FontAwesomeIcon icon={faWhatsapp} color="#000000" className="me-1" /> WhatsApp
                    </button>
                  </div>
                </div>

                <div className="contact-form">
                  <SendMessageForm
                    message={message}
                    onMessageSubmit={onMessageSubmit}
                    messageChangeEvent={onMessageChange}
                    messageError={messageError}
                    loading={loading}
                  />

                  {/* <form className="form-inline">

                                        <div className="mb-3 message">
                                            <textarea
                                                rows={5}
                                                // placeholder="Message*"
                                                className={`form-control ${messageError && "border-danger"}`}
                                                // id="InputMessage"
                                                // aria-describedby="message"
                                                value={message}
                                                onChange={(event) => setMessage(event.target.value)}
                                            />

                                            {messageError ? (
                                                <div className='p-1  text-danger'>{'Required'}</div>
                                            ) : ''}

                                        </div>

                                        <button className="btn-green " type="button" onClick={onMessageSubmit}>

                                            <div className='d-flex justify-content-center'>
                                                SEND MESSAGE
                                                {loading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        <span className='spinner-border spinner-border-sm align-middle mx-1 '></span>
                                                    </span>
                                                )}
                                            </div>


                                        </button>

                                    </form> */}
                </div>
              </div>

              {/* <div className="listing-sec right-panel">
                <h3>Related Listings</h3>

                {RelatedPetObj?.data!.length! > 0 && <GridListing items={RelatedPetObj?.data!} />}
              </div> */}
            </div>
          </div>
        </div>
        {/* <!-- End Product Detail --> */}
      </div>
      <div className="fea-product">
        <h1 className="newly-added-pet mb-4"> Featured Products</h1>

        <PetSection petType="all" showViewAllButton={false} />
      </div>
      {/* <PetSection petType="dog" title="Dogs" />
      <PetSection petType="hen" title="Hen" />  */}
      {/* <FeaturedPetsComponent />
      {/* <UserAds ownerId={PetObj?.data!.ownerId!} /> */}
    </>
  );
};

export default PetDetails;
