import React, { createContext, useContext, useState } from "react";


export const AuthContext = createContext({} as AuthContextType);

type AuthContextProviderProps = {
  children: React.ReactNode
}

type AuthContextType = {

  UserToken: {
    token: string,
    expiry: string,
    name: string,
    email: string,
    phone: string,
    userId: string
    city: string
    createdOn: string

  }
  // setHotelId : React.Dispatch<React.SetStateAction<string>>
  setUserToken: (
    token: string,
    expiry: string,
    name: string,
    email: string,
    phone: string,
    userId: string,
    city: string,
    createdOn: string

  ) => void


}


export const AuthProvider = ({ children }: AuthContextProviderProps) => {


  let token_ = localStorage.getItem('Token');
  let expiry_ = localStorage.getItem('Expiry');
  let userId_ = localStorage.getItem('UserId');


  let Name_ = localStorage.getItem('Name');
  let email_ = localStorage.getItem('Email');
  let phone_ = localStorage.getItem('Phone');
  let city_ = localStorage.getItem('City');
  let date_ = localStorage.getItem('JoinedDate');



  const [UserToken, setUser_Token] = useState({
    token: token_!,
    expiry: expiry_!,
    name: Name_!,
    email: email_!,
    phone: phone_!,
    userId: userId_!,
    city: city_!,
    createdOn: date_!


  })

  const setUserToken = (token: string, expiry: string,
    name: string,
    email: string, phone: string, userId: string, city: string,
    createdOn: string
  ) => {

    localStorage.setItem('Token', token);

    localStorage.setItem('Expiry', expiry);

    localStorage.setItem('UserId', userId);

    localStorage.setItem('Name', name);
    localStorage.setItem('Email', email);
    localStorage.setItem('Phone', phone);
    localStorage.setItem('City', city);
    localStorage.setItem('JoinedDate', createdOn);

    setUser_Token({
      token,
      expiry,
      name,
      email,
      phone,
      userId,
      city,
      createdOn
    })

  }

  return (
    <AuthContext.Provider value={{ UserToken, setUserToken }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)

