import React, { useEffect, useState } from 'react'
import kitty1 from "../Assets/Images/kitty1.jpg";
import kitty2 from "../Assets/Images/kitty2.jpg";
import kitty3 from "../Assets/Images/kitty3.jpg";
import p1 from "../Assets/Images/p1.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faDroplet, faCircleCheck, faGreaterThan, faLessThan, faLocationDot, faPhone, faShieldDog, faTag, faVenusMars, faShareNodes, faPrint, faArrowRightArrowLeft, faHeart, faT, faBuilding, faCode, faDog } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { string } from 'yup';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import GridListing from '../Components/widgets/GridListing';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import SocailShare from '../Components/widgets/socailShare';
import { useAuth } from '../Contexts/AuthContext';
import { PostMessage } from '../Service/MessagesServices';
import { onMaterialHeartClick, onPetHeartClick } from '../types/HeartIconClickFuncs';
import { GetMaterialByName } from '../Service/MaterialServices';
import { API_URL } from '../Service/PetServices';
import SendMessageForm from '../Components/widgets/SendMessageForm';
import avatar from "../Assets/Images/avatar.png";
import LoaderAnimation3 from '../Components/widgets/LoaderAnimation3';

// import { onPetHeartClick } from './Landing Page';

const MaterialDetails = () => {

    const location = useLocation()

    const urlParams = new URLSearchParams(location.search)





    const { UserToken } = useAuth()

    console.log('UserToken : in details ', UserToken);

    const { name } = useParams<{ name: string }>()

    const navigate = useNavigate()


    const { isLoading, data: MaterialObj, isError, error, refetch }
        = useQuery(['material-object', name], () => GetMaterialByName(name!, UserToken.userId, UserToken.token), {
            //   initialData: () => {
            //     // var featureItem =
            //     //   queryClient
            //     //     .getQueriesData('orders-list')
            //     //     ?.data?.find((obj) => obj.id === parseInt(route.params?.orderId))


            //     // if (featureItem) {
            //     //   return {
            //     //     data: featureItem
            //     //   }
            //     // }
            //     // else {
            //     //   return undefined
            //     // }

            //   },
            // refetchOnMount: true,
            // refetchOnWindowFocus: true,

            // refetchOnReconnect : false,
            enabled: !!name,
            refetchOnWindowFocus: false,
            refetchOnMount: true,

            onSuccess: (res) => {

                console.log('res.data in Details : ', res.data);

                //  FruitObj?.data.fileNames.push(FruitObj?.data.imageURL)


                //      
            }
        })




    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState('')

    const [message, setMessage] = useState("")


    const onMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessageError(false)
        setMessage(event.target.value);
    }

    const [messageError, setMessageError] = useState(false)

    useEffect(() => {

        window.scrollTo(0, 0);

        if (!!location.search) {
            let Msg = urlParams.get("MsgTxt")
            console.log("Msg : ',' ", Msg);
            console.log("Msg :  ", !!Msg);

            if (!!Msg) {
                setMessage(Msg)
            }


        }

    }, [location.search])


    const onMessageSubmit = () => {



        if (!message)
            setMessageError(true)
        else {


            if (!UserToken.email)
                navigate(`/login?returnUri=/material-details/${MaterialObj?.data.name!}&&MsgTxt=${message}`)
            else {

                setMessageError(false)
                setLoading(true)



                var model = {
                    fromUserId: UserToken.userId,
                    toUserId: MaterialObj?.data.ownerId!,
                    messageText: message!,
                    materialId : MaterialObj?.data.id!
                    
                }

                console.log('model : ', model);


                PostMessage(model!, UserToken.token).then(
                    () => {
                        setLoading(false)
                        setMessage("")
                    })
                    .catch((error) => {
                        setLoading(false)

                        let Obj = error.toJSON();
                        console.log('1111111');
                        console.log('Obj', Obj);
                        if (Obj.message === 'Network Error') {

                            var msg = 'API Server is down....';
                            setHasErrors(msg);
                            //toast.error(msg, { position: //toast.POSITION.BOTTOM_RIGHT });

                        }
                        else {
                            let obj2 = JSON.parse(Obj.message);
                            console.log('obj2 : ', obj2);

                            setHasErrors(obj2.ErrorMessage);
                            //toast.error(obj2.ErrorMessage, { position: //toast.POSITION.BOTTOM_RIGHT });

                        }

                    })

            }
        }

    }








    return (
        <div className="container">
            <div className="breadcrums mt-2">
                <ul>
                    <li><Link to={'/home'} className='mx-2'>Home </Link></li>
                    <li><Link to={'/store'} className='mx-2'> Materials </Link></li>
                    <li><Link to={''} className='mx-1'> {MaterialObj?.data!.name}</Link></li>
                </ul>
            </div>

            {
                isLoading &&
                <LoaderAnimation3 />
            }


            {/* <!-- Product Detail --> */}
            {/* {!!MaterialObj?.data && */}
            <div className="product-detail">
                <div className="heading-sec">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <h1>{MaterialObj?.data!.name}</h1>
                            <div className="loc">
                                <span>
                                    {/* <i className="fa-solid fa-location-dot"></i> */}
                                    <FontAwesomeIcon icon={faLocationDot}
                                        className='text-secondary-light mx-1'
                                    />
                                    {MaterialObj?.data!.city}
                                </span>
                                <span>/</span>
                                <span>
                                    {/* <i className="fa-regular fa-clock"></i> */}
                                    <FontAwesomeIcon icon={faClock}
                                        className='text-secondary-light mx-1'
                                    />
                                    Last Upated  {new Date(MaterialObj?.data!.updatedOn!).toDateString()}
                                </span>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 price-sec">
                            <h3>Rs. {MaterialObj?.data!.price}</h3>

                            <ul className="page-header icons">
                                <li className='social-share'>
                                    <SocailShare uri={`${API_URL}/material-details/${encodeURI(MaterialObj?.data.name!)}`} />
                                </li>
                                {!!UserToken.email &&
                                    <li><FontAwesomeIcon
                                        className={`${MaterialObj?.data!.isFavorite ? 'svg-heart' : 'svg'}`}

                                        icon={faHeart} onClick={() =>
                                            onMaterialHeartClick(MaterialObj?.data!, UserToken.userId, refetch, UserToken.token)} /></li>
                                }
                                <li><FontAwesomeIcon className='svg' icon={faPrint} /></li>

                            </ul>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-lg-6">

                        <Carousel showIndicators={false}
                            showStatus={false}
                            infiniteLoop={true}
                            autoPlay={true}
                            autoFocus={true}
                            preventMovementUntilSwipeScrollTolerance={true}
                            swipeScrollTolerance={30}


                            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                // hasPrev && (
                                <button type="button" onClick={onClickHandler} title={label} style={{ left: 15 }} className='control-arrow'>
                                    <FontAwesomeIcon icon={faLessThan} size='2xs' />
                                </button>
                                // )
                            }
                            renderArrowNext={(onClickHandler, hasNext, label) =>
                                // hasNext && (
                                <button type="button" onClick={onClickHandler} title={label} style={{ right: 15 }} className='control-arrow'>
                                    <FontAwesomeIcon icon={faGreaterThan} size='2xs' />

                                </button>
                                // )
                            }>

                            {MaterialObj?.data!.files.map(file => (
                                <div className="prod-slider-item">
                                    <img src={`${API_URL}/${file}`} alt="" />
                                </div>

                            ))
                            }

                        </Carousel>



                        <div className="contact-sec right-panel">
                            <h3>Contact Listing Owner</h3>
                            <div className="row">
                                <div className="col-4 contact-img">
                                    <img src={avatar} />
                                </div>

                                <div className="col-8 contact-detail">
                                    <h3>{MaterialObj?.data!.ownerName}</h3>
                                    <p>
                                        <FontAwesomeIcon icon={faPhone} className='mx-1' />
                                        +{MaterialObj?.data!.phone}
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faWhatsapp} className='mx-1' />
                                        +{MaterialObj?.data!.phone}
                                    </p>
                                </div>
                            </div>

                            <SendMessageForm
                                message={message}
                                onMessageSubmit={onMessageSubmit}
                                messageChangeEvent={onMessageChange}
                                messageError={messageError}
                                loading={loading}

                            />

                        </div>


                    </div>
                    <div className="col-lg-6 col-6">

                        <div className="overview-sec">
                            <div className="content">
                                <h3>Overview</h3>
                                <div className="prop">
                                    <ul>
                                        <li>
                                            <div className="prop-icon">
                                                <i>
                                                    <FontAwesomeIcon
                                                        icon={faT}
                                                    />
                                                </i>
                                            </div>

                                            <div className="prop-content">
                                                <p>Type</p>

                                                <p>{MaterialObj?.data!.materialType}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="prop-icon">
                                                <i>
                                                    <FontAwesomeIcon
                                                        icon={faBuilding}
                                                    />
                                                </i>
                                            </div>
                                            <div className="prop-content">
                                                <p>Brand</p>
                                                <p>{MaterialObj?.data!.materialBrandName}</p>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="prop-icon">
                                                <i>
                                                    <FontAwesomeIcon
                                                        icon={faCode}
                                                    />
                                                </i>

                                            </div>
                                            <div className="prop-content">
                                                <p>Code</p>
                                                <p>{MaterialObj?.data!.productCode}</p>
                                            </div>
                                        </li>


                                        <li>
                                            <div className="prop-icon">
                                                <i>
                                                    <FontAwesomeIcon
                                                        icon={faDog}
                                                    />
                                                </i>
                                            </div>
                                            <div className="prop-content">
                                                <p>For</p>
                                                <p>{MaterialObj?.data!.petTypeName}</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content">
                                <h3>About This Listing</h3>
                                <p>{MaterialObj?.data!.details}</p>
                            </div>
                            <div className="content">
                                <h3>Features</h3>
                                <div className="row">
                                    <div className="col-12 col-lg-4">
                                        <ul className='feature-list'>
                                            {MaterialObj?.data!.features.map((features, index) => (
                                                <li key={index}>
                                                    <FontAwesomeIcon icon={faCircleCheck} />
                                                    {features}
                                                </li>
                                            ))}

                                        </ul>




                                    </div>

                                </div>
                            </div>
                        </div>



                    </div>
                </div>

            </div>
            {/* // } */}
            {/* <!-- End Product Detail --> */}


        </div>




    )
}

export default MaterialDetails