import { Formik, Form, FieldArray } from 'formik'
import * as Yup from 'yup'
import { Form as BSForm, Spinner, Button, Card, FormLabel, FormSelect } from 'react-bootstrap-v5'
import FormikControl from '../Components/ReusableFormikComponents/FormikControl'

// import { useHistory } from 'react-router'
import { useState, useEffect, useRef } from 'react'


import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  GetMaterialBrands, GetMaterialById, Material_URL, PostMaterial,
  UpdateMaterial
} from '../Service/MaterialServices'

// import { toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'
// import FullPageLoader from '../../FullPageLoader'
import axios from 'axios'

// import { GetPetTypes } from '../../PetType/PetTypesService'
import Select from 'react-select'
import { GetUserStores } from '../Service/StoreServices'
import { useAuth } from '../Contexts/AuthContext'
import { API_URL, GetPetTypes, Pet_URL } from '../Service/PetServices'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faAngleRight, faArrowLeftLong, faArrowRightLong, faCross, faX } from '@fortawesome/free-solid-svg-icons'
import DashboardHeader from '../Components/widgets/DashboardHeader'
import LoaderAnimation from '../Components/widgets/LoaderAnimation'
import LoaderAnimation2 from '../Components/widgets/LoaderAnimation2'
import { ScrollToFieldError } from '../Components/ReusableFormikComponents/ScrollToErrorField'
import ImageGalleryDropZone from '../Components/widgets/ImageGalleryDropZone'


const AddMaterial = () => {

  //-----------------------------------------------------------------

  const initialValues = {
    Id: 0,
    Name: '',
    Price: 0,
    MaterialType: '',
    // ProductCode: '',
    Details: '',
    Files: [],
    // MaterialBrandId: 0,
    PetTypeId: 0,
    Features: ['']
  }

  const [formValues, setFormValues] = useState(null)


  //------------------------------------------------
  //------------------------------------------------

  const storesOptionsRef = useRef([])
  const [selectedStoreOption, setSelectedStoresOption] = useState(null)
  const [showSelectStoreErrorMsg, setshowSelectStoreErrorMsg] = useState(false)


  const location = useLocation()

  const urlParams = new URLSearchParams(location.search);


  const handleUserSelectChange = (selectedOption) => {

    // setshowSelectStoreErrorMsg(false);


    // setSelectedStoresOption(selectedOption);
  }


  //------------------------------------------------


  const MaterialTypeOptions = [
    { key: 'Select Type', value: 'select' },
    { key: 'Food', value: 'Food' },
    { key: 'Accessories', value: 'Accessories' }

  ]




  const { id } = useParams();

  const { UserToken } = useAuth()

  const isAddMode = !id;

  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [hasErrors, setHasErrors] = useState('')

  const [type, setType] = useState("")

  const navigate = useNavigate()


  useEffect(() => {
    (async () => {

      let Type = urlParams.get("Type");

      if (!UserToken.email)
        navigate(`/login?returnUri=/add-material?Type=${Type}`)


      // console.log("Type : ',' ", Type);
      // console.log("Type :  ", !!Type);
      if (!!Type)
        setType(Type)


      window.scrollTo(0, 0)
      // await GetUserStores(UserToken.userId, UserToken.token).then((res) => {

      //   console.log('res == ', res);

      //   res.data.map((store, index) => {
      //     storesOptionsRef.current.push({ label: `Store Name :  ${store.name} | Reg# : ${store.registrationNumber} | Owner Name : ${store.ownerName}`, value: `${store.id}`, OwnerId: store.ownerId })
      //   })

      // })

      if (!isAddMode) {
        setLoading2(true);

        await GetMaterialById(id, UserToken.token).then((res => {
          console.log('response data of Material by id ----', res.data);


          initialValues.Name = res.data.name;
          initialValues.Details = res.data.details;
          initialValues.Id = res.data.id;
          initialValues.Price = res.data.price;
          initialValues.Features = res.data.features;

          // initialValues.ProductCode = res.data.productCode;
          // initialValues.MaterialBrandId = res.data.materialBrandId;
          initialValues.PetTypeId = res.data.petTypeId;
          // initialValues.MaterialType = res.data.materialType;

          setType(res.data.materialType)



          // console.log('res.data.storeId : ', res.data.storeId);
          // setSelectedStoresOption(storesOptionsRef.current.find(a => a.value == res.data.storeId));


          res.data.files.map(file => {
            setPhotos(prevState => [...prevState, { src: `${file}`, width: 4, height: 4 }])
          })

          setFormValues(initialValues);


          setLoading2(false);

        }))
      }
      else {
        setFormValues(initialValues);

      }

    })()
  }, []
  )

  // const history = useHistory();

  const validationSchema = Yup.object().shape({



    Name: Yup.string().required('Required'),
    // MaterialType: Yup.string().required('Required'),
    Price: Yup.string().required('Required'),
    Details: Yup.string().required('Required'),
    // MaterialBrandId: Yup.string().required('Required'),
    PetTypeId: Yup.string().required('Required'),
    Features: Yup.array().min(1).required('Required')


  })


  const [PetTypesOptions, setPetTypesOptions] = useState([{ key: 'Select Pet Type', value: 'select' }])
  const [MaterialBrandsOptions, setMaterialBrandsOptions] = useState([{ key: 'Select Brand', value: 'select' }])






  useEffect(() => {

    (async () => {

      await GetMaterialBrands(UserToken.token)
        .then(res => {
          res.data.map(MaterialBrand => {
            setMaterialBrandsOptions(oldValues => [...oldValues, { key: MaterialBrand.name, value: MaterialBrand.id }])
          })
        })
        .catch(err => {
          console.log('err from GetMaterial Types ', err);
        })

      await GetPetTypes(UserToken.token)
        .then(res => {
          res.data.map(Type => {
            setPetTypesOptions(oldValues => [...oldValues, { key: Type.name, value: Type.id }])
          })
        })
        .catch(err => {
          console.log('err from GetMaterial Types ', err);
        })






    })()


  }, [])




  onsubmit = (values) => {

    // console.log('values =*= ', values);


    // if (selectedStoreOption == null) {
    //   setshowSelectStoreErrorMsg(true);
    // }

    // if (selectedStoreOption !== null) {

    let fileURLs = photos.map(photo => photo.src);
    values.Files = fileURLs;

    // values.City = UserToken.City;
    // values.OwnerId = selectedStoreOption.OwnerId;
    values.OwnerId = UserToken.userId;

    values.ListedBy = UserToken.userId;
    // values.Phone = UserToken.PhoneNumber;
    // values.storeId = selectedStoreOption.value;

    if (!!type) {
      values.MaterialType = type;
    }

    console.log('values == ', values);


    setLoading(true)
    setHasErrors("");

    if (isAddMode) {


      // console.log('values--- ', values);

      PostMaterial(values, UserToken.token).then(
        () => {
          setLoading(false)

          navigate('/dashboard')
          // toast.success('Item created successfully', { position: toast.POSITION.TOP_RIGHT });

          // history.push('/Materials/AllMaterials');

        })
        .catch((error) => {
          setLoading(false)

          let Obj = error.toJSON();
          console.log('1111111');
          console.log('Obj', Obj);
          if (Obj.message === 'Network Error') {

            setHasErrors('API Server is down....');
          }
          else {
            let obj2 = JSON.parse(Obj.message);

            setHasErrors(obj2.errorMessage);
            // toast.error(obj2.errorMessage, { position: toast.POSITION.BOTTOM_RIGHT });

          }

        })

    }
    else {

      UpdateMaterial(id, values, UserToken.token).then(
        () => {
          // setHasErrors(false)
          setLoading(false)
          // toast.success('Item updated successfully', { position: toast.POSITION.TOP_RIGHT });
          navigate('/dashboard')

          // history.push('/Materials/AllMaterials');

        })
        .catch((error) => {
          setLoading(false)

          let Obj = error.toJSON();
          console.log('1111111');
          console.log('Obj', Obj);
          if (Obj.message === 'Network Error') {

            setHasErrors('API Server is down....');
          }
          else {
            let obj2 = JSON.parse(Obj.message);

            setHasErrors(obj2.errorMessage);
            // toast.error(obj2.errorMessage, { position: toast.POSITION.BOTTOM_RIGHT });

          }
        })


    }


    // else {
    //   window.scrollTo(0, 0);
    // }



  }



  const [photos, setPhotos] = useState([])

  const setPhotosArray = (values) => {

    setPhotos(values)
  }




  return (
    <div className='container'>

      <div className="breadcrums mt-2">
        <ul>
          <li><Link to={'/home'} className='mx-2'>Home </Link></li>
          <li><Link to={'/dashboard'} className='mx-2'> Dashboard </Link></li>
          <li><Link to={''} className='mx-2'> {isAddMode ? "Add" : "Edit"} Material </Link></li>

        </ul>
      </div>


      <DashboardHeader />
      {(loading || loading2) && <LoaderAnimation2 />}

      {formValues !== null &&
        <Formik
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={onsubmit}
        >
          {
            formik => {
              return (



                <Form className="add-product-form"
                >

                  <ScrollToFieldError />


                  <h3 className="section-heading">
                    {!isAddMode ? "Update Information of Your Product"
                      : "Add Information of Your Product"
                    }
                  </h3>

                  {hasErrors ? (
                    <div className='mb-lg-15 alert alert-danger'>
                      <div className='alert-text font-weight-bold'>{hasErrors}</div>
                    </div>
                  ) : ''}

                  <div className="product-info">

                    <FormikControl
                      control='input'
                      type='text'
                      label='Material Name'
                      name='Name'
                      className='form-item'

                    />

                    <FormikControl
                      control='input'
                      type='number'
                      label='Price'
                      name='Price'
                      className='form-item'

                    />


                    {/* <FormikControl
                      control='select'
                      options={MaterialTypeOptions}
                      type='text'
                      label='Material Type'
                      name='MaterialType'
                      className='form-item'
                    /> */}


                    {/* <FormikControl
                      control='select'
                      options={MaterialBrandsOptions}
                      type='text'
                      label='Material Brand'
                      name='MaterialBrandId'
                      className='form-item'
                    /> */}

                    <FormikControl
                      control='select'
                      options={PetTypesOptions}
                      type='text'
                      label='Pet Type'
                      name='PetTypeId'
                      className='form-item'
                    />

                    {/* <FormikControl
                      control='input'
                      type='text'
                      label='Product Code'
                      name='ProductCode'
                      className='form-item'
                    /> */}



                  </div>

                  {/* --------Features list--- */}
                  <div className="mt-5 mb-3 w-100" >
                    <FieldArray name="Features">

                      {(fieldArrayProps) => {

                        const { push, remove, form } = fieldArrayProps;
                        const { values } = form;
                        const { Features } = values;

                        return (
                          <>
                            <div className=''>
                              <h4>Material Features</h4>
                            </div>

                            {Features.map(
                              (singlemap, index) => (

                                <div key={index} className='d-flex  m-1'>

                                  {/* <li style={{ marginTop: '25px' }} key={index}> </li> */}
                                  <FontAwesomeIcon icon={faArrowRightLong} className='mx-4 mt-3' />
                                  <FormikControl
                                    control='input'
                                    type='text'
                                    className='form-item-inner'

                                    name={`Features[${index}]`}
                                  />


                                  <div className='align-self-center col-2 mt-2'>
                                    {index != (Features.length - 1) && (
                                      <Button className="btn-secondary mx-3 btn-sm px-4 py-2"
                                        type="button" onClick={() => remove(index)}>
                                        <FontAwesomeIcon icon={faX} />
                                      </Button>
                                    )}

                                    {index == (Features.length - 1) && (
                                      <Button className="btn-primary mx-3 btn-sm px-4 py-2" type="button" onClick={() => push('')}>
                                        <FontAwesomeIcon icon={faAdd} />
                                      </Button>
                                    )}
                                  </div>



                                </div>


                              )
                            )}
                          </>

                        )
                      }

                      }

                    </FieldArray>

                  </div>



                  {/* ----------- */}



                  {/* Details input */}
                  <div className='m-5'>
                    <FormikControl
                      control='textarea'
                      type='text'
                      label='More About Your Material'
                      name='Details'
                      className='w-100'
                    />
                  </div>

                  {/* File selector */}


                  <ImageGalleryDropZone photosArray={photos} setPhotosArray={setPhotosArray} />

                  {/* Owner Details */}

                  {/* Select User... */}

                  {/* <div className='owner-detail'>
                    <div>
                      <h6 className={`${showSelectStoreErrorMsg ? 'text-danger' : ''}`}>Select Pet Store </h6>
                      <Select
                        value={selectedStoreOption}
                        onChange={handleUserSelectChange}
                        options={storesOptionsRef.current}
                        selectControlClassName='form-item form-control city-select'

                      // isDisabled={!isAddMode}

                      />
                      {showSelectStoreErrorMsg ? (
                        <div className='p-1 fs-3 text-danger'>{'Required'}</div>
                      ) : ''}
                    </div>
                  </div> */}

                  {/* ------------ */}



                  {/* Submit Btn */}
                  <div className='d-flex '>

                    <Button variant="success" className="submit-button" type='submit'>
                      <div className='d-flex justify-content-center'>


                        {/* {!isAddMode ? <span>Update </span> : <span> Create</span>} */}
                        SUBMIT


                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </div>
                    </Button>
                  </div>







                </Form>

              )
            }
          }
        </Formik>
      }
    </div>

  )
}

export { AddMaterial }



