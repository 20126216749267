import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Form as BSForm, Spinner, Button, Card, FormLabel, FormSelect } from 'react-bootstrap-v5'
import { useState, useEffect } from 'react'


import { Link, useNavigate, useParams } from 'react-router-dom'
import { GetStoreById, PostStore, UpdateStore } from '../Service/StoreServices'
import { useAuth } from '../Contexts/AuthContext'
import FormikControl from '../Components/ReusableFormikComponents/FormikControl'
import DashboardHeader from '../Components/widgets/DashboardHeader'
import LoaderAnimation2 from '../Components/widgets/LoaderAnimation2'

// import { toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'
// import FullPageLoader from '../../FullPageLoader'


const AddStore = () => {

  const initialValues = {
    Name: '',
    RegistrationNumber: ''
  }


  const { id } = useParams();

  const { UserToken } = useAuth()

  const isAddMode = !id;

  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [hasErrors, setHasErrors] = useState('')




  useEffect(
    () => {
      window.scrollTo(0, 0);

      setTimeout(() => {
        if (!isAddMode) {
          setLoading2(true);
          GetStoreById(id, UserToken.token).then((res => {
            console.log('response data of Store by id ----', res.data);

            initialValues.Name = res.data.name;

            initialValues.RegistrationNumber = res.data.registrationNumber;



            setLoading2(false);

          }))
        }
      }, 200);

    }, []
  )



  const validationSchema = Yup.object().shape({
    Name: Yup.string().required('Required'),
  })



  const navigate = useNavigate()



  onsubmit = (values) => {

    values.OwnerId = UserToken.userId;

    console.log('values == ', values);
    setLoading(true)
    setHasErrors('')

    if (isAddMode) {


      setTimeout(() => {

        console.log('values--- ', values);




        PostStore(values, UserToken.token).then(
          () => {
            setLoading(false)
            // toast.success('Item created successfully', { position: toast.POSITION.TOP_RIGHT });


            navigate('/dashboard')
            // history.push('/Stores/AllStores');

          })
          .catch((error) => {
            setLoading(false)

            let Obj = error.toJSON();
            console.log('1111111');
            console.log('Obj', Obj);


            if (Obj.message === 'Network Error') {

              setHasErrors('API Server is down....');
            }
            else if (Obj.message !== 'Network Error') {

              let obj2 = JSON.parse(Obj.message);

              setHasErrors(obj2.errorMessage);
              // toast.error(obj2.errorMessage, { position: toast.POSITION.BOTTOM_RIGHT });

            } else {
              setHasErrors('Error in Submitting Data');
            }



          })
      }, 1000)

    }
    else {

      setTimeout(() => {


        UpdateStore(id, values, UserToken.token).then(
          () => {
            // setHasErrors(false)
            setLoading(false)
            // toast.success('Item updated successfully', { position: toast.POSITION.TOP_RIGHT });
            navigate('/dashboard')

            // history.push('/Stores/AllStores');

          })
          .catch((error) => {
            setLoading(false)

            let Obj = error.toJSON();
            console.log('1111111');
            console.log('Obj', Obj);
            if (Obj.message === 'Network Error') {

              setHasErrors('API Server is down....');
            }
            else if (Obj.message !== 'Network Error') {

              let obj2 = JSON.parse(Obj.message);

              setHasErrors(obj2.errorMessage);
              // toast.error(obj2.errorMessage, { position: toast.POSITION.BOTTOM_RIGHT });

            } else {
              setHasErrors('Error in Submitting Data');
            }
          })
      }, 1000)


    }



  }





  return (

    <div className='container'>

      <div className="breadcrums mt-2">
        <ul>
          <li><Link to={'/home'} className='mx-2'>Home </Link></li>
          <li><Link to={'/dashboard'} className='mx-2'> Dashboard </Link></li>
          <li><Link to={''} className='mx-2'> {isAddMode ? "Add" : "Edit"} Store </Link></li>

        </ul>
      </div>


      <DashboardHeader />
      {(loading || loading2) && <LoaderAnimation2 />}


      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onsubmit}
      >
        {
          formik => {
            return (


              <Form className='add-product-form'>

                {/* {!isAddMode ? <h1 className='p-5'>Update Store</h1> : <h1 className='p-5'>Create Store</h1>} */}

                <h3 className="section-heading">
                  {!isAddMode ? "Update Information About Your Store"
                    : "Add Information of Your Store"
                  }
                </h3>


                {hasErrors ? (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{hasErrors}</div>
                  </div>
                ) : ''}

                <div className="product-info">


                  <FormikControl
                    control='input'
                    type='text'
                    label='Name'
                    name='Name'
                    className='form-item'
                  />

                  <FormikControl
                    control='input'
                    type='text'
                    label='Registration Number'
                    name='RegistrationNumber'
                    className='form-item'
                  />

                </div>







                <div className='d-flex '>

                  <Button variant="success" className="submit-button" type='submit'>
                    <div className='d-flex justify-content-center'>


                      {/* {!isAddMode ? <span>Update </span> : <span> Create</span>} */}
                      SUBMIT


                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </div>
                  </Button>
                </div>




              </Form>

            )
          }
        }
      </Formik>

    </div>
  )
}

export { AddStore }



